import { Badge, Button } from '@labourhub/labour-hub-ds'
import { PaymentMethodType } from 'features/payments/types'
import { SubscriptionPackageType } from 'features/subscriptions'
import { SubscriptionPlanPackageBadge } from 'features/subscriptions/components/SubscriptionPlanPackageBadge'

import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'
import { dateToReadableString } from 'utils/dateToReadableString'

const BillingHistoryTableColumn = (
  setInvoice: any,
  setIsInvoiceModalActive: any,
  setCardBillingAddress: any,
) => {
  const COLUMNS = [
    {
      Header: 'INVOICE ID',
      accessor: 'invoiceId',
      forSorting: 'invoiceId',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName:
        'flex items-center justify-center text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <>#{original?.invoice?.invoiceNumber}</>
      },
    },
    {
      Header: 'CURRENT PACKAGE',
      accessor: 'PlanName',
      forSorting: 'PlanName',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] py-5',
      headerClassName:
        'flex items-start justify-start text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-start justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return original?.subscription?.packageType ==
          SubscriptionPackageType.Fixed ? (
          <SubscriptionPlanPackageBadge
            packageName={original?.subscription?.packageName}
            isFill={true}
            size='small'
          />
        ) : (
          <span className='p-1 px-2 border rounded-md border-Gray-300'>
            {original?.description?.includes('Bonus')
              ? 'Bonus'
              : original?.invoice?.items?.length > 1
              ? 'Credits Bundle'
              : `${original?.subscription?.packageName} Credits`}
          </span>
        )
      },
    },
    {
      Header: 'SUBSCRIPTION',
      accessor: 'SubscriptionType',
      forSorting: 'SubscriptionType',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full max-w-[100px]',
      headerClassName:
        'flex items-start justify-start text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-start justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <>
            {original?.subscription?.subscriptionType
              ? capitalizeFirstLetter(original?.subscription?.subscriptionType)
              : 'NA'}
          </>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'IsActive',
      forSorting: 'IsActive',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName:
        'flex items-center justify-center text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        const data =
          original?.paymentStatus == 'Success'
            ? { theme: 'green', text: 'Success' }
            : original?.paymentStatus == 'Success'
            ? { theme: 'red', text: 'Failed' }
            : { theme: 'yellow', text: 'Pending' }

        return (
          <>
            <Badge size='large' theme={data.theme}>
              {data.text}
            </Badge>
          </>
        )
      },
    },
    {
      Header: 'PAYMENT TYPE',
      accessor: 'paymentType',
      forSorting: 'paymentType',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName:
        'flex items-start justify-start text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-start justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <>
            {original?.paymentMethod == PaymentMethodType.Card
              ? original?.cardType
                ? capitalizeFirstLetter(original?.cardType)
                : capitalizeFirstLetter(original?.paymentMethod)
              : original?.paymentMethod == 'None'
              ? 'NA'
              : original?.paymentMethod == PaymentMethodType.BankTransfer
              ? 'Bank Transfer'
              : capitalizeFirstLetter(original?.paymentMethod)}
          </>
        )
      },
    },
    {
      Header: 'PAYMENT DATE',
      accessor: 'paymentDate',
      forSorting: 'paymentDate',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName:
        'flex items-start justify-start text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-start justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <>
            {original?.invoice?.createdOn
              ? dateToReadableString(original?.invoice?.createdOn)
              : 'NA'}
          </>
        )
      },
    },
    {
      Header: 'RENEW',
      accessor: 'EndDate',
      forSorting: 'EndDate',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName:
        'flex items-start justify-start text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-start justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <>
            {original?.subscription?.renewalDate
              ? dateToReadableString(original?.subscription?.renewalDate)
              : 'NA'}
          </>
        )
      },
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
      forSorting: '',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName:
        'flex items-start justify-start text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-start justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <>${original?.invoice?.totalPaymentAmount?.toFixed(2)}</>
      },
    },
    {
      Header: '',
      accessor: 'Actions',
      forSorting: '',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName:
        'flex items-center justify-center text-Gray-900 text-small font-Medium bg-Gray-50',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <Button
            size='sm'
            onClick={() => {
              setInvoice({
                ...original?.invoice,
                subscription: original?.subscription,
                paymentType:
                  original?.paymentMethod == PaymentMethodType.Card
                    ? original?.cardType
                      ? capitalizeFirstLetter(original?.cardType)
                      : capitalizeFirstLetter(original?.paymentMethod)
                    : original?.paymentMethod == 'None'
                    ? 'NA'
                    : original?.paymentMethod == PaymentMethodType.BankTransfer
                    ? 'Bank Transfer'
                    : capitalizeFirstLetter(original?.paymentMethod),
              })
              setCardBillingAddress(original?.billingAddress)
              setIsInvoiceModalActive(true)
            }}>
            Download
          </Button>
        )
      },
    },
  ]

  return COLUMNS
}

export default BillingHistoryTableColumn
