import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import awardImage from 'assets/images/awardImage.svg'
import { bonusCreditMap } from 'features/onboarding/data'
import { getOnboardingStore, setCurrentStep } from 'features/onboarding/store'
import { getSubscriptionStore } from 'features/subscriptions'

import { ColumnWithAlignment } from 'components/atoms/Column'

export const GuideStepperIntro = () => {
  const dispatch = useDispatch()
  const { currentStep } = useSelector(getOnboardingStore)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const videoSrc = useMemo(
    () =>
      'https://player.vimeo.com/video/923171065?h=e69df80ebd&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479',
    [],
  )

  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  const bonusCredit = useMemo(() => {
    const packageType = fixedSubscription?.package.name
    const subscriptionType = fixedSubscription?.package.subscriptionType

    return packageType && subscriptionType
      ? bonusCreditMap[packageType]?.[subscriptionType]
      : 3
  }, [fixedSubscription])

  useEffect(() => {
    if (iframeRef.current) {
      if (currentStep == 1) iframeRef.current.src = videoSrc
      else iframeRef.current.src = ''
    }
  }, [currentStep])

  return (
    <ColumnWithAlignment justifyAlignment='center' className='w-full h-full'>
      <iframe
        ref={iframeRef}
        className='w-full h-[300px] rounded-xl'
        src={videoSrc}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Welcome to Ref Hub!'
      />

      <div className='flex w-full'>
        <div className='py-4'>
          <div className='flex flex-col items-center h-full mx-10 my-4'>
            <span className='my-2 leading-6 text-heading-3 font-Bold text-Cobalt-600'>
              Welcome To Ref Hub
            </span>
            <span className='px-8 my-2 text-base leading-5 text-center font-Regular text-Gray-900'>
              We're excited to have you on board. Let's take a quick tour to
              help you get started.
            </span>
            <img src={awardImage} alt='' className='h-[63px] mb-1 mt-3' />
            <span className='text-base text-center font-Medium'>
              Enjoy {bonusCredit} FREE Reference Checks on us.
            </span>
            <span className='text-[16px] leading-5 text-center font-Regular text-[#757575]'>
              To kickstart your hiring journey and as a thank you for signing
              up, we've gifted you {bonusCredit} free credits. Happy hiring!
            </span>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-center w-full px-36'>
        <Button onClick={() => dispatch(setCurrentStep(2))}>
          <span className='flex items-center justify-center py-0.5'>
            Let's Go
            <i className='ri-arrow-right-s-line text-[20px] ml-1'></i>
          </span>
        </Button>
      </div>
    </ColumnWithAlignment>
  )
}
