import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type GetNotificationListResponse = {
  messageTitle: string
  message: string
  notifications: Notification[]
  totalCount: number
  take: number
  skip: number
}

type Notification = {
  id: string
  createdOn: string
  updatedOn: string
  userProfileId: string
  type: string
  title: string
  description: string
  icon: string
  uuid: string
  meta: string
  isRead: boolean
}

type GetNotificationListRequestProps = {
  [x: string]: any
}

export const useGetNotificationList = (
  requestProps: GetNotificationListRequestProps,
) => {
  return useQuery(
    ['GET_NOTIFICATION_LIST', requestProps],
    async () => {
      const data = await get(
        `/Notification?Take=${requestProps.Take}&Skip=${requestProps.Skip}&UserProfileId=${requestProps.UserProfileId}`,
      )
      return (data?.data as GetNotificationListResponse) || []
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching Notifications',
          status: 'Error',
        })
      },
    },
  )
}
