import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import {
  QuestionnaireCategoryType,
  QuestionnaireLibraryDto,
  useGetQuestionnaireLibraryList,
  usePublishQuestionnaireLibrary,
} from 'api/questionnaires'
import EmptyTable from 'assets/images/emptyTable.svg'
import {
  getOnboardingStore,
  setCurrentStep,
  setGuideStepperPreviewTemplate,
  setShowGuideModal,
  setShowGuideStepper,
} from 'features/onboarding/store'
import { setAIQuestionData } from 'features/questionnaires/store/actions'

import { PageLoader } from 'components/atoms'

import { TemplateLibraryRadioCard } from './TemplateLibraryRadioCard'

type TemplateRoleListProps = {
  setIsAiTemplate: any
}

export const TemplateRoleList = ({
  setIsAiTemplate,
}: TemplateRoleListProps) => {
  const dispatch = useDispatch()
  const notify = (props: any) => Toast(props)
  const navigate = useNavigate()
  const { surveyType } = useSelector((state: any) => state.questionnaire)

  const { currentStep } = useSelector(getOnboardingStore)

  const [selectedTemplateType, setSelectedTemplateType] = useState({
    id: '',
    name: '',
  })

  const [showMore, setShowMore] = useState<boolean>(false)
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [library, setLibrary] = useState<QuestionnaireLibraryDto[]>([])
  const { isFetching, isLoading: isTemplateLoading } =
    useGetQuestionnaireLibraryList(
      {
        search: searchKeyWord,
        type: QuestionnaireCategoryType.ReferenceCheck,
      },
      (data) => setLibrary(data),
    )

  // Slice the library based on the showMore state
  const slicedLibrary = showMore ? library : library.slice(0, 9)

  // Function to handle the "View More" button click
  const handleViewMore = () => {
    setShowMore((prevShowMore) => !prevShowMore)
  }

  // Render TemplateLibraryCard components
  const renderTemplateLibraryCards = (library: QuestionnaireLibraryDto[]) => {
    return library.map((libraryItem) => (
      <TemplateLibraryRadioCard
        key={libraryItem.id}
        libraryItem={libraryItem}
        isSelected={libraryItem.id == selectedTemplateType?.id}
        setSelectedTemplateType={setSelectedTemplateType}
      />
    ))
  }

  // Render "View More" button if there are more than 9 items
  const renderViewMoreButton = () => {
    if (library.length > 9) {
      return (
        <div className='flex justify-center'>
          <Button
            theme='link'
            textTheme='blue'
            className='mt-4'
            onClick={handleViewMore}>
            {showMore ? 'View Less' : 'Load More'}
          </Button>
        </div>
      )
    }
    return null
  }

  const { mutate, isLoading: isTemplatePublishing } =
    usePublishQuestionnaireLibrary()

  const onNextClick = () => {
    if (selectedTemplateType.id != '')
      mutate(
        { id: selectedTemplateType.id, surveyType: surveyType },
        {
          onSuccess: ({ data }: any) => {
            if (data?.questionnaire) {
              dispatch(
                setGuideStepperPreviewTemplate({
                  id: data.questionnaire.id,
                  name: data.questionnaire.name,
                }),
              )
              dispatch(setCurrentStep(currentStep + 1))
            }
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: 'Error...!',
              alertBody: errData?.message
                ? errData?.message
                : 'Unable to create questionnaire',
              status: 'Error',
            })
          },
        },
      )
  }

  useEffect(() => {
    dispatch(setShowGuideStepper(library?.length > 0))
  }, [library])

  useEffect(() => {
    dispatch(setGuideStepperPreviewTemplate(undefined))
  }, [])

  return (
    <div className='relative px-2'>
      {(isFetching || isTemplateLoading) && <PageLoader hasBgOpacity={false} />}
      <div className='flex items-center pb-2 space-x-3'>
        <div className='w-auto pl-4 text-Gray-500'>
          Choose a reference check template
        </div>
        <div className='border border-Gray-200 w-[60px]' />
        <div className='relative flex-1 pr-4'>
          <input
            type='text'
            value={searchKeyWord}
            onChange={(e) => setSearchKeyWord(e.target.value)}
            className='w-full font-Regular py-[6px] pr-[30px] pl-[30px] bg-[#FAFAFAED] text-Gray-600 active:outline-Gray-50 focus:outline-Gray-50 rounded'
            placeholder='Search'
          />
          <i className='absolute transform -translate-y-1/2 left-2 top-1/2 ri-search-line text-Gray-400'></i>
        </div>
      </div>

      {library?.length === 0 ? (
        <div className='flex flex-col items-center justify-center my-20'>
          <img src={EmptyTable} alt='empty-table' className='h-[122px]' />

          <div className='flex flex-col items-center pt-2'>
            <span className='text-heading-4 text-Gray-800 font-Medium'>
              No Results Found
            </span>

            <span className='font-Regular text-Gray-600'>
              We’re sorry, we could not find any results for you.
            </span>
            <Button
              textTheme='white'
              size='xl'
              className='w-full h-[38px] mt-2 bg-Cobalt-600'
              onClick={() => {
                setIsAiTemplate(false)
                dispatch(setShowGuideModal(false))
                dispatch(setAIQuestionData(null))
                navigate(
                  `/questionnaires/new?buildMode=${QuestionnaireCategoryType.ReferenceCheck}&builderType=guideStepper`,
                )
              }}>
              <i className='ml-2 text-white ri-add-fill font-SemiBold' />
              <span className='ml-2 text-white font-Regular'>
                Build New Template
              </span>
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className='px-4 pt-2'>
            <p className='flex w-full text-extra-small leading-[18px] p-2 mb-2 border rounded bg-Blue-50 text-Blue-700 border-Blue-300'>
              Reference check surveys are sent to referees via Email and SMS
              once your candidate has uploaded their referee details. You can
              preview the template in the next step.
            </p>
          </div>

          <div className='px-4 my-3'>
            <Button
              theme='white'
              className='w-full h-[38px] text-Gray-800'
              onClick={() => {
                setIsAiTemplate(true)
              }}>
              <i className='ml-2 ri-reactjs-fill font-SemiBold text-Gray-800 text-[16px]' />
              <span className='ml-2 font-SemiBold text-Gray-800'>
                Generate role-specific survey with AI
              </span>
            </Button>
          </div>

          {/* Render radio buttons with images */}
          <div className='grid grid-cols-3 gap-3 px-4'>
            {renderTemplateLibraryCards(slicedLibrary)}
          </div>

          {/* Render "View More" button */}
          {renderViewMoreButton()}

          <div className='px-4 mt-5 space-y-3'>
            <Button
              theme='white'
              className='w-full h-[38px] text-Gray-800'
              onClick={() => {
                setIsAiTemplate(false)
                dispatch(setShowGuideModal(false))
                dispatch(setAIQuestionData(null))
                navigate(
                  `/questionnaires/new?buildMode=${QuestionnaireCategoryType.ReferenceCheck}&builderType=guideStepper`,
                )
              }}>
              <i className='ml-2 ri-add-fill font-SemiBold text-Gray-800' />
              <span className='ml-2 font-SemiBold text-Gray-800'>
                Build New Template
              </span>
            </Button>
            <Button
              textTheme='white'
              isLoading={isTemplatePublishing}
              isDisabled={selectedTemplateType.id == ''}
              className='w-full h-[38px] bg-Cobalt-600'
              onClick={onNextClick}>
              <span className='ml-2 text-white font-Regular'>Next</span>
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
