import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import freePlanDetails from 'assets/images/subscription/freePlanDetails.svg'
import {
  getOnboardingStore,
  setCurrentStep,
  setOnboardingPaymentBillingAddress,
} from 'features/onboarding/store'
import { defaultBillingAddress } from 'features/payments'

export const FreeCreditSection = () => {
  const dispatch = useDispatch()

  const { currentStep } = useSelector(getOnboardingStore)

  return (
    <div className='flex flex-col items-center justify-center w-full h-[calc(100%-48px)] px-[110px] pt-6 pb-4 overflow-y-auto styled-scroll'>
      <img src={freePlanDetails} alt='' className='object-fill w-[600px]' />
      <Button
        className='w-[600px] mt-8 py-2'
        onClick={() => {
          dispatch(setCurrentStep(currentStep + 1))
          dispatch(setOnboardingPaymentBillingAddress(defaultBillingAddress))
        }}>
        Get started
      </Button>
    </div>
  )
}
