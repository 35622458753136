import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

const listCandidateNotes = async ({ queryKey }: any) => {
  const { candidateId } = queryKey[1]
  const res = await get(`/CandidateHistory?CandidateId=${candidateId}`)
  return res.data.notes
}

export const useListCandidateNotes = (
  queryParams,
  onSuccess: (data: any) => void,
) => {
  return useQuery(['list_candidate_notes', queryParams], listCandidateNotes, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching candidate history',
        status: 'Error',
      })
    },
  })
}
