import { useEffect, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { useGetRefereesByCandidate } from 'framework/api/methods'
import { useDynamicFieldMapping } from 'hooks'

import { PageLoader } from 'components/atoms'
import {
  CallRecordingModal,
  RefereeReportViewModal,
  WebRefCheck,
} from 'components/molecules'

import { RefereeReferenceReportDownloadModal } from '../ReferenceReportDownloadModal/RefereeReferenceReportDownloadModal'

import { AddNewRefereeCard } from './AddNewRefereeCard'
import { AddRefereeModal } from './AddRefereeModal'
import { CandidateRefereeCard } from './CandidateRefereeCard'
import { CandidateRefereeEmptyState } from './CandidateRefereeEmptyState'
import { EditRefereeModal } from './EditRefereeModal'

type RefereesTabProps = {
  [x: string]: any
}

export const RefereesTab = ({
  candidateId,
  candidateDetails,
  getCandidateById,
  setRefereeRequestModalIsActive,
  setRefereeRequestReminderModalIsActive,
  notify,
  requestReferees,
}: RefereesTabProps) => {
  const [showAddRefereeModal, setShowAddRefereeModal] = useState(false)
  const [showEditRefereeModal, setShowEditRefereeModal] = useState(false)
  const [showCallRecordingModal, setShowCallRecordingModal] = useState(false)
  const [showViewReportModal, setShowViewReportModal] = useState(false)
  const [
    showDownlaodReferenceReportModal,
    setShowDownlaodReferenceReportModal,
  ] = useState(false)

  const [selectedRefereeDetails, setSelectedRefereeDetails] = useState<any>()
  const [isWebRefCheckModal, setIsWebRefCheckModal] = useState<any>({
    enable: false,
    selectAll: false,
    selectedRefereeId: '',
  })

  /**Fetch referees for the candidate */
  const {
    refetch: getCandidateRefereesList,
    data: candidateReferees,
    isLoading: loadingCandidateReferees,
  } = useGetRefereesByCandidate(candidateId)

  const [initial, setInitial] = useState('')

  useEffect(() => {
    selectedRefereeDetails && getInitial()
  }, [selectedRefereeDetails])

  const { changeJsonSchema } = useDynamicFieldMapping({
    candidateId,
    refereeId: selectedRefereeDetails?.id,
  })

  const getInitial = () => {
    const title =
      selectedRefereeDetails?.firstName + ' ' + selectedRefereeDetails?.lastName
    const Array: any = title?.split(' ')
    const firstLetter = Array[0]?.charAt(0).toUpperCase()
    const SecondLetter = Array[1]?.charAt(0).toUpperCase()
    setInitial(
      SecondLetter !== undefined ? firstLetter + SecondLetter : firstLetter,
    )
  }

  return (
    <div className='relative referee-tab-view'>
      {loadingCandidateReferees && (
        <PageLoader size='xxs' className='absolute min-h-[50vh]' />
      )}

      <div className='flex flex-col'>
        <div className='flex justify-between pb-8'>
          <span className='font-Medium text-heading-4 text-[#000000]'>
            Referees
          </span>

          <div className='flex gap-2'>
            <Button
              iconLeft={<i className='ri-send-plane-line' />}
              textTheme='blue'
              theme='white'
              //   isDisabled={
              //     (candidateReferees &&
              //       candidateReferees?.referees?.length == 0) ||
              //     (candidateReferees &&
              //       candidateReferees?.referees.filter(
              //         (r) => r.state !== 'NOT_SENT',
              //       ).length ===
              //         candidateDetails?.basicCandidateProfile?.refereeCount)
              //   }
              onClick={() => {
                setIsWebRefCheckModal({
                  ...isWebRefCheckModal,
                  selectedRefereeId: '',
                  selectAll: true,
                  enable: true,
                })
              }}>
              Send Ref Check for All
            </Button>
          </div>
        </div>
        {!loadingCandidateReferees &&
          (candidateDetails &&
          candidateReferees &&
          candidateReferees?.referees?.length > 0 ? (
            <>
              {candidateReferees?.referees?.map((candidateReferee, index) => {
                return (
                  <div key={index} className='mb-4'>
                    <CandidateRefereeCard
                      key={index}
                      referee={candidateReferee}
                      getCandidateById={getCandidateById}
                      refereeCount={candidateReferees?.referees?.length || 0}
                      getCandidateRefereesList={getCandidateRefereesList}
                      refereeIndex={index + 1}
                      candidate={candidateDetails.basicCandidateProfile}
                      setSelectedRefereeDetails={setSelectedRefereeDetails}
                      onEditClick={() => setShowEditRefereeModal(true)}
                      onCallRecordingsClick={() => {
                        setShowCallRecordingModal(true)
                      }}
                      onClickWebRefCheck={() => {
                        setIsWebRefCheckModal({
                          ...isWebRefCheckModal,
                          selectedRefereeId: candidateReferee?.id,
                          selectAll: false,
                          enable: true,
                        })
                      }}
                      onViewReportClick={() => setShowViewReportModal(true)}
                      setShowDownlaodReferenceReportModal={
                        setShowDownlaodReferenceReportModal
                      }
                    />
                  </div>
                )
              })}

              <AddNewRefereeCard
                candidateId={candidateId}
                getCandidateById={getCandidateById}
                activeRefereeCount={candidateReferees?.referees?.length || 0}
                setShowAddRefereeModal={setShowAddRefereeModal}
              />
            </>
          ) : (
            /** Empty State */
            <CandidateRefereeEmptyState
              setShowRefereeRequestModal={setRefereeRequestModalIsActive}
              setShowRefereeRequestReminderModal={
                setRefereeRequestReminderModalIsActive
              }
              setShowAddRefereeModal={setShowAddRefereeModal}
              candidateDetails={candidateDetails?.basicCandidateProfile}
            />
          ))}

        <EditRefereeModal
          isModalActive={showEditRefereeModal}
          setIsModalActive={setShowEditRefereeModal}
          candidateId={candidateId}
          getCandidateRefereesList={getCandidateRefereesList}
          notify={notify}
          getCandidateById={getCandidateById}
          selectedRefereeDetails={selectedRefereeDetails}
          setSelectedRefereeDetails={setSelectedRefereeDetails}
        />
      </div>

      <AddRefereeModal
        isModalActive={showAddRefereeModal}
        setIsModalActive={setShowAddRefereeModal}
        candidateId={candidateId}
        getCandidateRefereesList={getCandidateRefereesList}
        getCandidateById={getCandidateById}
      />

      <CallRecordingModal
        isModalActive={showCallRecordingModal}
        setIsModalActive={setShowCallRecordingModal}
        selectedRefereeDetails={selectedRefereeDetails}
        onRequestRefereeClick={() => requestReferees()}
      />

      {/* web ref check modal  */}
      {!loadingCandidateReferees &&
        candidateReferees &&
        candidateReferees?.referees?.length > 0 && (
          <WebRefCheck
            isOpen={isWebRefCheckModal?.enable}
            setIsWebRefCheckModal={setIsWebRefCheckModal}
            selectedRefereeId={isWebRefCheckModal?.selectedRefereeId}
            selectAll={isWebRefCheckModal?.selectAll}
            candidateId={candidateId}
            refereeList={candidateReferees?.referees}
            notify={notify}
            getCandidateRefereesList={getCandidateRefereesList}
          />
        )}

      {showViewReportModal && (
        <RefereeReportViewModal
          isModalActive={showViewReportModal}
          setIsModalActive={setShowViewReportModal}
          changeJsonSchema={changeJsonSchema}
          getCandidateById={getCandidateById}
          getCandidateRefereesList={getCandidateRefereesList}
          refereeIndex={candidateReferees?.referees.findIndex(
            (data) => data.id === selectedRefereeDetails.id,
          )}
          referee={selectedRefereeDetails}
          title={initial}
          selectedReferenceDoneDate={selectedRefereeDetails?.referenceDoneDate}
          selectedReferenceDoneBy={selectedRefereeDetails?.referenceDoneBy}
          setShowDownlaodReferenceReportModal={
            setShowDownlaodReferenceReportModal
          }
        />
      )}

      {candidateDetails && selectedRefereeDetails && (
        <RefereeReferenceReportDownloadModal
          isModalActive={showDownlaodReferenceReportModal}
          setIsModalActive={setShowDownlaodReferenceReportModal}
          candidateDetails={candidateDetails.basicCandidateProfile}
          referee={selectedRefereeDetails}
        />
      )}
    </div>
  )
}
