import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { TextField, Toast } from '@labourhub/labour-hub-ds'
import { useCreateCandidate } from 'api/candidates'
import {
  AppSettingKey,
  AppSettingProps,
  getAgencyAppSettings,
  INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  isDefaultSetting,
  isSettingEnabled,
  isSettingsExist,
  updateSettingByKey,
} from 'features/settings'
import { ModalProps } from 'types'

import { PageLoader, PhoneInputField } from 'components/atoms'
import { RoundedModal } from 'components/ui/RoundedModal'
import { isEmail, isLetters, isNumber, isPhoneNumber } from 'utils'

import {
  CandidateSettingsForm,
  CandidateSettingsFormRef,
} from './CandidateSettingsForm'
import {
  RefereeTypeAccordionContentType,
  RequestSpecificRefereeForm,
} from './RequestSpecificRefereeForm'

type AddNewCandidateModalProps = ModalProps & {
  modalTitle: string | undefined
  modalSubTitle: string | undefined
  refreshCandidateList: () => void
}

export const AddNewCandidateModal = ({
  modalTitle = 'Add Candidate',
  modalSubTitle = 'Add new candidate',
  isModalActive,
  refreshCandidateList,
  setIsModalActive,
}: AddNewCandidateModalProps) => {
  const notify = (props: any) => Toast(props)

  const { userDetails } = useSelector((state: any) => state.user)

  const agencyAppSettings = useSelector(getAgencyAppSettings)

  const [candidateSettings, setCandidateSettings] = useState<AppSettingProps[]>(
    INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  )

  const candidateSettingsFormRef = useRef<CandidateSettingsFormRef | null>(null)

  const isEmailRequired = isSettingEnabled(
    AppSettingKey.IS_CANDIDATE_EMAIL_REQUIRED,
    agencyAppSettings,
  )

  const isSmsEnabled = isSettingEnabled(
    AppSettingKey.SmsFeature,
    agencyAppSettings,
  )

  /** candidate details and error fields state */
  const [candidateDetails, setCandidateDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: 'AU',
    dateOfBirth: '',
    gender: '',
    jobTitle: '',
    noOfReferences: '',
    consultantId: userDetails?.id,
    candidateSettings: [] as any,
  })

  const [referenceTypes, setReferenceTypes] = useState<
    RefereeTypeAccordionContentType[]
  >([])

  const [
    isShowRequestSpecificRefereeForm,
    setIsShowRequestSpecificRefereeForm,
  ] = useState(false)
  const [renderKey, setRenderKey] = useState(Math.random())

  useEffect(() => {
    setCandidateSettings(agencyAppSettings)
  }, [agencyAppSettings])

  useEffect(() => {
    setCandidateDetails({
      ...candidateDetails,
      consultantId: userDetails?.id,
    })
  }, [userDetails])

  useEffect(() => {
    const updatedSettings = updateSettingByKey(
      AppSettingKey.SmsFeature,
      candidateSettings,
      {
        value: `true`,
      },
    )

    setCandidateDetails({
      ...candidateDetails,
      candidateSettings: updatedSettings,
    })
  }, [candidateSettings])

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    email: false,
    noOfReferences: 0,
    phone: false,
    jobTitle: false,
  })

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** APi call for the add candidate */
  const { mutate: createCandidateMutate, isLoading: createCandidateIsLoading } =
    useCreateCandidate()

  /** Process the add new candidate */
  const addNewCandidate = () => {
    createCandidateMutate(
      {
        candidateDetails,
        referenceTypes,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })
          resetInputFields()
          setIsModalActive(false)
          refreshCandidateList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation input fields */
  const validateInputs = () => {
    const { firstName, lastName, email, phone, jobTitle, noOfReferences } =
      candidateDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) && firstName?.length < 100 ? false : true,
      lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
      phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
      jobTitle: jobTitle?.length < 100 ? false : true,
      noOfReferences:
        isNumber(noOfReferences) && noOfReferences?.length < 100 ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      firstName?.length < 100 &&
      isLetters(lastName) &&
      lastName?.length < 100 &&
      isPhoneNumber(phone) &&
      phone?.length < 20 &&
      jobTitle?.length < 100 &&
      isNumber(noOfReferences) &&
      noOfReferences?.length < 100

    const isValidNoteCount = referenceTypes.every((r) => r.note.length <= 500)

    if (isEmailRequired) {
      setIsErrorField({
        ...isErrorField,
        email: isEmail(email) && email?.length < 200 ? false : true,
        firstName:
          isLetters(firstName) && firstName?.length < 100 ? false : true,
        lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
        phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
        jobTitle: jobTitle?.length < 100 ? false : true,
        noOfReferences:
          isNumber(noOfReferences) && noOfReferences?.length < 100
            ? false
            : true,
      })
      return (
        isAllValidFields &&
        isValidNoteCount &&
        email?.length < 200 &&
        isEmail(email)
      )
    }

    return isAllValidFields && isValidNoteCount
  }

  /** handle add new candidate button */
  const onAddNewCandidate = () => {
    const valid = validateInputs()

    if (
      isSettingEnabled(AppSettingKey.AutoReferencing, candidateSettings) &&
      isDefaultSetting(AppSettingKey.QuestionnaireTemplateId, candidateSettings)
    ) {
      notify({
        alertHeader: 'Please choose a template',
        status: 'Warning',
      })
      return
    }

    if (valid) {
      isShowRequestSpecificRefereeForm
        ? addNewCandidate()
        : setIsShowRequestSpecificRefereeForm(true)
    } else {
      notify({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** reset input fields */
  const resetInputFields = () => {
    setCandidateDetails({
      ...candidateDetails,
      firstName: '',
      lastName: '',
      email: '',
      countryCode: 'AU',
      phone: '',
      jobTitle: '',
      noOfReferences: '',
    })
    setIsShowRequestSpecificRefereeForm(false)

    setCandidateSettings(agencyAppSettings)

    if (candidateSettingsFormRef.current) {
      candidateSettingsFormRef.current.resetForm()
    }
  }

  /** handle primary button disable */
  useEffect(() => {
    const { firstName, email, phone, noOfReferences }: any =
      candidateDetails || {}

    const isValidNoteCount = referenceTypes.every((r) => r.note.length <= 500)

    if (isEmailRequired) {
      firstName && email && noOfReferences && isValidNoteCount
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    } else {
      firstName && noOfReferences && phone && isSmsEnabled && isValidNoteCount
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    }
  }, [candidateDetails, referenceTypes])

  useEffect(() => {
    if (isModalActive) {
      setRenderKey(Math.random())
    }
  }, [isModalActive])

  const resetErrorState = () => {
    setIsErrorField({
      firstName: false,
      lastName: false,
      email: false,
      noOfReferences: 0,
      phone: false,
      jobTitle: false,
    })
  }

  const handleCloseModal = () => {
    setIsModalActive(false)
    resetInputFields()
    resetErrorState()
  }

  return (
    <RoundedModal
      isActive={isModalActive}
      headerTitle={modalTitle}
      size='md'
      className='!z-[150]'
      headerSubtitle={
        isShowRequestSpecificRefereeForm ? 'Reference types' : modalSubTitle
      }
      isHeaderShow={true}
      isFooterShow={true}
      isSeparator={true}
      primaryButtonTitle={
        isShowRequestSpecificRefereeForm
          ? isSettingEnabled(AppSettingKey.AutoReferencing, candidateSettings)
            ? 'Add Candidate & Send Request'
            : 'Add Candidate'
          : 'Continue'
      }
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onClickPrimaryBtn={() => {
        onAddNewCandidate()
      }}
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}>
      {createCandidateIsLoading && <PageLoader size='xxs' />}
      {!isShowRequestSpecificRefereeForm ? (
        <div className='w-full px-6'>
          {/* first name section */}
          <TextField
            label='First Name'
            className='mt-5'
            isRequired
            value={candidateDetails?.firstName}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                firstName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.firstName}
          />
          {/* last name section */}
          <TextField
            label='Last Name'
            isRequired
            className='mt-5 mb-4'
            value={candidateDetails?.lastName}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                lastName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.lastName}
          />

          {/* email section */}
          <TextField
            label='Email'
            isRequired={isEmailRequired}
            className='mt-4'
            value={candidateDetails?.email}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                email: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isEmailRequired && isErrorField.email}
          />
          {/** Phone number section */}
          <PhoneInputField
            key={renderKey}
            label='Phone'
            isRequired
            value={candidateDetails?.phone}
            onChangeWithCountry={(number: any, countryCode: string) =>
              setCandidateDetails({
                ...candidateDetails,
                phone: number,
                countryCode,
              })
            }
            className='mt-5'
            isErrored={isErrorField.phone}
          />
          {/* job title section */}
          <TextField
            label='Role candidate is applying for'
            className='mt-5'
            isRequired
            value={candidateDetails?.jobTitle}
            onChange={(e: any) => {
              setCandidateDetails({
                ...candidateDetails,
                jobTitle: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField.jobTitle}
          />
          {/* no of reference section */}
          <TextField
            label='No of References'
            isRequired
            className='mt-5'
            type='number'
            value={candidateDetails?.noOfReferences}
            onChange={(e: any) => {
              if (e.target.value > 0 && e.target.value <= 5) {
                setCandidateDetails({
                  ...candidateDetails,
                  noOfReferences: e.target.value,
                })
              }
            }}
            placeholder=''
            isErrored={isErrorField.noOfReferences}
          />
          {isSettingsExist(agencyAppSettings) &&
            isSettingsExist(candidateSettings) && (
              <CandidateSettingsForm
                ref={candidateSettingsFormRef}
                agencySettings={agencyAppSettings}
                candidateSettings={candidateSettings}
                setCandidateSettings={setCandidateSettings}
              />
            )}
        </div>
      ) : (
        <RequestSpecificRefereeForm
          refereeCount={candidateDetails?.noOfReferences}
          setReferenceTypes={setReferenceTypes}
        />
      )}
    </RoundedModal>
  )
}
