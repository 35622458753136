import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { SampleReferenceReportPreview } from 'components/ui/reports/reference-report'

import { ReportSettingsAccordionForm } from './ReportSettingsAccordionForm'

export const ReportSettingsTab = () => {
  return (
    <Column>
      <RowWithAlignment className='space-x-5' itemAlignment='start'>
        <div className='w-2/5'>
          <ReportSettingsAccordionForm />
        </div>
        <div className='w-3/5'>
          <SampleReferenceReportPreview />
        </div>
      </RowWithAlignment>
    </Column>
  )
}
