import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMonths, addYears } from 'date-fns'
import { setOnboardingFixedSubscriptionPackageId } from 'features/onboarding/store'
import {
  FixedSubscriptionPackageDto,
  getSubscriptionPackages,
  SubscriptionPlanType,
  SubscriptionType,
} from 'features/subscriptions'

import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { dateToReadableString } from 'utils'

import { SubscriptionTypeRadio } from '../SubscriptionTypeRadio'

import { BonusCreditBanner } from './BonusCreditBanner'
import { CardDetailsSection } from './CardDetailsSection'
import { FreeCreditSection } from './FreeCreditSection'

type SelectPackageRightContentProps = {
  selectedPackageType: SubscriptionPlanType
  packagePeriod: SubscriptionType
  setPackagePeriod: any
}

export const SelectPackageRightContent = ({
  selectedPackageType,
  packagePeriod,
  setPackagePeriod,
}: SelectPackageRightContentProps) => {
  const dispatch = useDispatch()

  const { fixedMonthlyPackages, fixedYearlyPackages } = useSelector(
    getSubscriptionPackages,
  )

  const selectedFixedPackages = useMemo(
    () =>
      [...fixedMonthlyPackages, ...fixedYearlyPackages].filter(
        (p) => p.name === selectedPackageType,
      ),
    [selectedPackageType, fixedMonthlyPackages, fixedYearlyPackages],
  )

  const selectedPackageDetail = useMemo(
    () =>
      selectedFixedPackages?.find(
        (item: FixedSubscriptionPackageDto) =>
          item.subscriptionType === packagePeriod,
      ),
    [packagePeriod, selectedFixedPackages],
  )

  useEffect(() => {
    if (selectedPackageDetail?.id)
      dispatch(
        setOnboardingFixedSubscriptionPackageId(selectedPackageDetail.id),
      )
  }, [selectedPackageDetail])

  // const amountSummary = useSelector(getPayAsYouGoPaymentAmountSummary)

  const saveAmount = useMemo(() => {
    if (selectedFixedPackages) {
      const { monthlyAmount, yearlyAmount } = selectedFixedPackages.reduce(
        (acc, p) => {
          if (p.subscriptionType === SubscriptionType.Monthly) {
            p.promotionDiscountRate > 0
              ? (acc.monthlyAmount =
                  p.packageAmount * (1 - p.promotionDiscountRate))
              : (acc.monthlyAmount = p.packageAmount)
          } else if (p.subscriptionType === SubscriptionType.Yearly) {
            p.promotionDiscountRate > 0
              ? (acc.yearlyAmount =
                  p.packageAmount * (1 - p.promotionDiscountRate))
              : (acc.yearlyAmount = p.packageAmount)
          }
          return acc
        },
        { monthlyAmount: 0, yearlyAmount: 0 },
      )

      return monthlyAmount * 12 - yearlyAmount
    }

    return 0
  }, [selectedFixedPackages])

  const originalAmountWithTax = useMemo(() => {
    const amount = selectedPackageDetail
      ? selectedPackageDetail?.packageAmount * 1.1
      : 0
    return amount.toFixed(2)
  }, [selectedPackageDetail?.packageAmount])

  const discountedAmountWithTax = useMemo(() => {
    if (
      selectedPackageDetail &&
      selectedPackageDetail?.promotionDiscountRate > 0
    ) {
      const amount =
        selectedPackageDetail?.packageAmount *
        (1 - selectedPackageDetail.promotionDiscountRate) *
        1.1
      return amount.toFixed(2)
    }

    return 0
  }, [selectedPackageDetail?.packageAmount])

  const selectedPackage = useMemo(
    () =>
      selectedFixedPackages?.find((p) => p.subscriptionType === packagePeriod),
    [selectedFixedPackages, packagePeriod],
  )

  return selectedPackageType == SubscriptionPlanType.PAYG ? (
    <FreeCreditSection />
  ) : (
    <div className='flex flex-col items-start justify-start w-full h-[calc(100%-64px)] px-[110px] pt-[52px] pb-[34px] overflow-y-auto styled-scroll'>
      {/* {selectedPackageType !== SubscriptionPlanType.PAYG && ( */}

      {packagePeriod != SubscriptionType.Unlimited && selectedPackage?.name && (
        <BonusCreditBanner
          packagename={selectedPackage.name}
          subscriptionType={packagePeriod}
        />
      )}

      <div className='flex flex-col w-full p-6 border rounded-lg border-Gray-200'>
        <div className='pb-[27px] font-SemiBold text-base'>Billing Period</div>

        <div className='flex w-full gap-x-6 ' key={packagePeriod}>
          <SubscriptionTypeRadio
            isSelected={packagePeriod === SubscriptionType.Yearly}
            defaultChecked={packagePeriod === SubscriptionType.Yearly}
            isMonthly={false}
            saveAmount={saveAmount}
            onChange={() => {
              setPackagePeriod(SubscriptionType.Yearly)
            }}
            onRadioClick={() => {
              setPackagePeriod(SubscriptionType.Yearly)
            }}
            selectedFixedPackage={selectedFixedPackages?.find(
              (p) => p.subscriptionType === SubscriptionType.Yearly,
            )}
          />

          <SubscriptionTypeRadio
            isSelected={packagePeriod === SubscriptionType.Monthly}
            defaultChecked={packagePeriod === SubscriptionType.Monthly}
            isMonthly={true}
            onChange={() => {
              setPackagePeriod(SubscriptionType.Monthly)
            }}
            onRadioClick={() => {
              setPackagePeriod(SubscriptionType.Monthly)
            }}
            selectedFixedPackage={selectedFixedPackages?.find(
              (p) => p.subscriptionType === SubscriptionType.Monthly,
            )}
          />
        </div>
      </div>
      {/* )} */}

      {/* {selectedPackageType === SubscriptionPlanType.PAYG && (
        <PAYGCreditsSection />
      )} */}

      <CardDetailsSection
        subscriptionPlanType={selectedPackageType}
        packagePeriod={packagePeriod}
      />

      <Column>
        <div className='flex flex-col text-base leading-[20px] font-Medium text-[#414244] mt-6'>
          {/* {selectedPackageType == SubscriptionPlanType.PAYG ? (
            <Row>
              <span className='mr-1'>You will be charged</span>
              <span className='mr-1'>
                ${amountSummary.totalAmount.toFixed(2)}{' '}
              </span>
            </Row>
          ) : ( */}
          <Row>
            <span className='mr-1'>You will be charged</span>{' '}
            {selectedPackageDetail &&
            selectedPackageDetail?.promotionDiscountRate > 0 ? (
              <p>
                <del className='ml-1 text-Red-600'>
                  {' '}
                  ${originalAmountWithTax}{' '}
                </del>{' '}
                <span className='mr-1'>${discountedAmountWithTax} </span>
              </p>
            ) : (
              <span className='mr-1'>${originalAmountWithTax} </span>
            )}{' '}
            {selectedPackageDetail?.subscriptionType === SubscriptionType.Yearly
              ? ' AUD Per Year '
              : ' AUD Per Month '}
            starting from ({dateToReadableString(new Date())} -{' '}
            {selectedPackageDetail?.subscriptionType === SubscriptionType.Yearly
              ? dateToReadableString(addYears(new Date(), 1))
              : dateToReadableString(addMonths(new Date(), 1))}
            )
          </Row>
          {/* )} */}
          <span className='mt-1'>You can cancel anytime</span>
        </div>

        <span className='text-[#64676C] text-extra-small font-Regular tracking-[0.25px] mt-1'>
          By clicking Subscribe, you agree to our
          <a
            href='https://www.refhub.com.au/privacy-policy'
            target='_blank'
            className='mx-1 text-Cobalt-600 font-Medium'>
            Terms & conditions
          </a>
          and
          <a
            href='https://www.refhub.com.au/privacy-policy'
            target='_blank'
            className='mx-1 text-Cobalt-600 font-Medium'>
            Terms of service
          </a>
        </span>
      </Column>
    </div>
  )
}
