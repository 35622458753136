import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { Row, RowWithAlignment } from 'components/atoms/Row'
import { timeToAESTWithSeconds } from 'utils'

import { useGetPromotionByCode } from '../api/getPromotionByCode'
import { setPromotion } from '../store'

type ApplyPromotionRowProps = {
  isModalActive: boolean
}

export const ApplyPromotionRow = ({
  isModalActive,
}: ApplyPromotionRowProps) => {
  const dispatch = useDispatch()
  const [promoCode, setPromoCode] = useState<string>('')
  const [applyPromo, setApplyPromo] = useState(false)
  const [discountMessage, setDiscountMessage] = useState<string>('')

  const { refetch } = useGetPromotionByCode(
    isModalActive && applyPromo,
    promoCode,
    (promotion) => {
      dispatch(setPromotion(promotion))
      setDiscountMessage(`Discount Applied`)
      setApplyPromo(false)
    },
    () => {
      dispatch(setPromotion(null))
      setDiscountMessage('Code Invalid')
      setApplyPromo(false)
    },
  )

  useEffect(() => {
    setPromoCode('')
    dispatch(setPromotion(null))
  }, [isModalActive])

  useEffect(() => {
    setDiscountMessage('')
    dispatch(setPromotion(null))
  }, [promoCode])

  useEffect(() => {
    if (applyPromo) {
      refetch()
    }
  }, [applyPromo])

  return (
    <Row>
      <RowWithAlignment className='w-1/2' justifyAlignment='start'>
        <TextField
          className='w-3/4 m-2'
          isRequired
          value={promoCode}
          onChange={(e: any) => setPromoCode(e.target.value)}
          placeholder='Promo Code'
          isErrored={discountMessage.includes('Invalid')}
        />
        <Button
          onClick={() => setApplyPromo(true)}
          className='w-[80px] ml-1'
          textTheme='black'
          theme='white'>
          Apply
        </Button>
      </RowWithAlignment>

      <RowWithAlignment className='w-1/2 px-2 ml-6' justifyAlignment='end'>
        {discountMessage && (
          <span
            className={CN(
              'text-extra-small',
              discountMessage.includes('Invalid')
                ? 'text-Red-700'
                : 'text-Green-700',
            )}>
            {discountMessage}
          </span>
        )}
      </RowWithAlignment>
    </Row>
  )
}
