/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { PoliceCheckHistoryList } from './PoliceCheckHistoryList'

export const PoliceCheckHistoryListColumn = () => {
  const COLUMNS = [
    {
      Header: 'candidateNo',
      accessor: 'candidateNo',
      forSorting: 'candidateNo',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.candidateNo}</div>
      },
    },
    {
      Header: 'history',
      accessor: 'history',
      forSorting: 'history',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.history}</div>
      },
    },
  ]

  return COLUMNS
}

export default PoliceCheckHistoryList
