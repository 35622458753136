import { useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import addRefereeCover from 'assets/images/addRefereeCover.svg'

import { Card } from 'components/atoms/Card'
import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'

import { AddRefereeSelectionModal } from './AddRefereeSelectionModal'

type AddNewRefereeCardProps = {
  candidateId: string
  getCandidateById: any
  setShowAddRefereeModal: any
  activeRefereeCount: number
}

export const AddNewRefereeCard = ({
  candidateId,
  getCandidateById,
  activeRefereeCount,
  setShowAddRefereeModal,
}: AddNewRefereeCardProps) => {
  const [isAddRefereeSelectionModalOpen, setIsAddRefereeSelectionModalOpen] =
    useState(false)

  return (
    <div>
      <Card className='w-full min-h-[175px] !p-0'>
        <RowWithAlignment>
          <div className='flex items-end justify-end w-[250px] mx-5 '>
            <img
              src={addRefereeCover}
              alt=''
              className='object-contain h-[175px] w-[180px] '
            />
          </div>

          <div className='flex items-center justify-between w-full'>
            <Column className='w-full ml-5'>
              <h1 className='text-heading-3 font-SemiBold'>
                Add a New <span className='text-[#105DA4]'> Referee </span>
                Quickly
              </h1>
              <p className='text-base text-Gray-500'>
                Request additional referee details from the candidate or add
                referee details manually. <br /> This allows you to reach out to
                additional contacts whenever necessary.
              </p>
            </Column>
            <div className='w-[180px] mr-16 ml-4'>
              <Button
                className='h-[40px] w-[160px]'
                iconLeft={<i className='ri-add-fill' />}
                onClick={() => {
                  setIsAddRefereeSelectionModalOpen(true)
                }}>
                Add Referee
              </Button>
            </div>
          </div>
        </RowWithAlignment>
      </Card>
      <AddRefereeSelectionModal
        isModalActive={isAddRefereeSelectionModalOpen}
        setIsModalActive={setIsAddRefereeSelectionModalOpen}
        candidateId={candidateId}
        getCandidateById={getCandidateById}
        activeRefereeCount={activeRefereeCount}
        setShowAddRefereeModal={setShowAddRefereeModal}
      />
    </div>
  )
}
