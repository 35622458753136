import { Button } from '@labourhub/labour-hub-ds'
import moment from 'moment'

import { Modal, ModalFooter } from 'components/molecules'

type RequestRefereeReminderModalProps = {
  isActive: boolean
  requestSendDate: string | null | undefined
  lastSendRequest: string | null | undefined
  setIsActive: (value: boolean) => void
  onReminderClick: () => void
  [x: string]: any
}

export const RequestRefereeReminderModal = ({
  isActive,
  setIsActive,
  onReminderClick,
  requestSendDate,
  lastSendRequest,
}: RequestRefereeReminderModalProps) => {
  return (
    <Modal
      subHeaderTitle='Remind candidate to send referee information'
      headerTitle='Send Request Referee Reminder'
      isFooterShow={true}
      size='md'
      isActive={isActive}
      onHeaderCloseButtonClick={() => {
        setIsActive(false)
      }}
      onOverlayClick={() => {
        setIsActive(false)
      }}
      primaryButtonTitle='Download'
      secondaryButtonTitle='Cancel'>
      <div className='flex flex-col p-6 w-full'>
        <div className='flex flex-col w-full mb-6'>
          <div className='flex text-small text-Gray-500'>
            <span className=' mb-3 w-1/3'>Request sent on:</span>
            <span className='font-SemiBold w-2/3'>
              {requestSendDate
                ? moment(requestSendDate).format('DD/MM/yyyy')
                : 'Never'}
            </span>
          </div>
          <div className='flex text-small text-Gray-500'>
            <span className='w-1/3'>Reminder last sent on:</span>
            <span className='font-SemiBold w-2/3'>
              {lastSendRequest
                ? moment(lastSendRequest).format('DD/MM/yyyy')
                : 'Never'}
            </span>
          </div>
        </div>
        <span className='text-small text-Gray-800'>
          by clicking on Send Reminder, you will send a new reminder to
          candidate’s inbox.
        </span>
      </div>
      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            onClick={() => {
              onReminderClick()
            }}
            className='mr-2'
            theme='cobalt'>
            Send Reminder
          </Button>
          <Button
            onClick={() => {
              setIsActive(false)
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
