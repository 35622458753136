/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { getAgency } from 'features/agencies/store/selectors'
import { getOnboardingStore } from 'features/onboarding'
import { getQuestionnaires } from 'features/questionnaires/store/selectors'
import { motion } from 'framer-motion'
import { settingsMenu } from 'static-data/mainSideMenu'
import {
  setIsExpanded,
  setMenu as acSetMenu,
} from 'store/reducers/side-menu/mainSideMenuReducer'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import MenuItem from 'components/common/Sidebar/MenuItem'
import { HelpCenterModal } from 'components/molecules'
import { cn } from 'utils'

interface SidebarProps {
  className: string
  wrapperClassName?: string
}

/** this is the main components of main side bar */
export const MainSideBar: FC<SidebarProps> = ({
  className,
  wrapperClassName,
}: SidebarProps) => {
  const [isActiveHelpCenterModal, setIsActiveHelpCenterModal] = useState(false)

  const [isSettingMenuActive, setIsSettingMenuActive] = useState(
    settingsMenu.isActive,
  )

  const { menuItems: allItems, isExpanded } = useSelector(
    (state: any) => state.sideMenuList,
  )

  const { showGuideTooltipModal } = useSelector(getOnboardingStore)

  const { userDetails, isLoading, isHeaderBarDetailsUpdated } = useSelector(
    (state: any) => state.user,
  )

  const { agency: agencyDetails }: any = useSelector(getAgency)
  const { totalQuestionnaires }: any = useSelector(getQuestionnaires)

  const menuItems = allItems.filter((item: any) => item.menuPosition === 'left')

  const isCheckListExist = localStorage.getItem('isChecklist')

  const dispatch = useDispatch()
  const location = useLocation()

  /** handle active tab when page reload */
  useEffect(() => {
    const { pathname } = location
    const route = pathname.split('/')[1]

    if (menuItems.length > 0) {
      const itemsToUpdate = menuItems.map((menuItem: any) => {
        return {
          ...menuItem,
          isActive: false,
        }
      })

      const cloneItemsToUpdate = JSON.parse(JSON.stringify(itemsToUpdate))

      // Menu
      if (route || `${route}` === '' || route === '/' || route === ' ') {
        const selectedIndex = cloneItemsToUpdate?.findIndex((item: any) => {
          return item.link === `/${route}`
        })

        if (selectedIndex > -1) {
          cloneItemsToUpdate[selectedIndex].isActive = true
          dispatch(acSetMenu(cloneItemsToUpdate.slice()))
        }
      }
    }

    /** updated header bar details */
    dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
  }, [location])

  /** side bar main styles */
  const SidebarClasses = CN(
    `sidebar flex flex-col h-full flex-shrink-0 bg-white shadow relative`,
    className,
  )
  const isSuperAdmin = userDetails?.roleName === 'SuperAdministrator'
  const isAdmin = userDetails?.roleName === 'Administrator'
  const isConsultant = userDetails?.roleName === 'Consultant'

  const DisplaySideMenuItems = (): JSX.Element => {
    return menuItems.map((mainMenuitem: any, idx: number) => {
      if (
        (isSuperAdmin && mainMenuitem?.title === 'Super Admin Dashboard') ||
        ((isSuperAdmin || isAdmin) && mainMenuitem?.title === 'Company Profile')
      ) {
        return (
          <div key={mainMenuitem.id}>
            <div className='flex h-[1px] bg-Gray-200 mt-[12px]'></div>
            <MenuItem
              menuItems={menuItems}
              item={mainMenuitem}
              key={mainMenuitem.id}
              index={idx}
              setIsSettingMenuActive={setIsSettingMenuActive}
              isExpand={isExpanded}
            />
          </div>
        )
      }

      if (
        (isAdmin && mainMenuitem?.title === 'Super Admin Dashboard') ||
        (isConsultant &&
          (mainMenuitem?.title === 'Company Profile' ||
            mainMenuitem?.title === 'Super Admin Dashboard' ||
            mainMenuitem?.title === 'Settings' ||
            mainMenuitem?.title === 'Users'))
      ) {
        return <div key={mainMenuitem.id}></div>
      }

      return (
        <MenuItem
          menuItems={menuItems}
          item={mainMenuitem}
          key={mainMenuitem.id}
          index={idx}
          setIsSettingMenuActive={setIsSettingMenuActive}
          isExpand={isExpanded}
        />
      )
    })
  }

  return (
    <div className={SidebarClasses}>
      <motion.nav
        className={CN(
          'flex flex-col w-full relative px-4 h-full',
          wrapperClassName,
          {
            'min-w-[240px]': isExpanded,
            'min-w-[72px]': !isExpanded,
          },
        )}
        initial={{ width: isExpanded ? 240 : 72 }} // Initial width based on isExpand state
        animate={{ width: isExpanded ? 240 : 72 }} // Target width when expanded or collapsed
        exit={{ width: 0 }} // Animation when unmounting
        transition={{ duration: 0.3 }} // Animation duration
      >
        <div
          className='absolute flex w-[24px] h-[24px] bg-white hover:bg-Gray-100 border border-Gray-300 p-1 rounded-[7px] justify-center items-center right-0 cursor-pointer mt-5 -mr-[12px]'
          onClick={(e) => {
            e.stopPropagation()
            dispatch(setIsExpanded(!isExpanded))
          }}>
          <i
            className={CN('text-[16px] leading-none text-Gray-500 ', {
              'ri-arrow-left-s-line': isExpanded,
              'ri-arrow-right-s-line': !isExpanded,
            })}
          />
        </div>
        <div className='w-full flex flex-col overflow-y-auto styled-scroll max-h-[calc(100%-50px)]'>
          {!isLoading && <DisplaySideMenuItems />}

          <div className='flex h-[1px] bg-Gray-200 mt-[12px]'></div>
          <div
            className={CN(
              'flex items-center h-10 mt-3 mb-3 rounded-lg help-btn hover:bg-Gray-200 cursor-pointer',
              {
                'w-full p-2 justify-start': isExpanded,
                'w-10 justify-center': !isExpanded,
              },
            )}
            onClick={() => {
              setIsActiveHelpCenterModal(true)
            }}
            data-tip='help'
            data-for='help'>
            <i className='ri-question-line text-[24px] text-[#4B5563]'></i>
            {!isExpanded && (
              <ReactTooltip
                id='help'
                place='right'
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#4B5563'
                className='rounded-full text-extra-small'
              />
            )}

            {isExpanded && (
              <div className='pl-2 text-[16px] leading-[18px] font-Medium text-Gray-600'>
                Help Guides
              </div>
            )}
          </div>
          {isExpanded && isCheckListExist === 'true' && isAdmin && (
            <div className='flex flex-col items-center justify-start w-full pt-3 pl-2 border-t border-Gray-200'>
              <div className='w-full text-[16px] leading-[18px] font-Medium text-Gray-600  pb-3'>
                Setup checklist
              </div>
              <div className='w-full flex flex-col justify-start items-center text-extra-small gap-y-[14px] pl-4 border-l-2 border-l-[#E5E7EB]'>
                <span
                  className={CN(
                    'w-full flex justify-start items-center text-[#999]',
                    {
                      '!text-Gray-800': agencyDetails?.logoUrl,
                    },
                  )}>
                  Company Logo
                  {agencyDetails?.logoUrl && (
                    <i className='ml-1 ri-checkbox-circle-line text-Green-600' />
                  )}
                </span>
                <span
                  className={CN(
                    'w-full  flex justify-start items-center text-[#999]',
                    {
                      '!text-Gray-800':
                        agencyDetails?.name &&
                        agencyDetails?.email &&
                        agencyDetails?.phone &&
                        agencyDetails?.size &&
                        agencyDetails?.website &&
                        (agencyDetails?.address?.countryCode === 'AU'
                          ? agencyDetails?.abn
                          : true),
                    },
                  )}>
                  Company Basic Details
                  {agencyDetails?.name &&
                    agencyDetails?.email &&
                    agencyDetails?.phone &&
                    agencyDetails?.size &&
                    agencyDetails?.website &&
                    (agencyDetails?.address?.countryCode === 'AU'
                      ? agencyDetails?.abn
                      : true) && (
                      <i className='ml-1 ri-checkbox-circle-line text-Green-600' />
                    )}
                </span>
                <span
                  className={CN(
                    'w-full  flex justify-start items-center text-[#999]',
                    {
                      '!text-Gray-800': agencyDetails?.privacyPolicy,
                    },
                  )}>
                  Privacy Policy URL
                  {agencyDetails?.privacyPolicy && (
                    <i className='ml-1 ri-checkbox-circle-line text-Green-600' />
                  )}
                </span>
                <span
                  className={CN(
                    'w-full  flex justify-start items-center text-[#999]',
                    {
                      '!text-Gray-800': totalQuestionnaires > 1,
                    },
                  )}>
                  Create your first template
                  {totalQuestionnaires > 1 && (
                    <i className='ml-1 ri-checkbox-circle-line text-Green-600' />
                  )}
                </span>
              </div>

              <div className='w-full'>
                <Button
                  className='mt-3 mb-1'
                  iconLeft={<i className='ri-delete-bin-line text-[16px]' />}
                  onClick={() => {
                    localStorage.setItem('isChecklist', 'false')
                  }}
                  textTheme='black'
                  size='sm'
                  theme='white'>
                  Dismiss Checklist
                </Button>
              </div>
            </div>
          )}
        </div>
      </motion.nav>

      {/* side bar footer */}
      <div className='absolute bottom-0 flex flex-col items-start justify-start w-full px-4 mb-2'>
        {!isExpanded ? (
          <div className='flex items-center justify-center w-10 h-10 text-white rounded-lg text-heading-4 bg-Red-100'>
            <i className='ri-error-warning-line' />
          </div>
        ) : (
          <div
            className='h-10 p-2 flex justify-start items-center text-Gray-600 text-[16px] font-Medium cursor-pointer'
            onClick={() => {
              dispatch(setIsExpanded(false))
            }}>
            <i className='ri-arrow-left-line text-[16px] font-Medium mr-2' />{' '}
            collapse
          </div>
        )}
      </div>

      <HelpCenterModal
        isActive={isActiveHelpCenterModal}
        setIsActive={setIsActiveHelpCenterModal}
      />
    </div>
  )
}

export default MainSideBar
