import { createSlice } from '@reduxjs/toolkit'

import { PromotionDto } from '../types'

export type PromotionStoreProps = {
  promotion: PromotionDto | null
  promotionMessage: string
}

const initialState: PromotionStoreProps = {
  promotion: null,
  promotionMessage: 'Code Invalid',
}

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setPromotionState(state, { payload }) {
      state.promotion = payload
    },
    setPromotionMessageState(state, { payload }) {
      state.promotionMessage = payload
    },
  },
})

export const { setPromotionState, setPromotionMessageState } =
  promotionSlice.actions

export default promotionSlice.reducer
