import { createSlice } from '@reduxjs/toolkit'
import { QuestionnaireCategoryType } from 'api/questionnaires'

type QuestionnaireStore = {
  totalQuestionnaires: any
  isRefreshList: boolean
  aiQuestionData: any
  filterVariables: {
    searchKeyword: string
    status: string
    type: string
    filteredSurveyType: string
  }
  surveyType: QuestionnaireCategoryType
}

const initialState: QuestionnaireStore = {
  totalQuestionnaires: null,
  isRefreshList: false,
  aiQuestionData: null,
  filterVariables: {
    searchKeyword: '',
    status: '',
    type: '',
    filteredSurveyType: '',
  },
  surveyType: QuestionnaireCategoryType.ReferenceCheck,
}

const questionnaire = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setTotalQuestionnairesState(state, { payload }) {
      state.totalQuestionnaires = payload
    },
    setFilterVariablesState(state, { payload }) {
      state.filterVariables = payload
    },
    setIsRefreshListState(state) {
      state.isRefreshList = !state.isRefreshList
    },
    setAIQuestionDataState(state, { payload }) {
      state.aiQuestionData = payload
    },
    setSurveyTypeState(state, { payload }) {
      state.surveyType = payload
    },
  },
})

export const {
  setTotalQuestionnairesState,
  setIsRefreshListState,
  setAIQuestionDataState,
  setFilterVariablesState,
  setSurveyTypeState,
} = questionnaire.actions

export default questionnaire.reducer
