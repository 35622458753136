import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  SimpleSelect,
  SimpleSelectOption,
} from '@labourhub/labour-hub-ds'
import { useCreatePayAsYouGoSubscription } from 'api/subscriptions'
import { AmountSummary } from 'features/invoices'
import { getPromotionStore } from 'features/promotions'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { RowWithAlignment } from 'components/atoms/Row'

import { paymentIcons } from '../../data'
import {
  getPaymentStore,
  setPayAsYouGoCreditsModalActive,
  toggleIsRefetchBillingHistory,
  toggleIsRefetchPaymentCards,
} from '../../store'

const newCard = {
  id: 'new',
  cardType: 'Blank',
  label: 'Pay from a new card',
}

type ExisitngPaymentRowProps = {
  amountSummary: AmountSummary
  setIsPaymentCardElementModalActive: any
  setCardBillingAddress: any
  setInvoice: any
  setIsInvoiceModalActive: any
  isPayNowLoading: boolean
  setIsPayNowLoading: any
}

export const ExisitngPaymentRow = ({
  amountSummary,
  setIsPaymentCardElementModalActive,
  setInvoice,
  setCardBillingAddress,
  setIsInvoiceModalActive,
  isPayNowLoading,
  setIsPayNowLoading,
}: ExisitngPaymentRowProps) => {
  const dispatch = useDispatch()
  const { promotion } = useSelector(getPromotionStore)

  const { isHeaderBarDetailsUpdated } = useSelector((state: any) => state.user)

  const { cards, payAsYouGoCredits } = useSelector(getPaymentStore)

  const [selectedCard, setSelectedCard] = useState(newCard)
  const [isClicked, setIsClicked] = useState(false)

  const defaultCard = cards.find((card) => card.isDefaultCard)

  useEffect(() => {
    if (defaultCard) {
      setSelectedCard({
        id: defaultCard.id,
        cardType: defaultCard.cardType,
        label: `**** **** **** ${defaultCard.last4Digits}`,
      })
    } else {
      setSelectedCard(
        cards.length > 0
          ? {
              id: cards[0].id,
              cardType: cards[0].cardType,
              label: `**** **** **** ${cards[0].last4Digits}`,
            }
          : newCard,
      )
    }
  }, [cards, defaultCard])

  const { mutate } = useCreatePayAsYouGoSubscription()

  const handlePayment = () => {
    setIsPayNowLoading(true)
    setInvoice(null)
    if (selectedCard.id == newCard.id) {
      setIsPaymentCardElementModalActive(true)
    } else {
      setIsClicked(true)
      setIsPayNowLoading(true)
      mutate(
        {
          paymentCardId: selectedCard.id,
          promotionCode: promotion ? promotion.promotionCode : undefined,
          credits: payAsYouGoCredits,
        },
        {
          onSuccess: ({ data }: any) => {
            setInvoice(data.invoice)
            setCardBillingAddress(data.billingAddress)
            dispatch(toggleIsRefetchBillingHistory())
            dispatch(toggleIsRefetchPaymentCards())
            dispatch(setPayAsYouGoCreditsModalActive(false))
            dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
          },
          onError: () => {
            setInvoice(null)
          },
          onSettled: () => {
            setIsPayNowLoading(false)
            setIsClicked(false)
            setIsInvoiceModalActive(true)
          },
        },
      )
    }
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      'backgroundColor':
        state.data.id === selectedCard.id ? 'rgb(243 244 246)' : 'white',
      'color': 'black',
      '&:hover': {
        backgroundColor: 'rgb(243 244 246)',
        cursor: 'pointer',
      },
    }),
  }

  return (
    <>
      <RowWithAlignment className='p-1'>
        <div className='w-full mb-3'>
          <SimpleSelect
            label='Select payment method'
            className='mt-3 mb-1 w-full z-[100]'
            options={[
              ...cards.map((card) => ({
                id: card.id,
                cardType: card.cardType,
                label: `**** **** **** ${card.last4Digits}`,
              })),
              newCard,
            ]}
            styles={customStyles}
            menuPlacement='top'
            components={{
              SingleValue: (props: any) => (
                <div className='flex items-center justify-start space-x-2 -mt-7'>
                  <img src={paymentIcons[selectedCard.cardType]} alt='' />
                  <span className='text-Gray-500'> {props.children}</span>
                </div>
              ),
            }}
            value={selectedCard}
            onChange={(card: SimpleSelectOption) => setSelectedCard(card)}
          />
          <p className='text-Gray-500 text-extra-small'>
            By purchasing credits, you agree to our terms and conditions
          </p>
        </div>
        <Button
          onClick={handlePayment}
          isLoading={isPayNowLoading}
          isDisabled={amountSummary.totalAmount == 0 || isClicked}
          className='w-[200px] mt-1 ml-3'>
          Pay Now
        </Button>
      </RowWithAlignment>
    </>
  )
}
