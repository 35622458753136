/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import LogoMini from 'assets/images/refHubLogoText.svg'
import { CardBillingAddressDto } from 'features/payments'

import { RowWithAlignment } from 'components/atoms/Row'
import { Modal } from 'components/molecules'

import { refHubAddress, refHubEmail, refHubPhone } from '../data'
import { AmountSummary, InvoiceDto } from '../types'

import { InvoiceAmountSummary } from './InvoiceAmountSummary'

type InvoiceTemplateModalProps = {
  isActive: boolean
  setClose: any
  cardBillingAddress?: CardBillingAddressDto
  invoice: InvoiceDto | null
  amountSummary: AmountSummary
  agencyProfileDetails: any
}

export const InvoiceTemplateModal = ({
  isActive,
  setClose,
  cardBillingAddress,
  invoice,
  amountSummary,
  agencyProfileDetails,
}: InvoiceTemplateModalProps) => {
  const componentRef: any = useRef(null)

  const formatDate = (inputDate) => {
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' }
    const date = new Date(inputDate)
    return date.toLocaleDateString('en-US', options)
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      if (isActive) {
        setClose(false)
      }
    },
  })

  useEffect(() => {
    if (isActive && componentRef.current) {
      handlePrint()
    }
  }, [isActive])

  return (
    <Modal
      headerTitle=''
      isFooterShow={false}
      className='mt-[-1000px]'
      size='lg'
      customWidth='70vw'
      isActive={isActive}
      isHeaderShow={false}
      onHeaderCloseButtonClick={() => {
        setClose(false)
      }}
      onOverlayClick={() => {
        setClose(false)
      }}>
      <div
        className='flex flex-col items-start justify-start w-full p-5 overflow-y-auto h-fit'
        ref={componentRef}>
        <div className='flex items-center justify-between w-full'>
          <div className='text-heading-3 font-SemiBold'>Invoice</div>

          <img
            src={LogoMini}
            alt=''
            className='flex justify-center items-center h-[24px] px-4 cursor-pointer'
          />
        </div>

        <table className='w-6/12 mt-5'>
          <tbody>
            <TableRow
              label='Invoice Number'
              value={
                invoice?.invoiceNumber ? '#' + invoice?.invoiceNumber : '-'
              }
            />
            <TableRow
              label='Date of issue'
              value={formatDate(invoice?.createdOn)}
            />
            {/* <TableRow label='Date due' value='Spt 12, 2023' /> */}
            <TableRow label='Company Name' value={agencyProfileDetails?.name} />
          </tbody>
        </table>

        <div className='flex w-full mt-5 '>
          <div className='flex flex-1'>
            <div className='flex flex-col w-8/12'>
              <span className='font-SemiBold'>Ref Hub</span>
              <div>{refHubAddress}</div>
              <div>{refHubPhone}</div>
              <div>{refHubEmail}</div>
            </div>
          </div>

          <div className='flex flex-1'>
            <div className='flex flex-col w-8/12'>
              <span className='font-SemiBold'>Bill to</span>
              <div>
                {cardBillingAddress
                  ? `${cardBillingAddress?.firstName} ${cardBillingAddress?.lastName}`
                  : agencyProfileDetails?.name}
              </div>
              {cardBillingAddress ? (
                <div className='flex flex-col'>
                  <div>
                    {`${cardBillingAddress.lineOne}, `}
                    {cardBillingAddress?.suburb &&
                      `${cardBillingAddress.suburb}, `}
                    {`${cardBillingAddress.state}, `}
                    {`${cardBillingAddress.postCode}`}
                  </div>
                  <div>{`${cardBillingAddress.country}`}</div>
                </div>
              ) : agencyProfileDetails?.address ? (
                <div className='flex flex-col'>
                  <div>
                    {agencyProfileDetails?.address?.lineOne &&
                      `${agencyProfileDetails?.address?.lineOne}, `}
                    {agencyProfileDetails?.address?.suburb &&
                      `${agencyProfileDetails?.address?.suburb}, `}
                    {agencyProfileDetails?.address?.state &&
                      `${agencyProfileDetails?.address?.state}, `}
                    {agencyProfileDetails?.address?.postCode &&
                      `${agencyProfileDetails?.address?.postCode}`}
                  </div>
                  <div>
                    {agencyProfileDetails?.address?.country &&
                      `${agencyProfileDetails?.address?.country}`}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div>{agencyProfileDetails?.email}</div>
            </div>
          </div>
        </div>

        {/* <div className='mt-6 text-heading-4 font-Bold'>
          $198.00 USD due September 11,2023
        </div> */}

        {invoice && invoice.items?.length > 0 && (
          <div className='flex-col flex-1 w-full pt-4 overflow-y-auto h-fit styled-scroll '>
            <RowWithAlignment className='py-2 pb-1.5 border-b-[2px] border-Gray-600 text-Gray-800 font-SemiBold'>
              <div className='w-6/12'>Description</div>
              <div className='w-1/12'>Qty</div>
              <div className='w-2/12'>Unit price</div>
              <div className='w-3/12 text-right'>Amount</div>
            </RowWithAlignment>

            {invoice.items.map((item) => (
              <RowWithAlignment
                key={item.name}
                className='py-2 pb-3 border-b border-Gray-100'>
                <div className='w-6/12'>
                  <span className='text-Gray-800 font-SemiBold'>
                    {item.name} {invoice.isTrial && '(Trial)'}
                  </span>
                </div>
                <div className='w-1/12'>
                  {item.quantity && (
                    <span className='text-Gray-500 font-Regular'>
                      {item.quantity}
                    </span>
                  )}
                </div>
                <div className='w-2/12'>
                  {item.unitPrice && (
                    <span className='text-Gray-500 font-Regular'>
                      ${item.unitPrice?.toFixed(2)}
                    </span>
                  )}
                </div>
                <div className='w-3/12 text-right'>
                  {item.promotionAmount > 0 ? (
                    <span className='block text-base font-SemiBold'>
                      <del className='text-Red-600 text-small'>
                        ${item.subTotalAmount?.toFixed(2)}
                      </del>{' '}
                      ${item.totalAmount?.toFixed(2)}
                    </span>
                  ) : (
                    <span className='block text-base text-Gray-800 font-SemiBold'>
                      ${item.subTotalAmount?.toFixed(2)}
                    </span>
                  )}
                </div>
              </RowWithAlignment>
            ))}

            <div className='flex justify-end w-full'>
              <div className='w-1/2'>
                <InvoiceAmountSummary
                  className='mt-3'
                  amountSummary={amountSummary}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

type TableRowProps = {
  label: string | React.ReactNode
  value?: string | React.ReactNode
  children?: React.ReactNode
}

const TableRow = ({ label, value, children }: TableRowProps) => (
  <tr>
    <td className='py-1 text-Gray-600 font-Medium text-extra-small'>{label}</td>
    {value && (
      <td className='text-Gray-800 font-SemiBold text-small'>{value}</td>
    )}
    {children && <td>{children}</td>}
  </tr>
)
