import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

type CSVDropZoneProps = {
  setCSVFile: (file: File | undefined) => void
  [x: string]: any
}

export const CSVDropZone = ({
  className,
  setCSVFile,
  ...restProps
}: CSVDropZoneProps) => {
  const CSVDropZoneClasses = CN(`csv-drop-zone`, className)

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFiles(acceptedFiles)
      setCSVFile(acceptedFiles[0])
    },
    [selectedFiles],
  )

  const removeFile = (index: number) => {
    const newFiles = [...selectedFiles]
    newFiles.splice(index, 1)
    setSelectedFiles(newFiles)
    setCSVFile(newFiles[0])
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 2,
    maxSize: 100 * 1024 * 1024,
    accept: {
      'text/csv': ['.csv'],
    },
    onDrop,
  })

  return (
    <div className={CSVDropZoneClasses} {...restProps}>
      <section className=''>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Button
            textTheme='blue'
            size='xl'
            theme='white'
            className='w-[350px] h-[38px]'>
            <i className='ri-upload-2-line text-small' />
            <span className='font-Medium text-Cobalt-600 ml-2'>Upload</span>
          </Button>
        </div>
        <div className='py-2 gap-2'>
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className='flex items-center justify-between pl-2 w-fit min-w-[350px] h-[30px] box-border border border-Gray-200 rounded-md'>
              <div>
                <i className='ri-attachment-2 text-Gray-400 pr-2' />
                <span className='text-small font-Medium text-Gray-800'>
                  {file.name}
                </span>
              </div>
              <button
                onClick={() => {
                  removeFile(index)
                }}>
                <i className='ri-close-line text-Gray-400 pr-2' />
              </button>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}
