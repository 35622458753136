import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { FixedSubscriptionPaymentModal } from 'features/payments'
import { planRelationships } from 'features/subscriptions/data'

import { RowWithAlignment } from 'components/atoms/Row'
import { formatCurrency, formatNumberWithoutTrailingZeros } from 'utils'

import { getSubscriptionStore } from '../../../store'
import {
  FixedSubscriptionPackageDto,
  FixedSubscriptionPackageType,
  SubscriptionMode,
  SubscriptionType,
} from '../../../types'
import { CancelSubscriptionModal } from '../../CancelSubscriptionModal'
import { SubscriptionDowngradeModal } from '../../SubscriptionDowngradeModal'
import { SubscriptionPlanPackageBadge } from '../../SubscriptionPlanPackageBadge'
import { SubscriptionUpgradeModal } from '../../SubscriptionUpgradeModal'

import './PlanPackageCard.scss'

enum ButtonTextType {
  CurrentPack = 'Current Pack',
  StartTrial = 'Start Trial',
  BuyNow = 'Buy Now',
  Downgrade = 'Downgrade',
  Upgrade = 'Upgrade',
}

type PlanPackageCardProps = {
  subscriptionPackage: FixedSubscriptionPackageDto
  setIsFixedSubscriptionPackagesModalActive: any
  isYearlyPlan?: boolean
}

export const PlanPackageCard = ({
  subscriptionPackage,
  setIsFixedSubscriptionPackagesModalActive,
  isYearlyPlan,
}: PlanPackageCardProps) => {
  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  const [isActiveSubscription, setIsActiveSubscription] = useState(
    fixedSubscription?.package.id == subscriptionPackage.id,
  )

  const [subscriptionMode, setSubscriptionMode] = useState(SubscriptionMode.New)

  const [
    isSubscriptionPaymentModalActive,
    setIsSubscriptionPaymentModalActive,
  ] = useState(false)

  const [isCancelSubscriptionModalActive, setIsCancelSubscriptionModalActive] =
    useState(false)

  const [isUpgradeModalActive, setIsUpgradeModalActive] = useState(false)
  const [isDowngradeModalActive, setIsDowngradeModalActive] = useState(false)

  const [buttonText, setButtonText] = useState<string>('Subscribe')

  useEffect(() => {
    setIsActiveSubscription(
      fixedSubscription?.package.id == subscriptionPackage.id,
    )

    if (
      fixedSubscription &&
      fixedSubscription.package.name !=
        FixedSubscriptionPackageType.EnterprisePack
    ) {
      setButtonText(
        planRelationships[
          `${fixedSubscription?.package.name}-${fixedSubscription?.package.subscriptionType}`
        ][
          `${subscriptionPackage.name}-${subscriptionPackage.subscriptionType}`
        ],
      )
    } else if (
      fixedSubscription &&
      fixedSubscription.package.name ==
        FixedSubscriptionPackageType.EnterprisePack
    ) {
      setButtonText(SubscriptionMode.Downgrade)
    } else {
      setButtonText('Subscribe')
    }
  }, [fixedSubscription, subscriptionPackage])

  const handlePackageSubscription = () => {
    if (buttonText == ButtonTextType.CurrentPack) {
      return
    }

    if (buttonText == ButtonTextType.Downgrade) {
      setIsDowngradeModalActive(true)
      setSubscriptionMode(SubscriptionMode.Downgrade)
      return
    } else if (buttonText == ButtonTextType.Upgrade) {
      setIsUpgradeModalActive(true)
      setSubscriptionMode(SubscriptionMode.Upgrade)
      return
    }

    setIsSubscriptionPaymentModalActive(true)
  }

  const packagePrice = useMemo(() => {
    const packageDiscount = subscriptionPackage.promotionDiscountRate ?? 0

    const unitAmount =
      subscriptionPackage.packageAmount /
      (subscriptionPackage.subscriptionType === SubscriptionType.Yearly
        ? subscriptionPackage.referenceCheckLimit * 12
        : subscriptionPackage.referenceCheckLimit)
    const amount =
      packageDiscount > 0 ? unitAmount * (1 - packageDiscount) : unitAmount

    return (
      <span className='font-SemiBold'>
        $
        {isYearlyPlan
          ? amount.toFixed(2)
          : formatNumberWithoutTrailingZeros(amount)}
      </span>
    )
  }, [subscriptionPackage])

  const packageOffers = useMemo(() => {
    const packageDiscount = subscriptionPackage.promotionDiscountRate ?? 0

    const amount =
      packageDiscount > 0
        ? subscriptionPackage.packageAmount * (1 - packageDiscount)
        : subscriptionPackage.packageAmount

    const formattedOriginalAmount = formatCurrency(
      subscriptionPackage.packageAmount,
    )
    const formattedAmount = formatCurrency(amount)

    return [
      <span className='text-Gray-300' key='originalAmount'>
        {packageDiscount > 0 ? (
          <>
            <del className='text-Yellow-100 font-Medium'>
              {formattedOriginalAmount}
            </del>{' '}
            <b className='text-white'>{formattedAmount}</b>{' '}
          </>
        ) : (
          <b className='text-white'>{formattedOriginalAmount}</b>
        )}
        {isYearlyPlan ? ` Per Year` : ` Per Month`} Ex Gst
      </span>,
      <span className='text-Gray-300' key='referenceChecks'>
        <b className='text-white'>
          {subscriptionPackage.referenceCheckLimit || 0}
        </b>{' '}
        Reference Checks Per Month
      </span>,
    ]
  }, [subscriptionPackage, isYearlyPlan])

  return (
    <>
      <div className='relative w-[360px] bg-[#105DA4] pt-5 pb-[17px] pl-10 pr-6 border rounded-lg border-Gray-300'>
        {subscriptionPackage.promotionDiscountRate > 0 && (
          <div
            className={CN('left-ribbon ', {
              'ribbon-yellow': isYearlyPlan,
              'ribbon-orange': !isYearlyPlan,
            })}>
            <span>{subscriptionPackage.promotionDiscountRate * 100}% OFF</span>
          </div>
        )}

        <div className='flex justify-end w-full'>
          <SubscriptionPlanPackageBadge
            size='small'
            isFill={false}
            packageName={subscriptionPackage.name}
          />
        </div>

        <div className='pr-1'>
          <div
            className={CN('flex flex-col justify-start w-full', {
              'mt-5': subscriptionPackage.promotionDiscountRate > 0,
            })}>
            <div className='text-white text-heading-1'>{packagePrice}</div>
            <div className='text-white text-small font-Regular'>
              {subscriptionPackage.description}
            </div>
          </div>

          <ul className='font-Medium text-small mt-[10.5px]'>
            {packageOffers.map((item, index) => (
              <li
                key={index}
                className='flex items-center justify-start mb-[3px] gap-x-1 font-Regular text-white'>
                <i className='ri-check-fill'></i>
                {item}
              </li>
            ))}
          </ul>

          <div className='flex justify-center items-center w-full mt-10 gap-x-[10px]'>
            <Button
              textTheme={
                buttonText == ButtonTextType.CurrentPack ? 'green' : 'black'
              }
              theme={
                buttonText == ButtonTextType.CurrentPack
                  ? 'light-green'
                  : 'white'
              }
              className='!min-h-[42px]'
              isBlock
              onClick={handlePackageSubscription}>
              {buttonText}
            </Button>

            {!fixedSubscription && (
              <Button
                textTheme={'white'}
                theme={'link'}
                className='border-white border px-[17px] py-[9px] rounded-md'
                isBlock
                onClick={() => {
                  window.open('https://www.refhub.com.au/book-a-demo')
                }}>
                Book a Demo
              </Button>
            )}
          </div>

          <RowWithAlignment
            justifyAlignment='center'
            className='w-full mt-2 text-center text-extra-small bottom-4'>
            {isActiveSubscription && fixedSubscription ? (
              <p>
                {/* <span className='block'>
                  {fixedSubscription.isCanceled ? 'Valid upto ' : 'Renews on '}
                  {dateToReadableString(fixedSubscription?.endDate)}
                </span> */}
                {!fixedSubscription.isCanceled ? (
                  <Button
                    theme='link'
                    textTheme='TerraCotta'
                    onClick={() => setIsCancelSubscriptionModalActive(true)}
                    className='cursor-pointer text-TerraCotta-300 text-extra-small'>
                    Cancel Pack
                  </Button>
                ) : (
                  <span className='mt-1 cursor-default text-TerraCotta-300 text-extra-small'>
                    Cancelled
                  </span>
                )}
              </p>
            ) : (
              <p className='p4-5' style={{ visibility: 'hidden' }}></p>
            )}
          </RowWithAlignment>
        </div>
      </div>

      <CancelSubscriptionModal
        isModalActive={isCancelSubscriptionModalActive}
        setIsModalActive={setIsCancelSubscriptionModalActive}
      />

      <FixedSubscriptionPaymentModal
        isModalActive={isSubscriptionPaymentModalActive}
        setIsModalActive={setIsSubscriptionPaymentModalActive}
        setIsFixedSubscriptionPackagesModalActive={
          setIsFixedSubscriptionPackagesModalActive
        }
        subscriptionPackage={subscriptionPackage}
        subscriptionMode={subscriptionMode}
      />

      <SubscriptionUpgradeModal
        isModalActive={isUpgradeModalActive}
        setIsModalActive={setIsUpgradeModalActive}
        setIsSubscriptionPaymentModalActive={
          setIsSubscriptionPaymentModalActive
        }
        setSubscriptionMode={setSubscriptionMode}
        subscriptionPackage={subscriptionPackage}
      />

      <SubscriptionDowngradeModal
        isModalActive={isDowngradeModalActive}
        setIsModalActive={setIsDowngradeModalActive}
        setIsSubscriptionPaymentModalActive={
          setIsSubscriptionPaymentModalActive
        }
        setSubscriptionMode={setSubscriptionMode}
        subscriptionPackage={subscriptionPackage}
      />
    </>
  )
}
