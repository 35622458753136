import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { defaultQuotaItem } from '../data'
import {
  ActiveSubscriptionDto,
  PayAsYouGoSubscriptionPackageType,
  QuotaItem,
  SubscriptionPackagesListDto,
} from '../types'

export type SubscriptionStoreProps = {
  activeSubscriptions: ActiveSubscriptionDto
  fixedQuotas: {
    referenceCheck: QuotaItem
    users: QuotaItem
    sms: QuotaItem
  }
  payAsYouGoQuotas: Record<PayAsYouGoSubscriptionPackageType, QuotaItem>
  payAsYouGoUnitPrices: Record<PayAsYouGoSubscriptionPackageType, number>
  packages: SubscriptionPackagesListDto
}

const initialState: SubscriptionStoreProps = {
  activeSubscriptions: {
    fixedSubscription: null,
    payAsYouGoSubscriptions: null,
  },
  fixedQuotas: {
    referenceCheck: defaultQuotaItem,
    users: defaultQuotaItem,
    sms: defaultQuotaItem,
  },
  payAsYouGoQuotas: {
    PoliceCheck: defaultQuotaItem,
    VolunteerCheck: defaultQuotaItem,
    RightToWorkCheck: defaultQuotaItem,
    ReferenceCheck: defaultQuotaItem,
  },
  payAsYouGoUnitPrices: {
    PoliceCheck: 0,
    VolunteerCheck: 0,
    RightToWorkCheck: 0,
    ReferenceCheck: 0,
  },
  packages: {
    fixedMonthlyPackages: [],
    fixedYearlyPackages: [],
    payAsYouGoPackages: [],
  },
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionsState(state, action: PayloadAction<ActiveSubscriptionDto>) {
      state.activeSubscriptions = action.payload
      const { fixedSubscription, payAsYouGoSubscriptions } =
        action.payload || {}

      if (fixedSubscription) {
        const { quota } = fixedSubscription
        state.fixedQuotas = {
          referenceCheck: quota.ReferenceCheck,
          users: quota.Users,
          sms: quota.Sms,
        }
      }

      if (payAsYouGoSubscriptions && payAsYouGoSubscriptions.length > 0) {
        payAsYouGoSubscriptions.forEach((subscription) => {
          const {
            package: { name },
            quota: { Credit },
          } = subscription
          state.payAsYouGoQuotas[name] = Credit
        })
      }
    },
    setSubscriptionPackagesState(
      state,
      action: PayloadAction<SubscriptionPackagesListDto>,
    ) {
      state.packages = action.payload

      if (action.payload?.payAsYouGoPackages?.length != 0) {
        action.payload.payAsYouGoPackages.forEach((payAsYouGoPackage) => {
          switch (payAsYouGoPackage.name) {
            case PayAsYouGoSubscriptionPackageType.PoliceCheck:
              state.payAsYouGoUnitPrices.PoliceCheck =
                payAsYouGoPackage.unitPrice
              break

            case PayAsYouGoSubscriptionPackageType.VolunteerCheck:
              state.payAsYouGoUnitPrices.VolunteerCheck =
                payAsYouGoPackage.unitPrice
              break

            case PayAsYouGoSubscriptionPackageType.RightToWorkCheck:
              state.payAsYouGoUnitPrices.RightToWorkCheck =
                payAsYouGoPackage.unitPrice
              break

            case PayAsYouGoSubscriptionPackageType.ReferenceCheck:
              state.payAsYouGoUnitPrices.ReferenceCheck =
                payAsYouGoPackage.unitPrice
              break
            default:
              break
          }
        })
      }
    },
  },
})

export const { setSubscriptionsState, setSubscriptionPackagesState } =
  subscriptionSlice.actions

export default subscriptionSlice.reducer
