export type NccCredentialProps = {
  apiKey: string
  secretKey: string
  webhookToken: string
}

export enum PoliceCheckType {
  EMPLOYMENT = 'EMPLOYMENT',
  EMPLOYMENT_HEALTH = 'EMPLOYMENT_HEALTH',
  VOLUNTEER = 'VOLUNTEER',
  VOLUNTEER_HEALTH = 'VOLUNTEER_HEALTH',
  WORK_EXP = 'WORK_EXP',
  WORK_EXP_HEALTH = 'WORK_EXP_HEALTH',
  ACCREDITATION = 'ACCREDITATION',
  OTHER = 'OTHER',
}
