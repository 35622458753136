import { useMutation } from 'react-query'
import { CardBillingAddressDto } from 'api/payments'
import { SubscriptionMode } from 'features/subscriptions'
import { postAPI } from 'framework/api/http'

type CreateOrUpgradeFixedSubscriptionRequest = {
  packageId: string
  subscriptionMode: SubscriptionMode
  cardDetails: CardDetailsDto
}

export type CardDetailsDto = {
  paymentCardId: string
  isSaveMyCardChecked: boolean
  billingAddress?: CardBillingAddressDto
}

export const useCreateOrUpgradeFixedSubscription = () => {
  const mutate = useMutation(
    (request: CreateOrUpgradeFixedSubscriptionRequest) => {
      return postAPI(`/subscriptions/fixed`, request)
    },
  )

  return mutate
}
