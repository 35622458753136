const moment = require('moment-timezone')

export const timeToLocalTimeZoneWithISOFormat = (incomingDate: any) => {
  const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const dateString = moment
    .utc(incomingDate)
    .tz(localtz)
    .format('YYYY/MM/DD h:mm:ss a')

  return dateString
}
