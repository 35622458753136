type ColorizeWordsInBracketsProps = {
  text: string
}

export const ColorizeWordsInBrackets = ({
  text,
}: ColorizeWordsInBracketsProps) => {
  const splitText = text.split(/(\([^)]+\))/) // Split text by brackets and keep the brackets

  return (
    <span>
      {splitText.map((chunk, index) => {
        if (chunk.startsWith('(') && chunk.endsWith(')')) {
          // Apply color to words inside brackets
          const wordsInsideBrackets = chunk.slice(1, -1).split(' ')
          return (
            <span key={index} className='text-Cobalt-600 font-Medium'>
              {wordsInsideBrackets.map((word: string, wordIndex: number) => (
                <span key={wordIndex}>{word} </span>
              ))}
            </span>
          )
        } else {
          return <span key={index}>{chunk}</span>
        }
      })}
    </span>
  )
}
