import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

import { CardDetailsDto } from './createOrUpgradeFixedSubscription'

export type DowngradeFixedSubscriptionDto = {
  packageId: string
  cardDetails: CardDetailsDto
}

export const useDowndgradeFixedSubscription = () => {
  const mutate = useMutation((dto: DowngradeFixedSubscriptionDto) => {
    return postAPI(`/subscriptions/fixed/downgrade`, dto)
  })

  return mutate
}
