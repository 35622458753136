import { useState } from 'react'
import {
  ExecutiveSummaryDto,
  useGetExecutiveSummaryById,
} from 'api/executiveSummary'
import { CandidateProfileBasicDetailsType } from 'framework/api/methods'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { ReferenceReportPreviewModal } from 'components/ui/reports/reference-report'

import './style.scss'

type ExecutiveSummaryReportViewModalProps = ModalProps & {
  candidateId: string
  candidateDetails: CandidateProfileBasicDetailsType
  summaryId: string
}

export const ExecutiveSummaryReportViewModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  candidateDetails,
  summaryId,
}: ExecutiveSummaryReportViewModalProps) => {
  const [summaryDetails, setSummaryDetails] =
    useState<ExecutiveSummaryDto | null>(null)

  const { isLoading, isFetching } = useGetExecutiveSummaryById(
    summaryId,
    (data) => setSummaryDetails(data),
  )

  return (
    <>
      {(isLoading || isFetching) && <PageLoader size='xxs' />}

      {summaryDetails && (
        <ReferenceReportPreviewModal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          candidateId={candidateId}
          reportType={summaryDetails.reportType}
          candidateDetails={candidateDetails}
          stats={summaryDetails.refereeStatsCount}
          sentiment={summaryDetails.candidateSentiment}
          previewData={{
            referees: summaryDetails.referees,
            clientLogoUrl: summaryDetails?.clientLogo,
            executiveSummary: summaryDetails.summary,
            hasClientLogo: !!summaryDetails?.clientLogo,
            hasCompanyLogo: !summaryDetails?.isClientLogoOnly,
          }}
          isSummaryReadMode={true}
        />
      )}
    </>
  )
}
