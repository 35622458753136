/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Badge, Button } from '@labourhub/labour-hub-ds'

import { timeToAESTWithSeconds } from 'utils'

export const getColumns = (
  setSummary: any,
  setShowDownlaodReportModal: any,
  setShowReportDeleteModal: any,
) => {
  const COLUMNS = [
    {
      Header: 'REPORT NO',
      accessor: 'reportNumber',
      forSorting: 'reportNumber',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-auto pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.reportNumber}</div>
      },
    },
    {
      Header: 'REPORT TYPE',
      accessor: 'reportType',
      forSorting: 'reportType',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-auto pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.reportType ?? 'NA'}</div>
      },
    },
    {
      Header: 'REFEREE TYPE',
      accessor: 'refereeType',
      forSorting: 'refereeType',
      isSortable: true,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-auto pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        let badgeTheme = 'purple'
        let badgeIcon = 'ri-draft-line'

        switch (original?.refereeType) {
          case 'Employment':
            badgeTheme = 'blue'
            badgeIcon = 'ri-community-line'
            break
          case 'Multiple Types':
            badgeTheme = 'green'
            badgeIcon = 'ri-parent-fill'
            break
          case 'Friends & Family':
            badgeTheme = 'light-yellow'
            badgeIcon = 'ri-account-pin-circle-line'
            break
          case 'Professional':
            badgeTheme = 'purple'
            badgeIcon = 'ri-briefcase-fill'
            break
          default:
            // Default theme and icon if refereeType does not match any case
            badgeTheme = 'purple'
            badgeIcon = 'ri-draft-line'
        }

        return (
          <div>
            <Badge
              size='large'
              isIcon={true}
              icon={badgeIcon}
              theme={badgeTheme}>
              {original?.refereeType}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'REFEREE COUNT',
      accessor: 'refereeCount',
      forSorting: 'refereeCount',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-auto pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.refereeCount}</div>
      },
    },
    {
      Header: 'LAST DOWNLOADED ON',
      accessor: 'lastDownloadedOn',
      forSorting: 'lastDownloadedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-auto pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const updatedDateString = timeToAESTWithSeconds(
          original?.lastDownloadedOn,
        )

        return <div>{updatedDateString}</div>
      },
    },
    {
      Header: 'CREATED BY',
      accessor: 'createdBy',
      forSorting: 'createdBy',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-auto pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.createdBy}</div>
      },
    },

    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[15px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <>
            <Button
              icon={<i className='ri-eye-line text-[#105DA4] text-heading-5' />}
              isIconOnly
              onClick={() => {
                setSummary(original ?? null)
                setShowDownlaodReportModal(true)
              }}
              className='w-8 h-8 mr-2 border-none'
              textTheme='red'
              theme='white'
            />
            <Button
              icon={
                <i className='ri-delete-bin-line text-Red-500 text-heading-5' />
              }
              isIconOnly
              disabled={!original?.id}
              onClick={() => {
                setSummary(original ?? null)
                setShowReportDeleteModal(true)
              }}
              className='w-8 h-8 border-none'
              textTheme='red'
              theme='white'
            />
          </>
        )
      },
    },
  ]

  return COLUMNS
}
