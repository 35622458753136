import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export enum CustomTemplateTypes {
  RefereeDetailRequest = 'RefereeDetailRequest',
  RefereeDetailRequestReminder = 'RefereeDetailRequestReminder',
  EmploymentWebReferenceCheck = 'EmploymentWebReferenceCheck',
  ProfessionalWebReferenceCheck = 'ProfessionalWebReferenceCheck',
  FriendsAndFamilyWebReferenceCheck = 'FriendsAndFamilyWebReferenceCheck',
  WebReferenceCheck = 'WebReferenceCheck',
  WebReferenceCheckReminder = 'WebReferenceCheckReminder',
  PoliceCheckRequest = 'PoliceCheckRequest',
  RightToWorkRequest = 'RightToWorkRequest',
  CandidateSurveyRequest = 'CandidateSurveyRequest',
  CandidateSurveyRequestReminder = 'CandidateSurveyRequestReminder',
}

export type CustomTemplateDto = {
  id: string
  name: string
  emailTemplate?: EmailTemplateDto
  smsTemplate?: SmsTemplateDto
}

export type EmailTemplateDto = {
  body: string
  postBody?: string
  disclaimer?: string
  isRemoveBranding: boolean
  isPhoneRefRequired: boolean
}

export type SmsTemplateDto = {
  body: string
  previewText: string
  isPhoneRefRequired: boolean
}

const getCustomTemplateByName = async ({ queryKey }: any) => {
  const { name } = queryKey[1]
  const res = await get(`/templates/custom?name=${name}`)
  return res.data.template
}

export const useGetCustomTemplateByName = (
  name: any,
  onSuccess: (data: CustomTemplateDto) => void,
) => {
  return useQuery(['custom_template', name], getCustomTemplateByName, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: 1,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching Template Data.',
        status: 'Error',
      })
    },
  })
}
