/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */

import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

export const useGetCSVList = () => {
  const mutate = useMutation((search: string) => {
    return get(`/Candidate/CSVExport`, {
      search: search,
    })
  })

  return mutate
}

export default useGetCSVList
