import refHubLogo from 'assets/images/refHubLogoText.svg'

import { Row } from 'components/atoms/Row'
import { cn } from 'utils'

type ReportFooterProps = {
  isFixed?: boolean
  pageNumber?: number
}

export const ReportFooter = ({
  isFixed = false,
  pageNumber,
}: ReportFooterProps) => {
  return (
    <div
      className={cn('left-0 right-0 bottom-0 h-[30px]', {
        'fixed ': isFixed,
        'absolute mt-2': !isFixed,
      })}>
      <div className='flex items-center justify-end px-6'>
        <span className='mr-2 text-Gray-400 font-Medium text-[10px]'>
          Powered by{' '}
        </span>{' '}
        <img src={refHubLogo} alt='' className='w-[43.75px] h-[8.75px]' />
        {/* <span className='text-Gray-400 font-Medium text-[10px]'>
          {pageNumber < 10 ? `0${pageNumber}` : pageNumber}
        </span> */}
      </div>
    </div>
  )
}
