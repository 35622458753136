import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, TextField, Toast } from '@labourhub/labour-hub-ds'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import {
  getPayAsYouGoPaymentAmountSummary,
  InvoiceDto,
  PaymentInvoiceModal,
} from 'features/invoices'
import { FixedSubscriptionPaymentInvoiceModal } from 'features/invoices/components/FixedSubscriptionPaymentInvoiceModal'
import { paymentData } from 'features/onboarding/data'
import {
  getOnboardingStore,
  setCurrentStep,
  setOnboardingPaymentBillingAddress,
} from 'features/onboarding/store'
import {
  defaultBillingAddress,
  getPaymentStore,
  PaymentErrorType,
} from 'features/payments'
import { getPromotionStore } from 'features/promotions'
import { SubscriptionPlanType, SubscriptionType } from 'features/subscriptions'

import { BillingAddressForm } from 'components/molecules'

type CheckoutFormProps = {
  clientSecret: string
  subscriptionPlanType: string
  subscriptionType: SubscriptionType
}

export const CheckoutForm = ({
  clientSecret,
  subscriptionPlanType,
  subscriptionType,
}: CheckoutFormProps) => {
  const stripe = useStripe()
  const dispatch = useDispatch()
  const elements = useElements()
  const notify = (props: any) => Toast(props)

  const { currentStep, paymentData: paymentInfo } =
    useSelector(getOnboardingStore)
  const amountSummary = useSelector(getPayAsYouGoPaymentAmountSummary)
  const { payAsYouGoCredits } = useSelector(getPaymentStore)
  const { promotion } = useSelector(getPromotionStore)

  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })

  const [nameOnCard, setNameOnCard] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isValidBillingAddress, setIsValidBillingAddress] = useState(false)
  const [isSaveMyCardChecked, setIsSaveMyCardChecked] = useState(true)

  const [invoice, setInvoice] = useState<InvoiceDto | null>(null)
  const [isInvoiceModalActive, setIsInvoiceModalActive] = useState(false)
  const [paymentError, setPaymentError] = useState<PaymentErrorType>(null)

  const [billingAddress, setBillingAddress] = useState(defaultBillingAddress)

  useEffect(() => {
    if (isValidBillingAddress)
      dispatch(setOnboardingPaymentBillingAddress(billingAddress))
  }, [billingAddress])

  const handleCardElementOnChange = (e: any) => {
    setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
  }

  const onNextStepClick = () => {
    dispatch(setCurrentStep(currentStep + 1))
  }

  const { mutate } = useUpdateOnboardingStepper()

  const handleSubmit = async () => {
    setInvoice(null)
    setIsLoading(true)
    if (!stripe || !elements || !clientSecret) {
      setPaymentError(null)
      setIsInvoiceModalActive(true)
      setIsLoading(false)
      return
    }

    if (
      !nameOnCard ||
      !cardComplete.cardNumber ||
      !cardComplete.cardExpiry ||
      !cardComplete.cardCvc
    ) {
      notify({
        alertHeader: 'Card details required',
        alertBody: '',
        status: 'Warning',
      })
      setIsLoading(false)
      return
    }

    if (!isValidBillingAddress) {
      notify({
        alertHeader: 'Billing address details required',
        alertBody: '',
        status: 'Warning',
      })
      setIsLoading(false)
      return
    }

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          name: nameOnCard,
          address: {
            line1: billingAddress.lineOne,
            line2: billingAddress.lineTwo,
            city: billingAddress.suburb,
            postal_code: billingAddress.postCode,
            state: billingAddress.state,
            country: billingAddress.countryCode,
          },
        },
      },
    })

    if (
      (result.error && !result.error?.setup_intent?.payment_method) ||
      (!result.error && !result.setupIntent.payment_method) ||
      (!result.error && result.setupIntent.status != 'succeeded')
    ) {
      setPaymentError({
        errorCode: result.error?.code,
        declinedCode: result.error?.decline_code,
      })
      setIsLoading(false)
      setIsInvoiceModalActive(true)
      return
    }

    const paymentCardId = result.setupIntent
      ? (result.setupIntent.payment_method as string)
      : (result.error?.setup_intent?.payment_method as string)

    if (paymentData && paymentCardId) {
      mutate(
        subscriptionPlanType == SubscriptionPlanType.PAYG
          ? {
              paymentData: {
                id: paymentData,
                onboardingPackageType: subscriptionPlanType,
                onboardingSubscriptionType: subscriptionType,
                cardDetails: {
                  paymentCardId,
                  isSaveMyCardChecked,
                  billingAddress,
                },

                payAsYouGoSubscription: {
                  promotionCode: promotion?.promotionCode,
                  credits: payAsYouGoCredits,
                },
              },
            }
          : {
              paymentData: {
                id: paymentData,
                onboardingPackageType: subscriptionPlanType,
                onboardingSubscriptionType: subscriptionType,
                cardDetails: {
                  paymentCardId,
                  isSaveMyCardChecked,
                  billingAddress,
                },
                fixedSubscriptionPackageId:
                  paymentInfo?.fixedSubscriptionPackageId,
              },
            },
        {
          onSuccess: ({ data }: any) => {
            setInvoice(data.invoice)
          },
          onError: () => {
            setPaymentError(null)
            setInvoice(null)
          },
          onSettled: () => {
            setIsLoading(false)
            setIsInvoiceModalActive(true)
          },
        },
      )
    }
  }

  return (
    <>
      <div className='flex flex-col w-full p-6 mt-6 border rounded-lg border-Gray-200'>
        <div className='pb-4 text-base font-SemiBold'>Card Details</div>

        <div className='pb-1 text-small'>
          <div className='flex text-Gray-800 font-Medium'>
            <span>Card Number</span>
            {<span className='text-Red-500 pl-[2px]'>*</span>}
          </div>
        </div>

        <CardNumberElement
          onChange={handleCardElementOnChange}
          options={{
            showIcon: true,
            classes: {
              base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400',
              focus: 'ring-1 ring-Cobalt-400',
            },
          }}
        />

        <div className='flex items-center justify-around w-full pt-[21.5px] space-x-5 pb-4'>
          <div className='flex-col w-full'>
            <div className='flex mb-1 text-Gray-800 font-Medium'>
              <span>Expiry</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardExpiryElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 w-full bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
          <div className='flex-col w-full'>
            <div className='flex mb-1 text-Gray-800 font-Medium'>
              <span>CVC</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardCvcElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400 w-[200px] bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
        </div>

        <TextField
          className='mb-4'
          value={null}
          isRequired
          onChange={(e: any) => setNameOnCard(e.target.value)}
          label='Name on the card'
          placeholder=''
        />
        <Checkbox
          labelText='Save my card for future payments'
          defaultChecked={isSaveMyCardChecked}
          onChange={(e: any) => setIsSaveMyCardChecked(e.target.checked)}
        />
      </div>

      <BillingAddressForm
        setIsValidBillingAddress={setIsValidBillingAddress}
        billingAddress={billingAddress}
        setBillingAddress={setBillingAddress}
      />

      <div className='flex w-full gap-x-[30px]'>
        <Button
          isBlock
          onClick={() => {
            onNextStepClick()
            setBillingAddress(defaultBillingAddress)
            dispatch(setOnboardingPaymentBillingAddress(defaultBillingAddress))
          }}
          className='mt-6 next-btn'
          textTheme='black'
          theme='white'>
          Skip
        </Button>

        <Button
          isBlock
          onClick={handleSubmit}
          isDisabled={
            !nameOnCard ||
            !cardComplete.cardNumber ||
            !cardComplete.cardExpiry ||
            !cardComplete.cardCvc ||
            !isValidBillingAddress ||
            (subscriptionPlanType == SubscriptionPlanType.PAYG &&
              amountSummary.totalAmount == 0)
          }
          isLoading={isLoading}
          className='mt-6 next-btn'>
          Pay
        </Button>
      </div>

      {subscriptionPlanType == SubscriptionPlanType.PAYG ? (
        <PaymentInvoiceModal
          isActive={isInvoiceModalActive}
          setIsActive={setIsInvoiceModalActive}
          invoice={invoice}
          cardBillingAddress={billingAddress}
          invoiceDownloadMode={true}
          isSuccess={invoice != null}
          paymentError={paymentError}
          onCloseAction={invoice != null ? () => onNextStepClick() : undefined}
        />
      ) : (
        <FixedSubscriptionPaymentInvoiceModal
          isActive={isInvoiceModalActive}
          setIsActive={setIsInvoiceModalActive}
          invoice={invoice}
          cardBillingAddress={billingAddress}
          isSuccess={invoice != null}
          paymentError={paymentError}
          onCloseAction={invoice != null ? () => onNextStepClick() : undefined}
        />
      )}
    </>
  )
}
