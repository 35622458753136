import React from 'react'
import CN from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
}

export const Card = ({ children, className }: Props) => {
  return (
    <div
      className={CN(
        'flex flex-col w-full h-full p-5 bg-white border rounded-md border-Gray-200',
        className,
      )}>
      {children}
    </div>
  )
}
