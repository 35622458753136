import { useState } from 'react'
import { Avatar, Button, Modal } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  CandidateProfileBasicDetailsType,
  CandidateReferee,
  useDeleteCandidateReferee,
} from 'framework/api/methods'
import { ReferenceCheckType } from 'types'

import {
  MoreMenuButton,
  PageLoader,
  ReferenceTypeLabel,
} from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Row, RowWithAlignment } from 'components/atoms/Row'
import {
  CandidateRefereeStatusCard,
  CandidateReferenceCheckSnack,
  PhoneRefCheck,
  SendWebRefCheckReminderModal,
} from 'components/molecules'
import { timeGap } from 'utils/timeGap'

type CandidateRefereeCardProps = {
  candidate: CandidateProfileBasicDetailsType
  getCandidateRefereesList: () => void
  onCallRecordingsClick?: () => void | undefined
  onClickWebRefCheck?: () => void
  onEditClick?: () => void | undefined
  onViewReportClick: () => void
  referee: CandidateReferee
  refereeIndex: number
  setShowDownlaodReferenceReportModal: any
  [x: string]: any
}

export const CandidateRefereeCard = ({
  candidate,
  className,
  getCandidateRefereesList,
  onCallRecordingsClick,
  onClickWebRefCheck,
  onEditClick,
  refereeCount,
  onViewReportClick,
  referee,
  refereeIndex,
  setSelectedRefereeDetails,
  getCandidateById,
  setShowDownlaodReferenceReportModal,
}: CandidateRefereeCardProps) => {
  const RefereeCardClasses = CN(
    `flex flex-col bg-white border border-Gray-200 rounded-lg p-5 pt-4 w-full`,
    className,
  )

  const [isOpenPhoneRefCheck, setIsOpenPhoneRefCheck] = useState(false)
  const [isOpenRefereeDelete, setIsOpenRefereeDelete] = useState(false)
  const [
    isSendWebRefCheckReminderModalOpen,
    setIsSendWebRefCheckReminderModalOpen,
  ] = useState(false)

  const {
    mutateAsync: deleteCandidateReferee,
    isLoading: isLoadingDeleteCandidateReferee,
  } = useDeleteCandidateReferee(candidate.candidateId, referee.id, refereeIndex)

  return (
    <div className={RefereeCardClasses} key={refereeIndex}>
      {isLoadingDeleteCandidateReferee && <PageLoader size='xxs' />}
      <RowWithAlignment className='box-border mb-4'>
        <div className='justify-start w-1/2'>
          <Column className='gap-y-1'>
            <div className='flex items-center'>
              <span className='mr-2 text-heading-4 text-Gray-800 font-Bold'>
                Referee {refereeIndex.toString().padStart(2, '0')}
              </span>
              <CandidateReferenceCheckSnack status={referee.status} />
            </div>
            {referee?.referenceType?.type && (
              <div className='flex mt-1'>
                <span className='mr-1 text-small'> Reference type:</span>
                <ReferenceTypeLabel type={referee?.referenceType?.type} />
              </div>
            )}
          </Column>
        </div>
        {/* Ref Check types */}
        <Row className='justify-end w-1/2'>
          <Button
            size='sm'
            textTheme='blue'
            theme='white'
            isDisabled={!referee?.refereeResponse}
            className='mx-2'
            iconLeft={
              <i className='flex items-center ri-eye-line text-small' />
            }
            onClick={() => {
              setSelectedRefereeDetails(referee)
              onViewReportClick()
            }}>
            <span className='text-small font-Medium'>Preview Report</span>
          </Button>
          <Button
            textTheme='blue'
            size='sm'
            theme='white'
            isDisabled={referee?.state !== 'NOT_SENT'}
            className='mr-2'
            iconLeft={
              <i className='flex items-center ri-global-line text-small' />
            }
            onClick={() => {
              onClickWebRefCheck && onClickWebRefCheck()
            }}>
            <span className='text-small font-Medium'>Web Ref Check</span>
          </Button>
          <Button
            textTheme='blue'
            size='sm'
            theme='white'
            isDisabled={
              referee?.state == 'REQUEST_DECLINED' ||
              referee?.state == 'RESPONSE_RECEVIED'
            }
            iconLeft={<i className='ri-phone-line text-small' />}
            onClick={() => {
              setIsOpenPhoneRefCheck(true)
            }}>
            <span className='text-small font-Medium'>Phone Ref Check</span>
          </Button>
          <div className='h-[38px] w-[38px]'>
            <MoreMenuButton
              className='ml-2'
              position={refereeIndex !== refereeCount ? 'bottom' : 'left'}
              actionButtons={[
                {
                  iconClass: 'ri-pencil-line',
                  onClick: () => {
                    onEditClick && onEditClick()
                  },
                  title: 'Edit Referee',
                  status: 'normal',
                },
                {
                  iconClass: 'ri-notification-4-line',
                  onClick: () => {
                    setIsSendWebRefCheckReminderModalOpen(true)
                  },
                  title: 'Send Web Ref Check Reminder',
                  status: 'normal',
                  disabled: referee?.state !== 'AWAITING_RESPONSE',
                },
                {
                  iconClass: 'ri-record-mail-line',
                  onClick: () => {
                    onCallRecordingsClick && onCallRecordingsClick()
                  },
                  title: 'Call Recordings',
                  status: 'normal',
                },
                {
                  iconClass: 'ri-delete-bin-line',
                  onClick: () => {
                    setIsOpenRefereeDelete(true)
                  },
                  title: 'Delete',
                  status: 'danger',
                },
              ]}
              onClickMoreButton={() => {
                referee && setSelectedRefereeDetails(referee)
              }}
            />
          </div>
        </Row>
      </RowWithAlignment>

      <div className='flex items-start justify-between'>
        <div className='relative flex-none mr-4'>
          <Avatar
            isHeader={false}
            title={referee.firstName + ' ' + referee.lastName}
            color={'four'}
          />
        </div>
        <div className='flex flex-col w-full'>
          {/* Name with form type status */}
          <div className='flex flex-col'>
            <div className='flex items-center'>
              <span className='mr-2 text-heading-5 text-Gray-800 font-Medium'>
                {referee.firstName + ' ' + referee.lastName}
              </span>

              {/* Status either web or phone ref check sent */}
              {referee.refereeResponse?.responseType === 'PHONE_CHECK' && (
                <span className='flex items-center bg-Gray-100 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                  <i className='flex items-center ri-phone-line text-extra-small text-Gray-500' />
                  <span className='text-extra-small font-Medium text-Gray-800'>
                    Phone
                  </span>
                </span>
              )}
              {referee.refereeResponse?.responseType === 'WEB_CHECK' && (
                <span className='flex items-center bg-Gray-100 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                  <i className='flex items-center ri-global-line text-extra-small text-Gray-500' />
                  <span className='text-extra-small font-Medium text-Gray-800'>
                    Web Form
                  </span>
                </span>
              )}
            </div>
          </div>
          <div className='grid grid-cols-2 gap-2 pr-4 mt-2'>
            <>
              <div className='flex items-start'>
                <i className='flex items-center mr-2 ri-phone-line text-small text-Gray-500' />
                <a
                  href={`tel:${referee?.phone}`}
                  className='break-words text-small font-Medium text-Gray-600'>
                  {referee.phone}
                </a>
              </div>
              {referee?.referenceType?.type == ReferenceCheckType.Employment ? (
                <div className='flex items-start'>
                  <span className='text-small min-w-[100px] lg:min-w-[180px] text-Gray-500 mr-[10px] font-Regular'>
                    Relationship to candidate:
                  </span>
                  <span className='break-words text-small font-Medium text-Gray-600'>
                    {referee.candidateRelation
                      ? referee.candidateRelation
                      : 'NA'}
                  </span>
                </div>
              ) : referee?.referenceType?.type ==
                ReferenceCheckType.Professional ? (
                <div className='flex items-start'>
                  <span className='flex text-small min-w-[100px] lg:min-w-[180px] text-Gray-500 mr-[10px] font-Regular'>
                    <i className='items-center mr-2 ri-building-4-line text-small text-Gray-500' />
                    Referee Company:
                  </span>
                  <span className='break-words text-small font-Medium text-Gray-600 '>
                    {referee.company ? referee.company : 'NA'}
                  </span>
                </div>
              ) : (
                <div />
              )}
            </>

            <>
              <div className='flex items-start'>
                <i className='flex items-center mr-2 ri-mail-line text-small text-Gray-500' />
                {!referee?.email.includes('@noreply.com') ? (
                  <a
                    href={`mailto:${referee?.email}`}
                    className='break-all text-small font-Medium text-Gray-600'>
                    {referee?.email}
                  </a>
                ) : (
                  <span className='text-small font-SemiBold'>N/A</span>
                )}
              </div>
              {referee?.referenceType?.type == ReferenceCheckType.Employment ? (
                <div className='flex items-start'>
                  <span className='text-small min-w-[100px] lg:min-w-[180px] text-Gray-500 mr-[10px] font-Regular'>
                    Candidate job position:
                  </span>
                  <span className='break-words text-small font-Medium text-Gray-600'>
                    {referee.candidatePosition
                      ? referee.candidatePosition
                      : 'NA'}
                  </span>
                </div>
              ) : referee?.referenceType?.type ==
                ReferenceCheckType.Professional ? (
                <div className='flex items-start'>
                  <span className='flex text-small min-w-[100px] lg:min-w-[180px] text-Gray-500 mr-[10px] font-Regular'>
                    <i className='items-center mr-2 ri-suitcase-line text-small text-Gray-500' />
                    Referee Position:
                  </span>
                  <span className='break-words text-small font-Medium text-Gray-600 '>
                    {referee.jobTitle ? referee.jobTitle : 'NA'}
                  </span>
                </div>
              ) : (
                <div />
              )}
            </>

            <>
              {referee?.referenceType?.type ==
                ReferenceCheckType.Employment && (
                <div className='flex items-start'>
                  <i className='flex items-center mr-2 ri-suitcase-line text-small text-Gray-500' />
                  <span className='break-words text-small font-Medium text-Gray-600 '>
                    {referee.jobTitle} at {referee.company}
                  </span>
                </div>
              )}
              {referee?.referenceType?.type ==
                ReferenceCheckType.Employment && (
                <div className='flex items-start'>
                  <span className='text-small  w-[100px] lg:w-[180px] text-Gray-500 mr-[10px] font-Regular'>
                    Work Period:
                  </span>
                  <span className='text-small font-Medium text-Gray-600'>
                    {referee.isCurrentlyWorking
                      ? 'Currently working'
                      : referee.endDate && referee.startDate
                      ? timeGap(referee.endDate, referee.startDate)
                      : 'Not Available'}
                  </span>
                </div>
              )}
            </>
          </div>

          {referee?.referenceType?.type != ReferenceCheckType.Employment && (
            <div className='flex items-start mt-2'>
              <span className='mr-1 text-Gray-500'>
                Relationship to candidate:
              </span>
              <span className='break-words text-small font-Medium text-Gray-600'>
                {referee.candidateRelation}
              </span>
            </div>
          )}
        </div>
        <div className='flex-auto items-end min-w-[246px]'>
          <CandidateRefereeStatusCard
            onClickViewReport={() => {
              setSelectedRefereeDetails(referee)
              setShowDownlaodReferenceReportModal(true)
            }}
            status={referee.status}
            state={referee.state}
            sentDate={referee?.requestSendDate}
            updatedDate={
              referee.status === 'Failed'
                ? referee.updatedOn
                : referee?.refereeResponse?.updatedOn
            }
          />
        </div>
      </div>

      {/* Phone Ref Check */}
      <PhoneRefCheck
        referee={referee}
        candidate={candidate}
        isOpen={isOpenPhoneRefCheck}
        hideModal={() => setIsOpenPhoneRefCheck(false)}
        getCandidateRefereesList={getCandidateRefereesList}
        refreshCandidateProfileData={getCandidateById}
      />

      <SendWebRefCheckReminderModal
        referee={referee}
        refereeIndex={refereeIndex}
        isActive={isSendWebRefCheckReminderModalOpen}
        setIsActive={setIsSendWebRefCheckReminderModalOpen}
        getCandidateRefereesList={getCandidateRefereesList}
      />

      {/* Delete Referee modal */}
      <Modal
        isActive={isOpenRefereeDelete}
        onClickPrimaryBtn={() => {
          deleteCandidateReferee().finally(() => {
            setIsOpenRefereeDelete(false)
            getCandidateRefereesList()
            getCandidateById()
          })
        }}
        onClickSecondaryBtn={() => {
          setIsOpenRefereeDelete(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          // isLoading: deleteClientContactAvatarIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsOpenRefereeDelete(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Delete Referee?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to delete referee?
          </span>
        </div>
      </Modal>
    </div>
  )
}
