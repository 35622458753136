/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button } from '@labourhub/labour-hub-ds'

import { Modal } from 'components/molecules'

type HelpCenterModalProps = {
  isActive: boolean
  setIsActive: (value: boolean) => void
}

export const HelpCenterModal = ({
  isActive,
  setIsActive,
}: HelpCenterModalProps) => {
  return (
    <Modal
      headerTitle=''
      isFooterShow={false}
      size='lg'
      customWidth='100vw'
      isActive={isActive}
      isHeaderShow={false}
      onHeaderCloseButtonClick={() => {
        setIsActive(false)
      }}
      onOverlayClick={() => {
        setIsActive(false)
      }}>
      <div className='flex justify-between items-center w-full p-2'>
        <Button
          iconLeft={<i className='ri-arrow-right-up-fill text-[16px]' />}
          onClick={() => {
            window.open('https://www.craft.me/s/1E9yDjlzX5Ax2A', '_blank')
            setIsActive(false)
          }}
          textTheme='black'
          size='sm'
          theme='white'>
          Open in web browser
        </Button>

        <Button
          size='sm'
          icon={<i className='ri-close-line text-heading-5' />}
          textTheme='black'
          theme='link'
          isIconOnly
          onClick={() => {
            setIsActive(false)
          }}
        />
      </div>
      <div className='flex h-[100vh] justify-center items-center'>
        <iframe
          src='https://www.craft.me/s/1E9yDjlzX5Ax2A'
          className='w-[100vw]'
          height='100%'
          title='refhub-help-center'></iframe>
      </div>
    </Modal>
  )
}
