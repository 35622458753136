import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {
  Button,
  Checkbox,
  Modal,
  SimpleSelect,
  ToggleDetailSection,
} from '@labourhub/labour-hub-ds'
import {
  QuestionnaireCategoryType,
  useGetQuestionnaireTemplateList,
} from 'api/questionnaires'
import popoverCareerForm from 'assets/images/popover_career_form.svg'
import popoverStaffForm from 'assets/images/popover_staff_form.svg'
import popoverContent from 'assets/images/popoverContent.svg'
import CN from 'classnames'
import {
  AppSettingKey,
  getAppSettings,
  getSettingValue,
  isDefaultSetting,
  isSettingEnabled,
} from 'features/settings'
import { useCreateWebRefEmail } from 'framework/api/methods'
import { ReferenceCheckType } from 'types'

import { PageLoader, TextEditor } from 'components/atoms'

import { WebRefCheckPreviewModal } from '../WebRefCheckPreviewModal'

export type CandidateReferee = {
  candidateCompany: string
  candidateId: string
  candidatePosition: string
  candidateRelation: string
  company: string
  createdOn: string
  email: string
  endDate: string
  firstName: string
  id: string
  jobTitle: string
  lastName: string
  phone: string
  responseDate: string | null
  sendDate: string | null
  startDate: 'string'
  state: 'string' | null
  status: null | 'Failed' | 'Incomplete' | 'Passed'
  templateId: string | null
  tenantId: string
  updatedOn: string
  formType: 'Web Ref Check' | 'Phone Ref Check'
}

export type WebRefCheckProps = {
  referee?: CandidateReferee[]
  candidateId?: string
  isOpen?: boolean
  [x: string]: any
}

export const WebRefCheck = ({
  className,
  isOpen,
  setIsWebRefCheckModal,
  candidateId,
  notify,
  selectedRefereeId,
  selectAll,
  refereeList,
  getCandidateRefereesList,
  ...restProps
}: WebRefCheckProps) => {
  const navigate = useNavigate()
  const { agencyAppSettings, candidateAppSettings } =
    useSelector(getAppSettings)

  const [referees, setReferees] = useState<Array<any>>(refereeList)
  const [selectedId, setSelectedId] = useState<any>(selectedRefereeId)
  const [blankTemplateContent, setBlankTemplateContent] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState<any>()

  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)
  const [isCareerInterest, setIsCareerInterest] = useState(false)
  const [isStaffRequest, setIsStaffRequest] = useState(false)

  const [isPrimaryBtnDisabled, setIsPrimaryBtnDisabled] = useState(false)
  const [
    isShowNewTemplateNavigationConfirmationModal,
    setIsShowNewTemplateNavigationConfirmationModal,
  ] = useState(false)

  /** set Referee List */
  useEffect(() => {
    setReferees(refereeList)
  }, [refereeList])

  /** check box states */
  const [checkedState, setCheckedState] = useState(
    selectAll
      ? new Array(refereeList?.length).fill(true)
      : new Array(refereeList?.length).fill(undefined),
  )

  /** handle selected referee ID*/
  useEffect(() => {
    !selectAll && setSelectedId(selectedRefereeId)

    selectAll && setCheckedState(new Array(referees?.length).fill(true))
  }, [isOpen, selectAll, selectedRefereeId])

  /** handle select all or already selected referee */
  useEffect(() => {
    if (selectAll) {
      // setCheckedState(new Array(referees?.length).fill(true))

      const filteredRefIds = referees.reduce((filteredRefIds, item) => {
        if (item.state === 'NOT_SENT') {
          filteredRefIds.push(true)
        } else {
          filteredRefIds.push(false)
        }
        return filteredRefIds
      }, [])

      setCheckedState(filteredRefIds)
    }

    if (!selectAll && selectedId) {
      const updatedCheckedState = referees.map((item) =>
        selectedId === item.id ? true : false,
      )
      setCheckedState(updatedCheckedState)
    }

    setSelectedId('')
  }, [selectedId, selectAll])

  /** handle on change list update */
  useEffect(() => {
    if (selectedId === '') {
      const updatedArray = referees?.map((item, idx) => {
        return { ...item, checked: checkedState[idx] }
      })

      setReferees(updatedArray)
    }
  }, [checkedState])

  // Fetching Questionnaire list
  const {
    data: questionnaireTemplates,
    isLoading: getQuestionnairesListIsLoading,
  } = useGetQuestionnaireTemplateList()

  useEffect(() => {
    const leadGenCareerInterestEnabled = isSettingEnabled(
      AppSettingKey.LeadGenCareerInterest,
      agencyAppSettings,
    )

    const leadGenStaffRequestEnabled = isSettingEnabled(
      AppSettingKey.LeadGenStaffRequest,
      agencyAppSettings,
    )

    setIsCareerInterest(leadGenCareerInterestEnabled)
    setIsStaffRequest(leadGenStaffRequestEnabled)
  }, [isOpen, agencyAppSettings, selectedRefereeId])

  useEffect(() => {
    let questionId: string

    const isAgengcyQuesTemIdBlank = isDefaultSetting(
      AppSettingKey.QuestionnaireTemplateId,
      agencyAppSettings,
    )

    const isCandidateQuesTemIdBlank = isDefaultSetting(
      AppSettingKey.QuestionnaireTemplateId,
      candidateAppSettings,
    )

    if (isAgengcyQuesTemIdBlank && !isCandidateQuesTemIdBlank) {
      questionId = getSettingValue(
        AppSettingKey.QuestionnaireTemplateId,
        candidateAppSettings,
      )
    } else if (!isAgengcyQuesTemIdBlank && isCandidateQuesTemIdBlank) {
      questionId = getSettingValue(
        AppSettingKey.QuestionnaireTemplateId,
        agencyAppSettings,
      )
    } else {
      questionId = 'Blank'
    }

    const template = questionnaireTemplates?.find((q) => q.id === questionId)

    if (template) {
      setSelectedTemplate({
        value: template.id,
        label: template.name,
      })
    } else {
      setSelectedTemplate('')
    }
  }, [isOpen, agencyAppSettings, candidateAppSettings, selectedRefereeId])

  /** APi call for the create web ref email */
  const {
    mutate: createWebRefEmailMutate,
    isLoading: createWebRefEmailIsLoading,
  } = useCreateWebRefEmail()

  /** Process the add new candidate */
  const createWebRefEmail = (refereeIdsList: string[]) => {
    createWebRefEmailMutate(
      {
        candidateId,
        customMessage: blankTemplateContent,
        refereeIds: refereeIdsList,
        templateId: selectedTemplate?.value,
        isStaffRequest,
        isCareerInterest,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          setIsWebRefCheckModal({
            selectedRefereeId: '',
            selectAll: false,
            enable: false,
          })

          setSelectedTemplate('')
          setBlankTemplateContent('')
          getCandidateRefereesList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }
  /** handle email ref check form btn */
  const handleEmailRefCheckFormBtn = () => {
    const selectedRefList = handleFilterSelectedRefIds()
    createWebRefEmail(selectedRefList)
  }

  /** handle selected referee list only */
  const handleFilterSelectedRefIds = () => {
    const refIds = referees.reduce((refIds, item) => {
      if (item.checked) {
        refIds.push(item.id)
      }
      return refIds
    }, [])

    return refIds
  }

  const WebRefCheckClasses = CN(`web-ref-check`, className)

  /** handle primary button disable */
  useEffect(() => {
    const selectedRefList = handleFilterSelectedRefIds()

    selectedRefList?.length > 0 && selectedTemplate
      ? setIsPrimaryBtnDisabled(false)
      : setIsPrimaryBtnDisabled(true)
  }, [referees, checkedState, selectedTemplate])

  return (
    <div className={WebRefCheckClasses} {...restProps}>
      {getQuestionnairesListIsLoading && <PageLoader size='xxs' />}

      <Modal
        headerTitle='Send Ref Check'
        customWidth='65%'
        isActive={isOpen}
        primaryButtonTheme={'cobalt'}
        primaryButtonTitle='Send Ref Check Form'
        onClickSecondaryBtn={() => {
          setIsWebRefCheckModal({
            selectedRefereeId: '',
            selectAll: false,
            enable: false,
          })
          setSelectedTemplate('')
        }}
        footerProps={{
          style: {
            justifyContent: 'end',
          },
        }}
        primaryButtonProps={{
          isDisabled: isPrimaryBtnDisabled,
          isLoading: createWebRefEmailIsLoading,
        }}
        onClickPrimaryBtn={() => {
          handleEmailRefCheckFormBtn()
        }}
        onHeaderCloseButtonClick={() => {
          setIsWebRefCheckModal({
            selectedRefereeId: '',
            selectAll: false,
            enable: false,
          })
          setSelectedTemplate('')
        }}
        secondaryButtonTitle='Close'>
        <div className='flex w-full px-8 pt-7 pb-10 gap-x-5 min-h-[630px]'>
          <div className='flex flex-col w-1/3 left-container'>
            <span className='pb-4 text-Gray-800 font-SemiBold'>Recipients</span>
            <div className='recipients-card-container flex flex-col gap-y-2 max-h-[330px] overflow-y-auto styled-scroll'>
              {referees?.map((item, idx) => (
                <div
                  className={CN(
                    'recipients-card rounded-lg py-2 px-3 relative',
                    {
                      'bg-white border border-Gray-100': !item.checked,
                      'bg-Cobalt-50 border border-Cobalt-100': item.checked,
                    },
                  )}
                  key={idx}>
                  <Checkbox
                    key={idx}
                    id={`custom-checkbox-${idx}`}
                    checked={item?.checked || false}
                    isDisabled={item.state !== 'NOT_SENT'}
                    onChange={() => {
                      const updatedCheckedState = checkedState.map(
                        (item, index) => (index === idx ? !item : item),
                      )

                      setCheckedState(updatedCheckedState)
                    }}
                    labelText={`${item?.firstName} ${item?.lastName}`}
                    labelTextClassName='font-Regular line-clamp-1'
                    checkboxClassName='ml-0'
                  />

                  <div className='flex flex-col ml-7 text-extra-small font-Regular text-Gray-500'>
                    <span className='leading-[18px] line-clamp-1 break-all'>
                      {item?.email}
                    </span>

                    {item?.referenceType?.type !=
                      ReferenceCheckType.FriendFamily && (
                      <span className='break-all line-clamp-1'>
                        {item?.company}
                      </span>
                    )}
                  </div>

                  {item.state !== 'NOT_SENT' && (
                    <div className='absolute top-0 left-0 right-0 z-20 flex w-full h-full bg-Gray-100 opacity-30'></div>
                  )}
                </div>
              ))}
            </div>

            <span className='mt-8 text-Gray-800 font-SemiBold'>
              Questionnaire
            </span>

            <SimpleSelect
              className='mt-4 template-selector'
              label='Choose Template'
              isRequired
              maxMenuHeight={150}
              options={[
                ...(questionnaireTemplates?.map((template) => ({
                  value: template.id,
                  label: template.name,
                })) || []),
              ]}
              value={
                selectedTemplate
                  ? {
                      label: selectedTemplate?.label,
                      value: selectedTemplate?.value,
                    }
                  : null
              }
              onChange={(selectedItem: any) => {
                setSelectedTemplate({
                  label: selectedItem?.label,
                  value: selectedItem?.value,
                })
              }}
            />

            <div className='flex items-center py-2'>
              <hr className='flex-auto text-Gray-300' />

              <span className='mx-2 text-small font-Medium text-Gray-400'>
                OR
              </span>

              <hr className='flex-auto text-Gray-300' />
            </div>

            <Button
              textTheme={'blue'}
              theme='white'
              className='create-new-template-btn'
              onClick={() => {
                setIsShowNewTemplateNavigationConfirmationModal(true)
              }}>
              Create New Template
            </Button>
          </div>

          <div className='flex flex-col w-2/3 right-container'>
            <span className='text-Gray-800 font-SemiBold'>Preview</span>

            <Button
              textTheme={'blue'}
              theme='white'
              onClick={() => {
                setIsShowPreviewModal(true)
              }}
              isDisabled={!selectedTemplate?.value}
              className='mt-4 pre-que-temp-btn w-fit'>
              Preview Questionnaire Template
            </Button>

            <span className='mt-8 text-Gray-800 font-SemiBold'>
              Lead Generation
            </span>

            <ToggleDetailSection
              id='staff-request-form'
              label='Attach staff request form'
              description='This will be in the thank you screen for the referee'
              hasTooltip={true}
              tooltipImage={popoverStaffForm}
              value={isStaffRequest}
              onClick={(e: any) => setIsStaffRequest(e.target.checked)}
            />

            <ToggleDetailSection
              id='career-interest-form'
              label='Attach career interest form'
              description='This will be in the thank you screen for the referee'
              hasTooltip={true}
              tooltipImage={popoverCareerForm}
              value={isCareerInterest}
              onClick={(e: any) => setIsCareerInterest(e.target.checked)}
            />

            <div className='flex items-center justify-start mt-8'>
              <span className='text-Gray-800 font-SemiBold'>
                Custom Message
              </span>

              <i
                className='ri-error-warning-line text-Gray-500 ml-2 text-[14px] relative'
                data-tip='custom-message'
                data-for='custom-message'
              />

              <ReactTooltip
                place='right'
                className='absolute w-auto'
                id='custom-message'
                effect='solid'
                backgroundColor='rgb(255,255,255,0)'>
                <img
                  src={popoverContent}
                  alt=''
                  className='relative flex w-full object-contain h-[550px] top-[100px] flex-auto shadow-xl shadow-Gray-300 border border-Gray-200 rounded-sm'
                />
              </ReactTooltip>
            </div>

            <span className='mt-1 text-extra-small text-Gray-500'>
              You can add an optional custom message to the recipient along with
              the email request.
            </span>

            <div className='editor-container flex mt-5 w-full h-[180px] '>
              <TextEditor
                onContentChange={(content) => {
                  setBlankTemplateContent(content)
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/** Create new template confirmation modal */}
      <Modal
        isActive={isShowNewTemplateNavigationConfirmationModal}
        onClickPrimaryBtn={() => {
          navigate(
            `/questionnaires/new?buildMode=${QuestionnaireCategoryType.ReferenceCheck}`,
          )
        }}
        onClickSecondaryBtn={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Yellow-50'>
            <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Warning
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Please note you will be directed to the create questionnaire page
            and the content in this page will not be saved.
          </span>
        </div>
      </Modal>

      {isShowPreviewModal && (
        <WebRefCheckPreviewModal
          isActive={isShowPreviewModal}
          modalClose={() => {
            setIsShowPreviewModal(false)
          }}
          templateId={selectedTemplate?.value}
          templateName={selectedTemplate?.label}
          notify={notify}
        />
      )}
    </div>
  )
}
