import React, { ForwardedRef, forwardRef, Fragment } from 'react'
import JSONForm from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'

import { Rate } from 'components/atoms'

import './theme.scss'

export type FormBuilderProps = {
  schema: any
  formData?: any
  uiSchema: any
  buttonRef?: React.MutableRefObject<JSONForm<any, any> | null>
  onChange?: (event: any) => void
  onSubmit?: (event: any) => void
  onError?: (event: any) => void
  onFocus?: (event: any) => void
  readonly?: boolean
  [x: string]: any
}
export const FormBuilder = forwardRef(
  (
    {
      schema,
      formData,
      uiSchema,
      onChange,
      onSubmit,
      onFocus,
      onError,
      readonly,
    }: FormBuilderProps,
    ref: ForwardedRef<JSONForm<any, any>>,
  ) => {
    const Rating = (props: any) => {
      return (
        <Rate
          readonly={readonly}
          count={props.schema.enum.length}
          rating={props.value ? props.value : undefined}
          onRating={(e) => {
            props.onChange(e.toString())
          }}
        />
      )
    }

    const widgets = {
      rating: Rating,
    }

    const changeSchema = (schema: any) => {
      const properties = { ...schema.properties }
      let tempIndex = 0
      Object.keys(properties).map((key: string) => {
        const property = properties[key]
        if (property?.type !== 'object') {
          properties[key] = {
            ...property,
            title: `${
              tempIndex < 9 ? `0${tempIndex + 1}.` : `${tempIndex + 1}.`
            } ${property.title}`,
          }
          tempIndex++
        }
      })

      return {
        ...schema,
        properties,
      }
    }

    return (
      <JSONForm
        showErrorList={false}
        widgets={widgets}
        className='jason-theme'
        schema={changeSchema(schema)}
        formData={formData}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        onChange={onChange}
        onFocus={onFocus}
        onError={onError}
        validator={validator}
        ref={ref}
        readonly={readonly}>
        <Fragment />
      </JSONForm>
    )
  },
)
