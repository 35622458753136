/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import { PieChart, PieChartProps } from 'react-minimal-pie-chart'
import ReactTooltip from 'react-tooltip'
import CN from 'classnames'

export type DashboardReportCardProps = {
  [x: string]: any
  index: number
  isDetailedCard?: boolean
  toolTipDescription: string | undefined
  title: string | undefined
  AverageRefCheckTime?: number | undefined
  fraudDetection?: number | undefined
  isFullCircle?: boolean
  data?: any
  totalCount?: number | undefined
}

export const DashboardReportCard: FC<DashboardReportCardProps> = ({
  className,
  index,
  isDetailedCard,
  isFullCircle,
  title,
  toolTipDescription,
  AverageRefCheckTime,
  fraudDetection,
  data,
  totalCount,
  dashboardDetailModal,
  setDashboardDetailModal,
  ...restProps
}: DashboardReportCardProps) => {
  const DashboardReportCardClasses = CN(
    `dashboard-report-card flex flex-col w-full h-full bg-white w-full border border-Gray-200 rounded-lg p-6`,
    className,
  )

  const [isSeparator, setIsSeparator] = useState(true)
  const [isAllEmpty, setIsAllEmpty] = useState(false)

  type BaseData = PieChartProps['data'][number]

  function makeTooltipContent(
    entry: BaseData & {
      tooltip: BaseData['title']
    },
  ) {
    return `${entry.title} - ${entry.value}`
  }

  useEffect(() => {
    const tempDataObject = data.filter((element: any) => element.value > 0)

    /** for check object only one values is more than 0 and other are equals 0 */
    tempDataObject?.length == 1 ? setIsSeparator(false) : setIsSeparator(true)

    /** for check object all values are 0 */
    tempDataObject?.length == 0 ? setIsAllEmpty(false) : setIsAllEmpty(true)
  }, [data])

  const [hovered, setHovered] = useState<number | null>(null)

  return (
    <div className={DashboardReportCardClasses} {...restProps}>
      <div
        className={CN('top-section flex', {
          'justify-between items-center h-full': !isDetailedCard,
          'justify-start items-start h-1/5': isDetailedCard,
        })}>
        <div className='flex w-full'>
          <span className='text-heading-5 text-Gray-800 font-Medium'>
            {title}
          </span>

          <span
            className='flex h-[30px] w-[30px] justify-center items-center hover:bg-Cobalt-50 hover:shadow-sm ml-1'
            data-tip={toolTipDescription}
            data-for={index}>
            <i className='ri-error-warning-line text-[18px] text-Cobalt-600' />
          </span>

          <ReactTooltip
            id={`${index}`}
            place='right'
            effect='solid'
            textColor='#FFFFFF'
            backgroundColor='#4B5563'
            className='text-extra-small rounded-full w-[230px]'
          />
        </div>

        {fraudDetection !== null && fraudDetection !== undefined && (
          <div className='w-1/3 text-right text-[28px] leading-[38px] font-Bold text-Gray-800'>
            {fraudDetection}
          </div>
        )}

        {AverageRefCheckTime !== null && AverageRefCheckTime !== undefined && (
          <div className='w-1/3 text-right text-[28px] leading-[38px] font-Bold text-Gray-800'>
            {AverageRefCheckTime === 0 ? 'N/A' : `${AverageRefCheckTime} hours`}
          </div>
        )}
      </div>

      {isDetailedCard && (
        <div className='detailed-section flex mt-3 h-4/5'>
          <div className='w-1/3 h-auto relative' data-tip='' data-for='chart'>
            <PieChart
              data={data}
              segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
              className='w-full'
              lineWidth={!isFullCircle ? 10 : undefined}
              onMouseOver={(_, index) => {
                setHovered(index)
              }}
              onMouseOut={() => {
                setHovered(null)
              }}
              background={isAllEmpty ? `white` : `#ECECEC`}
            />

            <ReactTooltip
              id='chart'
              getContent={() =>
                typeof hovered === 'number'
                  ? makeTooltipContent(data[hovered])
                  : null
              }
            />

            {!isFullCircle && (
              <div className='flex flex-col w-full justify-center items-center absolute top-0 left-0 right-0 bottom-0'>
                <span className='text-center text-[20px] font-Medium'>
                  {totalCount}
                </span>

                <span className='text-extra-small text-Gray-600 text-center font-Regular'>
                  Total <br />
                  {title === 'Referees' ? 'Referees' : 'Candidates'}
                </span>
              </div>
            )}
          </div>

          <div className='w-2/3 flex flex-col justify-center items-center gap-y-3 ml-8'>
            {data?.map((item: any, index: number) => (
              <div
                key={index}
                className='flex items-center justify-between w-full'>
                <div
                  className='flex items-center text-Gray-600 text-small cursor-pointer hover:font-Medium'
                  onClick={() => {
                    if (item?.module) {
                      setDashboardDetailModal({
                        ...dashboardDetailModal,
                        isActive: true,
                        module: item?.module,
                        status: item?.status,
                        title: item?.title,
                      })
                    }
                  }}>
                  <i
                    className='ri-checkbox-blank-circle-fill text-[12px] mr-1'
                    style={{ color: item.color }}
                  />

                  {item?.title}
                </div>

                <div className='text-base font-Medium'>{item?.value}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

DashboardReportCard.defaultProps = {
  isDetailedCard: true,
  isFullCircle: false,
  data: [],
  totalCount: 0,
}

export default DashboardReportCard
