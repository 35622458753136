import CN from 'classnames'

import PoliceCheckHistoryList from 'components/pages/candidates/candidateProfileTabs/EligibilityTabView/PoliceCheckHistoryTable/PoliceCheckHistoryListColumn'

type EligibilityCriminalHistoryCardProps = {
  onAddClick?: () => void
  onDeleteClick?: any | undefined
  onEditClick?: any | undefined
  [x: string]: any
}

export const EligibilityPoliceCheckHistoryCard = ({
  className,
  ...restProps
}: EligibilityCriminalHistoryCardProps) => {
  const EligibilityCriminalHistoryCardClasses = CN(
    `eligibility-criminal-history-card border bg-white border-Gray-200 rounded-md py-4 px-5 mt-5 relative`,
    className,
  )

  return (
    <div className={EligibilityCriminalHistoryCardClasses} {...restProps}>
      {/* loader */}
      {/* {isLoading && <PageLoader size='xxs' className='h-full min-h-[200px]' />} */}

      <div className='flex justify-between'>
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Police Check History
        </span>
      </div>

      <PoliceCheckHistoryList />
    </div>
  )
}

EligibilityPoliceCheckHistoryCard.defaultProps = {
  onAddClick: undefined,
  onDeleteClick: undefined,
  onEditClick: undefined,
}

export default EligibilityPoliceCheckHistoryCard
