/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, ReactNode } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { motion } from 'framer-motion'

type ModalProps = {
  [x: string]: any
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  closeButtonClassName?: string | undefined
  customWidth?: string | undefined
  customZIndex?: number | undefined
  footerButtonAlignment?: 'left' | 'right' | 'center'
  headerTitle?: string
  subHeaderTitle?: string
  isActive?: boolean | undefined
  isCustomStyles?: boolean
  isHeaderShow?: boolean
  isSeparator?: boolean
  modalContainerClassNames?: string | undefined
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  size?: 'sm' | 'md' | 'lg'
}

export const Modal: FC<ModalProps> = ({
  children,
  className,
  closeButtonClassName,
  customWidth,
  customZIndex,
  headerTitle,
  subHeaderTitle,
  isActive,
  isCustomStyles,
  isHeaderShow,
  isSeparator,
  modalContainerClassNames,
  onHeaderCloseButtonClick,
  onOverlayClick,
  size,
  ...restProps
}: ModalProps) => {
  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed flex top-0 bottom-0 left-0 right-0 z-50 justify-center items-center h-full bg-black bg-opacity-20',
        className,
        {
          hidden: !isActive,
        },
        {
          'z-50': customZIndex === undefined,
          'z-10': customZIndex === 10,
          'z-20': customZIndex === 20,
          'z-30': customZIndex === 30,
          'z-40': customZIndex === 40,
          'z-60': customZIndex === 60,
          'z-70': customZIndex === 70,
          'z-80': customZIndex === 80,
          'z-90': customZIndex === 90,
          'z-100': customZIndex === 100,
        },
      )
    : className

  /** main modal container styles */
  const ModalContainerClassName = CN(
    'modal-container flex flex-col justify-between items-center bg-white shadow-lg rounded-sm relative',
    customWidth === undefined && {
      'w-[400px]': size === 'sm',
      'w-[600px]': size === 'md',
      'w-[800px]': size === 'lg',
    },
    modalContainerClassNames,
  )

  /** main modal header styles */
  const ModalHeaderClassName = CN(
    'modal-Header flex w-full justify-between px-6 py-[15px] items-center',
    {
      'border-b-[1px] border-[#E5E7EB]': isSeparator,
    },
  )

  /** prevent modal hide when click inside modal */
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  return (
    <div
      className={OverlayClassName}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
        onOverlayClick && onOverlayClick()
      }}
      {...restProps.overlayProps}>
      {/* Modal main container  */}
      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, y: 0, transition: { duration: 0.1 } },
          closed: { opacity: 1, y: '-100%' },
        }}
        className={ModalContainerClassName}
        style={customWidth && { width: customWidth, maxHeight: '90%' }}
        {...restProps.modalProps}
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}

        {isHeaderShow && (
          <div className={ModalHeaderClassName} {...restProps.headerProps}>
            <div className='w-11/12'>
              <span className='flex items-center header-title text-heading-4 font-Medium text-Gray-700'>
                {headerTitle}
              </span>
              <span className='flex items-center text-small text-Gray-600'>
                {subHeaderTitle}
              </span>
            </div>

            <Button
              className={CN(
                'w-[34px] h-[34px] flex justify-center items-center',
                closeButtonClassName,
              )}
              size='sm'
              isIconOnly
              textTheme='blue'
              theme='white'
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onHeaderCloseButtonClick && onHeaderCloseButtonClick()
              }}
              icon={
                <i className='cursor-pointer ri-close-fill text-Cobalt-600 text-heading-3'></i>
              }
            />
          </div>
        )}

        {/* Modal body */}
        {children}
      </motion.div>
    </div>
  )
}

export default Modal

Modal.defaultProps = {
  children: undefined,
  className: undefined,
  closeButtonClassName: undefined,
  customWidth: undefined,
  customZIndex: undefined,
  footerButtonAlignment: 'left',
  headerTitle: 'Lorem ipsum',
  isActive: true,
  isCustomStyles: false,
  isFooterShow: true,
  isHeaderShow: true,
  isSeparator: true,
  modalContainerClassNames: undefined,
  onClickPrimaryBtn: undefined,
  onClickSecondaryBtn: undefined,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: 'Lorem',
  secondaryButtonTitle: 'Lorem',
  size: 'sm',
}
