import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Modal, TextArea, Toast } from '@labourhub/labour-hub-ds'
import { useCreateCandidateNote } from 'api/candidates'
import { ModalProps } from 'types'

import { dateAndTimeToCustomFormat } from 'utils'

import { HistoryItemCard } from './HistoryItemCard'

type SaveCardModalProps = ModalProps & {
  candidateId: string
  [x: string]: any
}

export const AddHistoryRecordModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  getListData,
}: SaveCardModalProps) => {
  // const typeOptionList = [
  //   {
  //     label: 'Add',
  //     value: 'Add',
  //   },
  //   {
  //     label: 'Error',
  //     value: 'Error',
  //   },
  //   {
  //     label: 'Info',
  //     value: 'Info',
  //   },
  //   {
  //     label: 'Note',
  //     value: 'Note',
  //   },
  //   {
  //     label: 'Send',
  //     value: 'Send',
  //   },
  //   {
  //     label: 'Success',
  //     value: 'Success',
  //   },
  // ]
  const [searchParams] = useSearchParams()
  const [formDetails, setFormDetails] = useState<any>({
    type: 'Note',
    note: '',
  })

  const { userDetails } = useSelector((state: any) => state.user)

  const resetFields = () => {
    setFormDetails({
      type: 'Note',
      note: '',
    })
  }

  const {
    mutate: addCandidateNoteMutate,
    isLoading: addCandidateNoteIsLoading,
  } = useCreateCandidateNote()

  /** Process the add new candidate */
  const addCandidateNote = () => {
    addCandidateNoteMutate(
      {
        candidateId,
        formDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          resetFields()

          setIsModalActive(false)
          getListData()
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Modal
      isActive={isModalActive}
      modalProps={{ style: { width: '560px', height: 'auto' } }}
      isHeaderShow={true}
      headerTitle='Add History Record'
      headerCloseButtonProps={{
        style: {
          fontSize: '22px',
        },
      }}
      onHeaderCloseButtonClick={() => {
        resetFields()
        setIsModalActive(false)
      }}
      isFooterShow={true}
      footerProps={{
        style: {
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonTitle={'Save'}
      primaryButtonProps={{
        isLoading: addCandidateNoteIsLoading,
        isDisabled: !formDetails?.type || !formDetails?.note,
        style: {
          marginLeft: '12px',
          paddingLeft: '30px',
          paddingRight: '30px',
        },
      }}
      onClickPrimaryBtn={() => {
        addCandidateNote()
      }}
      secondaryButtonTitle='Cancel'
      secondaryButtonProps={{
        theme: 'white',
        textTheme: 'black',
      }}
      onClickSecondaryBtn={() => {
        resetFields()
        setIsModalActive(false)
      }}>
      <div className='w-full h-full py-5 px-6 max-h-[70vh] min-h-[45vh] overflow-y-auto styled-scroll'>
        {/* <SimpleSelect
          className='type'
          value={
            formDetails?.type
              ? {
                  label: formDetails?.type,
                  value: formDetails?.type,
                }
              : null
          }
          onChange={(selectedItem: any) => {
            setFormDetails({
              ...formDetails,
              type: selectedItem.label,
            })
          }}
          label='Select the type'
          options={typeOptionList}
          placeholder='Type'
        /> */}

        <TextArea
          className='pt-4 notes'
          value={formDetails?.note}
          onChange={(e: any) => {
            setFormDetails({
              ...formDetails,
              note: e.target.value,
            })
          }}
          placeholder='Add a Note'
          rows={4}
        />

        {formDetails?.type && (
          <div className='mt-4'>
            <div className='mb-1 text-small text-Gray-900 font-Medium'>
              Preview
            </div>

            <HistoryItemCard
              icon={formDetails?.icon}
              description={formDetails?.note}
              date={dateAndTimeToCustomFormat(new Date())}
              title={`(${userDetails?.firstName} ${
                userDetails?.lastName
              }) added new note for (${searchParams.get('candidate_name')})`}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}
