import { useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { getSubscriptionStore } from 'features/subscriptions'

type CandidateRefereeEmptyStateProps = {
  candidateDetails: any
  setShowRefereeRequestModal: any
  setShowRefereeRequestReminderModal: any
  setShowAddRefereeModal: any
}

export const CandidateRefereeEmptyState = ({
  candidateDetails,
  setShowRefereeRequestModal,
  setShowRefereeRequestReminderModal,
  setShowAddRefereeModal,
}: CandidateRefereeEmptyStateProps) => {
  const { fixedQuotas, payAsYouGoQuotas } = useSelector(getSubscriptionStore)

  const isQuotaNotAvailable =
    fixedQuotas.referenceCheck.remainingQuota +
      payAsYouGoQuotas.ReferenceCheck.remainingQuota ==
    0

  return (
    <div className='empty-state flex flex-col w-full border-[1px] border-Gray-200 rounded-lg bg-white'>
      <i className='ri-team-line flex justify-center text-[43px] text-Gray-500 pt-[111px]'></i>

      <span className='flex justify-center font-Medium text-heading-4 text-Gray-800'>
        No referees found!
      </span>

      <span className='flex justify-center pt-2 font-Regular text-small text-Gray-600'>
        Request referee details from candidates or add them manually
      </span>

      <div className='flex justify-center gap-6 pt-6 pb-[112px]'>
        {/** Request Referee Button*/}
        <Button
          iconLeft={<i className='ri-send-plane-line' />}
          isDisabled={isQuotaNotAvailable}
          onClick={() => {
            candidateDetails?.requestSendDate == null
              ? setShowRefereeRequestModal(true)
              : setShowRefereeRequestReminderModal(true)
          }}>
          {candidateDetails?.requestSendDate == null
            ? `Request Referees`
            : `Send Reminder (Request Referees)`}
        </Button>

        {/** Add New Referee Button */}
        <Button
          iconLeft={<i className='ri-add-fill' />}
          isDisabled={isQuotaNotAvailable}
          textTheme='blue'
          theme='white'
          onClick={() => setShowAddRefereeModal(true)}>
          Add New Referee
        </Button>
      </div>
    </div>
  )
}
