import { useState } from 'react'
import QRCode from 'react-qr-code'
import { Button, TextField } from '@labourhub/labour-hub-ds'
import {
  SetupTwoFAResponse,
  useConfirmTwoFA,
  useDisableTwoFA,
  useSetupTwoFA,
} from 'features/auth'

import { Column } from 'components/atoms/Column'
import { H6 } from 'components/atoms/Headings'
import { Row, RowWithAlignment } from 'components/atoms/Row'

type TwoFaSettingsSectionProps = {
  [x: string]: any
  userDataLoading?: boolean
}

export const TwoFaSettingsSection = ({
  userData,
  notify,
}: TwoFaSettingsSectionProps) => {
  const [setupMode, setSetupMode] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [authCode, setAuthCode] = useState<string>('')
  const [twoFA, setTwoFa] = useState<SetupTwoFAResponse | null>(null)

  const { refetch } = useSetupTwoFA(userData?.email, (data) => setTwoFa(data))

  const { mutate: confirmMutate } = useConfirmTwoFA()

  const handleVerification = () => {
    if (authCode == '') {
      notify({
        alertHeader: 'Please enter the code provided by the Authenticator App',
        status: 'Warning',
      })
      setHasError(true)
      return
    }

    confirmMutate(
      {
        email: userData?.email,
        code: authCode,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setTwoFa(null)
          setSetupMode(false)
          refetch()
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const { mutate: disableMutate } = useDisableTwoFA()

  const handleDisable = () => {
    disableMutate(
      {
        email: userData?.email,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          refetch()
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Column>
      <RowWithAlignment>
        <span className='font-Medium text-Gray-800'>
          2 Factor Authentication
        </span>
        {twoFA?.isTwoFAEnabled ? (
          <Button onClick={handleDisable} textTheme='terracotta' theme='link'>
            Disable 2FA
          </Button>
        ) : (
          <Button
            onClick={() => setSetupMode(true)}
            textTheme='blue'
            theme='link'>
            Setup
          </Button>
        )}
      </RowWithAlignment>

      {setupMode && twoFA && (
        <div className='flex flex-col w-full'>
          <div className='py-6'>
            <H6 title='Step 1' />
            <p className='my-3'>
              Using the Authenticator app, you should scan the following QR
              code:
            </p>
            {twoFA.authenticatorUri && (
              <QRCode value={twoFA.authenticatorUri} />
            )}
            <p className='my-3'>
              Alternatively, you may enter the following code to the
              Authenticator app:
            </p>
            <pre className='p-2 border rounded-lg text-heading-4 border-Gray-300'>
              {twoFA?.secretKey}
            </pre>
          </div>

          <div>
            <H6 title='Step 2' />
            <div>
              <div className='mb-3 row'>
                <TextField
                  label='Enter the code provided by the Authenticator App:'
                  isRequired={true}
                  className='w-full mt-4'
                  placeholder='6-digit verification code'
                  value={authCode}
                  onChange={(e: any) => setAuthCode(e.target.value)}
                  isErrored={hasError && authCode == ''}
                />
              </div>
              <Row className='justify-end mt-6'>
                <Button
                  className='mr-2'
                  onClick={() => setSetupMode(false)}
                  textTheme='blue'
                  theme='white'>
                  Cancel
                </Button>
                <Button className='mr-2' onClick={handleVerification}>
                  Verify
                </Button>
              </Row>
            </div>
          </div>
        </div>
      )}
    </Column>
  )
}
