/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Badge } from '@labourhub/labour-hub-ds'

import { MoreMenuButton } from 'components/atoms'
import { timeToAESTWithSeconds } from 'utils'

export const SurveyResponsesListColumn = (
  setSelectedResponse: any,
  setIsReminderModalActive: any,
  setShowSurveyResponseDetailsModal: any,
  setIsDownlaodSurveyReport: any,
) => {
  const COLUMNS = [
    {
      Header: 'SURVEY NAME',
      accessor: 'surveyName',
      forSorting: 'SurveyName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[240px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.surveyName}</div>
      },
    },
    {
      Header: 'SENT BY',
      accessor: 'sentBy',
      forSorting: 'sentBy',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[135px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.sentBy}</div>
      },
    },
    {
      Header: 'SENT ON',
      accessor: 'sentOn',
      forSorting: 'SentOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[205px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const createdDateString = timeToAESTWithSeconds(original?.sentOn)
        return <div>{createdDateString}</div>
      },
    },
    {
      Header: 'COMPLETED ON',
      accessor: 'completedOn',
      forSorting: 'CompletedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[205px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const updatedDateString =
          original?.completedOn !== null
            ? timeToAESTWithSeconds(original?.completedOn)
            : '-'
        return <div>{updatedDateString}</div>
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[230px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const badgeTheme =
          original?.status === 'Response Received'
            ? 'green'
            : original?.status === 'Awaiting for Response'
            ? 'yellow'
            : 'red'
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              {original?.status}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[65px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='relative'>
            {original.status === 'Request Declined' ? null : (
              <MoreMenuButton
                className='ml-2'
                actionButtons={
                  original.status === 'Awaiting for Response'
                    ? [
                        {
                          iconClass: 'ri-send-plane-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsReminderModalActive(true)
                          },
                          title: 'Send Reminder',
                          status: 'normal',
                        },
                      ]
                    : original.status === 'Response Received'
                    ? [
                        {
                          iconClass: 'ri-eye-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setShowSurveyResponseDetailsModal(true)
                          },
                          title: 'Preview Report',
                          status: 'normal',
                        },
                        {
                          iconClass: 'ri-download-2-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsDownlaodSurveyReport(true)
                          },
                          title: 'Download Result',
                          status: 'normal',
                        },
                      ]
                    : []
                }
              />
            )}
          </div>
        )
      },
    },
  ]
  return COLUMNS
}
