import { useEffect, useMemo, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import {
  ReportCandidateSentimentDto,
  ReportRefereeStatsCountDto,
  useCreateExecutiveSummary,
} from 'api/executiveSummary'
import { ReportType } from 'features/candidates/data'
import { CandidateProfileBasicDetailsType } from 'framework/api/methods'
import { ModalProps, ReportPreviewData } from 'types'

import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'

import {
  ReportBanner,
  ReportExecutiveSummarySection,
  ReportFooter,
  ReportLogoHeader,
  ReportQuestionnaireSummarySection,
  ReportRefereeDetailsSection,
} from '../../sections'
import {
  ReferenceReportCandidateDetails,
  ReferenceReportCandidateDetailsDto,
} from '../../sections/ReportCandidateDetails/ReferenceReportCandidateDetails'

import { ReferenceReportEditExecutiveSummaryModal } from './ReferenceReportEditExecutiveSummaryModal'
import { ReferenceReportPrintPreview } from './ReferenceReportPrintPreview'

import '../style.scss'

type ReferenceReportPreviewModalProps = ModalProps & {
  reportType: string
  candidateId: string
  candidateDetails: CandidateProfileBasicDetailsType
  stats: ReportRefereeStatsCountDto
  sentiment: ReportCandidateSentimentDto
  previewData: ReportPreviewData
  isSummaryReadMode?: boolean
}

export const ReferenceReportPreviewModal = ({
  isModalActive,
  setIsModalActive,
  reportType,
  candidateId,
  candidateDetails,
  stats,
  sentiment,
  previewData,
  isSummaryReadMode = false,
}: ReferenceReportPreviewModalProps) => {
  const notify = (props: any) => Toast(props)
  const modalRef = useRef<HTMLDivElement>(null)

  const [newPreviewData, setNewPreviewData] =
    useState<ReportPreviewData>(previewData)

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTop = 0
    }
    setNewPreviewData(previewData)
  }, [isModalActive, previewData])

  const [isEditExecutiveSummaryModal, setIsEditExecutiveSummaryModal] =
    useState(false)

  const candidateReportDetails = useMemo(() => {
    const details: ReferenceReportCandidateDetailsDto = {
      name: candidateDetails.name,
      candidateNo: candidateDetails.candidateNo,
      email: candidateDetails.email,
      phoneNumber: candidateDetails.phone,
      avatarUrl: candidateDetails.avatarUrl,
      jobTitle: candidateDetails.jobTitle,
      consultantName: candidateDetails.consultantName,
    }
    return details
  }, [candidateDetails])

  const [isPrinting, setIsPrinting] = useState(false)
  const componentRef = useRef<any>(null)
  const promiseResolveRef = useRef<any>(null)

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])

  const pageStyle = ` 
  @media print {
    @page {
      size: a4 !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    body {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  `
  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
    onBeforePrint: () => setIsModalActive(false),
    onAfterPrint: () => {
      promiseResolveRef.current = null
      setIsPrinting(false)
    },
  })

  const { mutate, isLoading } = useCreateExecutiveSummary()

  const handleDownload = () => {
    setIsEditClicked(isEditClicked)

    if (!isSummaryReadMode && newPreviewData.executiveSummary) {
      mutate(
        {
          candidateId,
          refereeIds: newPreviewData.referees.map((r) => r.id),
          clientId: newPreviewData?.clientId,
          isClientLogoOnly:
            newPreviewData.hasClientLogo && !newPreviewData.hasCompanyLogo,
          summary: newPreviewData.executiveSummary,
          type: 'ReferenceCheck',
          reportType,
        },
        {
          onSuccess: () => {
            handlePrint()
          },
          onError: () => {
            notify({
              alertHeader: 'Error...!',
              alertBody: 'Unable to save executive summary',
              status: 'Error',
            })
          },
        },
      )
    } else {
      handlePrint()
    }
  }

  const updateNewPreviewData = (executiveSummary: string) => {
    setNewPreviewData((prev) => ({
      ...prev,
      executiveSummary,
    }))
  }

  const [isEditClicked, setIsEditClicked] = useState(false)

  return (
    <>
      <Modal
        headerTitle='Reference Report Preview'
        isFooterShow={true}
        isActive={isModalActive}
        className='!z-[150]'
        onHeaderCloseButtonClick={() => setIsModalActive(false)}
        onOverlayClick={() => setIsModalActive(false)}
        primaryButtonProps={{
          style: { marginRight: '12px' },
          isLoading,
        }}
        secondaryButtonProps={{
          style: { color: '#1F2937' },
        }}
        modalProps={{
          style: { width: '800px', height: '95vh' },
        }}
        footerProps={{
          style: {
            justifyContent: 'end',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}>
        <div
          ref={modalRef}
          className='relative flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
          <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
            <div style={{ pageBreakBefore: 'always' }}>
              <div className='h-[1123px] relative bg-white border-b-2 border-dashed border-Gray-300'>
                <ReportLogoHeader
                  clientlogo={newPreviewData.clientLogoUrl}
                  hasClientLogo={newPreviewData.hasClientLogo}
                  hasCompanyLogo={newPreviewData.hasCompanyLogo}
                />
                <ReportBanner />
                <ReferenceReportCandidateDetails
                  details={candidateReportDetails}
                  stats={stats}
                  sentiment={sentiment}
                />
                <ReportFooter isFixed={false} pageNumber={1} />
              </div>
            </div>

            {newPreviewData.executiveSummary && (
              <div
                className='h-[1123px] relative bg-white border-b-2 border-dashed border-Gray-300'
                style={{ pageBreakBefore: 'always' }}>
                <ReportExecutiveSummarySection
                  executiveSummary={newPreviewData.executiveSummary}
                />
                <ReportFooter isFixed={false} pageNumber={2} />
              </div>
            )}

            <div className='relative bg-white'>
              {newPreviewData?.referees?.map((referee, index) => (
                <div
                  className='min-h-[1123px] relative border-b-2 last:border-b-0 border-dashed border-Gray-300'
                  style={{ pageBreakBefore: 'always' }}
                  key={index}>
                  <ReportRefereeDetailsSection
                    referee={referee}
                    refereeNumber={index + 1}
                    refereeCount={newPreviewData?.referees?.length}
                  />

                  <div className='px-6 pb-10'>
                    <ReportQuestionnaireSummarySection
                      candidateId={candidateId}
                      referee={referee}
                      reportType={ReportType.Reference}
                    />
                  </div>
                  <ReportFooter
                    isFixed={false}
                    pageNumber={
                      index + (newPreviewData?.executiveSummary ? 3 : 2)
                    }
                  />
                </div>
              ))}
            </div>
          </Column>
        </div>

        <ModalFooter className='py-4 bg-Gray-50'>
          <div className='flex justify-end flex-1'>
            {!isSummaryReadMode && newPreviewData.executiveSummary ? (
              <Button
                onClick={() => setIsEditExecutiveSummaryModal(true)}
                iconLeft={
                  <i className='flex items-center ri-pencil-line text-small' />
                }
                textTheme='blue'
                theme='white'>
                Edit Summary
              </Button>
            ) : (
              <Button
                onClick={() => setIsModalActive(false)}
                textTheme='blue'
                theme='white'>
                Close
              </Button>
            )}

            <Button
              onClick={() => {
                // handleDownloadPdf()

                handleDownload()
              }}
              iconLeft={
                <i className='flex items-center ri-download-line text-small' />
              }
              className='ml-2'
              theme='cobalt'>
              {!isSummaryReadMode && newPreviewData.executiveSummary
                ? 'Save & Download'
                : 'Download'}
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {isModalActive && newPreviewData && (
        <ReferenceReportPrintPreview
          ref={componentRef}
          candidateId={candidateId}
          candidateDetails={candidateDetails}
          stats={stats}
          sentiment={sentiment}
          previewData={newPreviewData}
        />
      )}

      {!isSummaryReadMode && newPreviewData.executiveSummary && (
        <ReferenceReportEditExecutiveSummaryModal
          isModalActive={isEditExecutiveSummaryModal}
          setIsModalActive={setIsEditExecutiveSummaryModal}
          candidateId={candidateId}
          refereeIds={newPreviewData.referees.map((referee) => referee.id)}
          executiveSummary={newPreviewData.executiveSummary}
          updateNewPreviewData={updateNewPreviewData}
        />
      )}
    </>
  )
}
