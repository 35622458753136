import { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { useConvertEmployee } from 'api/candidates'
import {
  CandidateProfileBasicDetailsType,
  useUpdateCandidateMarkPassFail,
} from 'framework/api/methods'

import { PageLoader } from 'components/atoms'

import { CandidateMarkingStatus } from '../data'

type MarkCandidateSwitchProps = {
  candidateDetails: CandidateProfileBasicDetailsType | undefined
  getCandidateById: () => void
}

export const MarkCandidateSwitch = ({
  candidateDetails,
  getCandidateById,
}: MarkCandidateSwitchProps) => {
  const notify = (props: any) => Toast(props)

  const [selected, setSelected] = useState(false)
  const [isCandidateConvertModalOpen, setIsCandidateConvertModalOpen] =
    useState(false)

  /** Convert Employee API call */
  const { mutate: convertEmployeeMutate, isLoading: convertEmployeeLoading } =
    useConvertEmployee()

  const handleConvertEmployee = () => {
    if (candidateDetails?.candidateId) {
      convertEmployeeMutate(
        {
          candidateId: candidateDetails?.candidateId,
          isEmployee: true,
        },
        {
          onSuccess: ({ data: successData }: any) => {
            notify({
              alertHeader: successData?.message,
              alertBody: '',
              status: 'Success',
            })
            setIsCandidateConvertModalOpen(false)
            getCandidateById()
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: 'Error...!',
              alertBody:
                'Candidate converted to Employee failed' || errData?.message,
              status: 'Error',
            })
            setIsCandidateConvertModalOpen(false)
          },
        },
      )
    }
  }

  /** Update candidate mark as pass/fail API call */
  const {
    mutate: updateCandidateMarkPassFailMutate,
    isLoading: updateCandidateMarkPassFailIsLoading,
  } = useUpdateCandidateMarkPassFail()

  async function updateCandidateMarkPassFail(markingStatus: string | null) {
    updateCandidateMarkPassFailMutate(
      {
        candidateId: candidateDetails?.candidateId,
        markingStatus,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Success!',
            alertBody: successData?.message,
            status: 'Success',
          })
          getCandidateById()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const dataTip =
    candidateDetails?.markedStatus != null
      ? 'Select an option to indicate if the candidate has been officially hired'
      : "Select an option to indicate the candidate's suitability for further consideration"

  return (
    <div className='w-full max-w-xs flex flex-row mx-auto text-center'>
      {(updateCandidateMarkPassFailIsLoading || convertEmployeeLoading) && (
        <PageLoader size='xxs' className='absolute' />
      )}

      <div className='w-full flex flex-row'>
        {selected === false &&
        candidateDetails?.isEmployee === false &&
        candidateDetails?.markedStatus === null ? (
          <>
            <button
              onClick={() => {
                setSelected(true)
                updateCandidateMarkPassFail(CandidateMarkingStatus.Failed)
              }}
              className={
                'w-full flex justify-center font-Medium focus:outline-none p-1 hover:bg-Cobalt-600 hover:text-white cursor-pointer bg-white text-Cobalt-600 border-2 border-r-0 border-Cobalt-600 rounded-tl-md rounded-bl-md'
              }>
              <span className='text-center'>Mark as Failed</span>
            </button>
            <button
              onClick={() => {
                setSelected(true)
                updateCandidateMarkPassFail(CandidateMarkingStatus.Passed)
              }}
              className={
                'w-full flex justify-center font-Medium focus:outline-none p-1 hover:bg-Cobalt-600 hover:text-white cursor-pointer bg-white text-Cobalt-600 border-2 border-Cobalt-600 rounded-tr-md rounded-br-md'
              }>
              <span>Mark as Passed</span>
            </button>{' '}
          </>
        ) : (
          <>
            <button
              disabled={candidateDetails?.isEmployee}
              className={`w-full flex justify-center font-Medium focus:outline-none p-1 cursor-pointer ${
                candidateDetails?.isEmployee === false
                  ? 'bg-Cobalt-600 text-white border-2 border-Cobalt-600 rounded-tl-md rounded-bl-md'
                  : 'bg-Gray-200 text-Gray-500 rounded-tl-md rounded-bl-md'
              }`}>
              <span className='text-center'>Candidate</span>
            </button>
            <button
              onClick={() => {
                setIsCandidateConvertModalOpen(true)
              }}
              disabled={candidateDetails?.isEmployee}
              className={`w-full flex justify-center font-Medium focus:outline-none p-1 cursor-pointer ${
                candidateDetails?.isEmployee === true
                  ? 'bg-Cobalt-600 text-white rounded-tr-md rounded-br-md'
                  : 'bg-white text-Cobalt-600 hover:bg-Gray-50 border-2 border-Cobalt-600 rounded-tr-md rounded-br-md'
              }`}>
              <span>Employee</span>
            </button>
          </>
        )}
      </div>

      <span
        className='flex h-[30px] w-[30px] justify-center items-center hover:bg-Cobalt-50 hover:shadow-sm ml-1'
        data-tip={dataTip}
        data-for='mark-candidate'>
        <i className='ri-error-warning-line text-[18px] text-Cobalt-600' />
      </span>
      <ReactTooltip
        id='mark-candidate'
        place='right'
        effect='solid'
        textColor='#FFFFFF'
        backgroundColor='#1b1b1c'
        className='text-extra-small text-left rounded-full w-[230px]'
      />

      {/* Candidate convert to Employee confirmation pop up */}
      <Modal
        isActive={isCandidateConvertModalOpen}
        onClickPrimaryBtn={() => {
          handleConvertEmployee()
        }}
        onClickSecondaryBtn={() => {
          setIsCandidateConvertModalOpen(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: updateCandidateMarkPassFailIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, Sure'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Cobalt-50'>
            <i className='ri-error-warning-line text-Cobalt-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Convert to Employee?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to convert this candidate to an employee? This
            action is permanent and cannot be reverted.
          </span>
        </div>
      </Modal>
    </div>
  )
}
