import { useDispatch, useSelector } from 'react-redux'
import CN from 'classnames'
import {
  getSubscriptionStore,
  NormalizedPayAsYouGoSubscriptionPackageType,
  PayAsYouGoSubscriptionPackageType,
} from 'features/subscriptions'

import PoliceCheckLayout from 'components/layouts/PoliceCheckLayout/PoliceCheckLayout'

import { getPaymentStore, setPayAsYouGoCredits } from '../../../store'

import { QuantityControlRow } from './QuantityControlRow'

export const QuantityControlTable = ({ isFullWidth = false }) => {
  const dispatch = useDispatch()

  const { payAsYouGoUnitPrices } = useSelector(getSubscriptionStore)
  const { payAsYouGoCredits } = useSelector(getPaymentStore)

  const setQuantity = (
    key: PayAsYouGoSubscriptionPackageType,
    quantity: number,
  ) => {
    dispatch(
      setPayAsYouGoCredits({
        key,
        quantity,
      }),
    )
  }

  return (
    <table className={CN({ 'w-full': isFullWidth })}>
      <tbody>
        <PoliceCheckLayout>
          <QuantityControlRow
            id={PayAsYouGoSubscriptionPackageType.PoliceCheck}
            label={NormalizedPayAsYouGoSubscriptionPackageType.PoliceCheck}
            labelTooltip='Complete the ACIC Registration first. Once approved, you can start
                  conducting checks.'
            unitPrice={payAsYouGoUnitPrices.PoliceCheck}
            quantity={payAsYouGoCredits.PoliceCheck}
            setQuantity={(value) =>
              setQuantity(PayAsYouGoSubscriptionPackageType.PoliceCheck, value)
            }
            isFullWidth={isFullWidth}
          />
          <QuantityControlRow
            id={PayAsYouGoSubscriptionPackageType.VolunteerCheck}
            label={NormalizedPayAsYouGoSubscriptionPackageType.VolunteerCheck}
            labelTooltip='Complete the ACIC Registration first. Once approved, you can start
                  conducting checks.'
            unitPrice={payAsYouGoUnitPrices.VolunteerCheck}
            quantity={payAsYouGoCredits.VolunteerCheck}
            setQuantity={(value) =>
              setQuantity(
                PayAsYouGoSubscriptionPackageType.VolunteerCheck,
                value,
              )
            }
            isFullWidth={isFullWidth}
          />
          <QuantityControlRow
            id={PayAsYouGoSubscriptionPackageType.RightToWorkCheck}
            label={NormalizedPayAsYouGoSubscriptionPackageType.RightToWorkCheck}
            type='silver'
            labelTooltip='Complete the ACIC Registration first. Once approved, you can start
                  conducting checks.'
            unitPrice={payAsYouGoUnitPrices.RightToWorkCheck}
            quantity={payAsYouGoCredits.RightToWorkCheck}
            setQuantity={(value) =>
              setQuantity(
                PayAsYouGoSubscriptionPackageType.RightToWorkCheck,
                value,
              )
            }
            isFullWidth={isFullWidth}
          />
        </PoliceCheckLayout>

        <QuantityControlRow
          id={PayAsYouGoSubscriptionPackageType.ReferenceCheck}
          label={NormalizedPayAsYouGoSubscriptionPackageType.ReferenceCheck}
          type='silver'
          unitPrice={payAsYouGoUnitPrices.ReferenceCheck}
          quantity={payAsYouGoCredits.ReferenceCheck}
          setQuantity={(value) =>
            setQuantity(PayAsYouGoSubscriptionPackageType.ReferenceCheck, value)
          }
          isFullWidth={isFullWidth}
        />
      </tbody>
    </table>
  )
}
