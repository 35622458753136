import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, TextArea, TextField, Toast } from '@labourhub/labour-hub-ds'
import {
  useGenerateCompetenciesList,
  useGenerateQuestionnairesTemplate,
} from 'api/ai'
import { QuestionnaireCategoryType } from 'api/questionnaires'
import { RefereeTypeCard } from 'features/candidates/components/AddEditCandidateModals/RefereeTypeCard'
import { setShowGuideModal } from 'features/onboarding/store'
import { defaultCompetencies } from 'features/questionnaires/data'
import { setAIQuestionData } from 'features/questionnaires/store/actions'

import { RowWithAlignment } from 'components/atoms/Row'

import { CompetencyBadgeList } from './CompetencyBadgeList'

type GenerateAiTemplateProps = {
  setIsAiTemplate: any
}

export const GenerateAiTemplate = ({
  setIsAiTemplate,
}: GenerateAiTemplateProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)

  const [formData, setFormData] = useState({
    industry: '',
    jobRole: '',
    templateType: 'Employment',
    competencies: [],
    jobDescription: '',
  })

  const [isCompetenciesLoaded, setIsCompetenciesLoaded] = useState(false)
  const [formCompetencies, setFormCompetencies] =
    useState<string[]>(defaultCompetencies)

  const handleFormData = (name: string, value: string | string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    handleFormData('competencies', [])
    handleFormData('jobDescription', '')
    setFormCompetencies(defaultCompetencies)
    setIsCompetenciesLoaded(false)
  }, [formData.industry, formData.jobRole, formData.templateType])

  const { mutate: generateTemplate, isLoading: isTemplateLoading } =
    useGenerateQuestionnairesTemplate()

  const { mutate: generateCompetencies, isLoading: isCompetenciesListLoading } =
    useGenerateCompetenciesList()

  const handleCreate = () => {
    const { industry, jobRole, templateType, jobDescription, competencies } =
      formData

    if (!industry || !jobRole || !templateType) {
      Toast({
        alertHeader: 'Please fill in all required fields.',
        status: 'Warning',
      })
      handleFormData('competencies', [])
      setFormCompetencies(defaultCompetencies)
      return
    }

    if (!isCompetenciesLoaded) {
      generateCompetencies(
        {
          industry,
          jobRole,
          jobDescription: jobDescription ?? '',
          typeOfReferenceCheck: templateType,
        },
        {
          onSuccess: ({ data }: any) => {
            if (data?.aiResponse) {
              setFormCompetencies(
                data?.aiResponse?.competencyList || defaultCompetencies,
              )
            } else {
              setFormCompetencies(defaultCompetencies)
            }
          },
          onError: () => {
            setFormCompetencies(defaultCompetencies)
          },
          onSettled: () => {
            setIsCompetenciesLoaded(true)
          },
        },
      )
    } else {
      if (competencies.length < 3 || competencies.length > 7) {
        Toast({
          alertHeader: 'Please select between 3 and 7 badges.',
          status: 'Warning',
        })
        return
      }

      generateTemplate(
        {
          questionNumber: 15,
          industry,
          jobRole,
          jobDescription,
          typeOfReferenceCheck: templateType,
          competencies,
        },
        {
          onSuccess: ({ data }: any) => {
            if (data?.aiResponse == null) {
              notify({
                alertHeader: 'Error...!',
                alertBody: 'Error while generating questionnaire',
                status: 'Error',
              })
            } else {
              setIsAiTemplate(false)
              dispatch(setShowGuideModal(false))
              dispatch(setAIQuestionData(data?.aiResponse))
              navigate(
                `/questionnaires/new?buildMode=${QuestionnaireCategoryType.ReferenceCheck}&builderType=guideStepper`,
              )
            }
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          },
        },
      )
    }
  }

  const handleCancel = () => {
    setFormData({
      industry: '',
      jobRole: '',
      templateType: 'Employment',
      jobDescription: '',
      competencies: [],
    })

    setFormCompetencies(defaultCompetencies)

    if (!isCompetenciesLoaded) {
      setIsAiTemplate(false)
    } else {
      setIsAiTemplate(true)
    }
  }

  return (
    <div className='flex flex-col w-full px-4 pt-3'>
      {isCompetenciesLoaded ? (
        <div className='max-h-[500px] overflow-x-hidden overflow-y-auto styled-scroll'>
          <CompetencyBadgeList
            formCompetencies={formCompetencies}
            selectedCompetencies={formData.competencies}
            setSelectedCompetencies={(competencies: string[]) =>
              handleFormData('competencies', competencies)
            }
          />

          <RowWithAlignment justifyAlignment='start' className='px-2 pt-5'>
            <TextArea
              className=''
              value={formData.jobDescription}
              onChange={(e) => handleFormData('jobDescription', e.target.value)}
              style={{ width: '752px' }}
              label='Include a job description for more relevant results'
              placeholder=''
              rows={5}
            />
          </RowWithAlignment>
        </div>
      ) : (
        <div className='space-y-5'>
          <TextField
            label='What industry are you hiring for?'
            placeholder=''
            value={formData.industry}
            onChange={(e) => handleFormData('industry', e.target.value)}
            isRequired
          />

          <TextField
            label='What is the role you are hiring for?'
            className='mt-5'
            placeholder=''
            value={formData.jobRole}
            onChange={(e) => handleFormData('jobRole', e.target.value)}
            isRequired
          />

          <div>
            <span className='flex pb-1 mt-4 text-Gray-800 font-Medium text-small'>
              What type of reference check is this for?{' '}
              <span className='text-Red-500 pl-[2px]'>*</span>
            </span>

            <div className='mt-1 space-y-3'>
              <RefereeTypeCard
                id='Employment'
                name='referee'
                onChange={() => handleFormData('templateType', 'Employment')}
                isSelected={formData.templateType === 'Employment'}
                defaultChecked={formData.templateType === 'Employment'}
                icon='ri-community-line'
                title='Employment Reference'
              />

              <RefereeTypeCard
                id='Professional'
                name='referee'
                onChange={() => handleFormData('templateType', 'Professional')}
                defaultChecked={formData.templateType === 'Professional'}
                isSelected={formData.templateType === 'Professional'}
                icon='ri-briefcase-fill'
                title='Professional Reference'
              />

              <RefereeTypeCard
                id='FamilyFriends'
                name='referee'
                onChange={() => handleFormData('templateType', 'FamilyFriends')}
                isSelected={formData.templateType === 'FamilyFriends'}
                defaultChecked={formData.templateType === 'FamilyFriends'}
                icon='ri-account-pin-circle-line'
                title='Friends & Family Reference'
              />
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col justify-center mt-8 space-y-2'>
        <Button
          isLoading={isTemplateLoading || isCompetenciesListLoading}
          isDisabled={formData.industry == '' || formData.jobRole == ''}
          onClick={handleCreate}
          theme='cobalt'>
          {isCompetenciesLoaded ? 'Build Template' : 'Next'}
        </Button>
        <Button theme='white' textTheme='black' onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
