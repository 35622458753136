/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactTooltip from 'react-tooltip'
import { Avatar, Badge, ProgressBar } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'
import { GuideTooltipModal } from 'features/onboarding'

import { handleDecimal, timeToAESTWithoutSeconds } from 'utils'
import { dateToReadableString } from 'utils/dateToReadableString'

const CandidatesTableColumn = (
  isEmailRequired: boolean,
  navigate: any,
  guideStepperCandidateId?: string,
) => {
  const COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'firstName',
      forSorting: 'FirstName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[250px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center w-full',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div className='relative w-full cursor-pointer'>
            <div
              onClick={() => {
                navigate(
                  `/candidate-list/candidate-profile?&candidate_id=${original?.candidateId}&candidate_name=${original?.firstName} ${original?.lastName}`,
                )
              }}>
              <Avatar
                imageURL={
                  original?.avatarUrl
                    ? original?.avatarUrl
                    : candidateDefaultLogo
                }
                titleClassName='flex w-max line-clamp-1'
                size='xs'
                color='one'
                title={`${original?.firstName} ${original?.lastName}`}
                /** Showing warning and medical notification icons */
                titleIcons={
                  <div className='flex'>
                    {original.isWarning && (
                      <i className='pl-1 ri-error-warning-fill text-Red-500'></i>
                    )}

                    {original.isMedicalNotification && (
                      <i className='pl-1 ri-shield-cross-fill text-Cobalt-500'></i>
                    )}
                  </div>
                }
                tag={isEmailRequired ? original?.email : original.phone}
              />
            </div>

            {guideStepperCandidateId &&
              original?.candidateId == guideStepperCandidateId && (
                <GuideTooltipModal />
              )}
          </div>
        )
      },
    },
    {
      Header: 'REFCHECK STATUS',
      accessor: 'rate',
      forSorting: 'Rate',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div className='w-full'>
            {original.rate === null ? (
              <span className='text-Gray-500'>Pending...</span>
            ) : (
              <div>
                <span className='text-Gray-800 font-Regular text-[14px]'>
                  {/** original?.rate to two decimal points with % */}
                  {handleDecimal(original?.rate, 0)}%
                </span>
                <ProgressBar progressCount={original?.rate} isLabel={false} />
              </div>
            )}
          </div>
        )
      },
    },
    {
      Header: 'SUBMITTED REFEREES',
      accessor: 'referenceCount',
      forSorting: 'ReferenceCount',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.referenceCount}</div>
      },
    },
    {
      Header: 'SURVEYS SENT',
      accessor: 'refereeSurveysSentCount',
      forSorting: 'RefereeSurveysSentCount',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.refereeSurveysSentCount}</div>
      },
    },
    {
      Header: 'RESPONDED REFEREES',
      accessor: 'refereeRespondedCount',
      forSorting: 'RefereeRespondedCount',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.refereeRespondedCount}</div>
      },
    },
    {
      Header: 'PASSED REFERENCES',
      accessor: 'passed',
      forSorting: 'PassedRefereeCount',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.passed}</div>
      },
    },
    {
      Header: 'FAILED REFERENCES',
      accessor: 'failed',
      forSorting: 'FailedRefereeCount',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.failed ? (
              <Badge size='large' theme='red' type='rounded'>
                {original?.failed}
              </Badge>
            ) : (
              0
            )}
          </div>
        )
      },
    },
    {
      Header: 'LAST REFEREE REQUESTED ON',
      accessor: 'refereeRequestedOn',
      forSorting: 'RefereeRequestOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        const refereeRequestedDate = timeToAESTWithoutSeconds(
          original?.refereeRequestedOn,
        )

        return (
          <div className='line-clamp-1' id='referee-requested-on'>
            <span
              data-tip={refereeRequestedDate}
              data-for={`${original?.refereeRequestedOn} {index}`}>
              {original?.refereeRequestedOn
                ? dateToReadableString(original?.refereeRequestedOn)
                : ' - '}
            </span>

            {original?.refereeRequestedOn && (
              <ReactTooltip
                id={`${original?.refereeRequestedOn} {index}`}
                place='right'
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#4B5563'
                className='rounded-full text-extra-small'
              />
            )}
          </div>
        )
      },
    },
    {
      Header: 'MANAGING CONSULTANT',
      accessor: 'consultantName',
      forSorting: 'ConsultantName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start h-auto',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.consultantName}</div>
      },
    },
  ]

  return COLUMNS
}

export default CandidatesTableColumn
