import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Modal,
  SubscriptionUpgradeBadge,
  Toast,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import {
  AppSettingKey,
  getAgencyAppSettings,
  isSettingEnabled,
  isSettingsExist,
} from 'features/settings'
import { getSubscriptionStore } from 'features/subscriptions'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { PageLoader } from 'components/atoms'
import { timeToAESTWithSeconds } from 'utils'
import { downloadDocument } from 'utils/downloadDocument'

import { useAddRightToWorkCheck } from '../../api/useAddRightToWorkCheck'
import { useDownloadCheckReport } from '../../api/useDownloadCheckReport'

type RightToWorkChecksCardProps = {
  onEditClick?: () => void
  [x: string]: any
}

export const RightToWorkCheckCard = ({
  className,
  isLoading,
  rightToWorkData,
  candidateId,
  candidateName,
  getRightToWorkCheckDetails,
  rtwCheckConsultantName,
  ...restProps
}: RightToWorkChecksCardProps) => {
  const dispatch = useDispatch()

  const [isActiveAddRtwCheckModal, setIsActiveAddRtwCheckModal] =
    useState(false)

  const agencyAppSettings = useSelector(getAgencyAppSettings)

  const { isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )

  const {
    payAsYouGoQuotas: { RightToWorkCheck },
  } = useSelector(getSubscriptionStore)

  const [isSettingDisabled, setIsSettingDisabled] = useState(true)

  useEffect(() => {
    if (isSettingsExist(agencyAppSettings)) {
      setIsSettingDisabled(
        !isSettingEnabled(AppSettingKey.NccRightToWorkCheck, agencyAppSettings),
      )
    }
  }, [agencyAppSettings])

  /** APi call for the add rtw check */
  const {
    mutate: addRightToWorkCheckMutate,
    isLoading: addRightToWorkCheckIsLoading,
  } = useAddRightToWorkCheck()

  /** Process the add rtw check */
  const addRtwCheck = () => {
    if (RightToWorkCheck.remainingQuota == 0) {
      Toast({
        alertHeader: 'Insufficient Credit',
        status: 'Warning',
      })

      return
    }
    addRightToWorkCheckMutate(
      {
        CandidateId: candidateId,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })
          setIsActiveAddRtwCheckModal(false)
          getRightToWorkCheckDetails()
          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
          setIsActiveAddRtwCheckModal(false)
        },
      },
    )
  }
  /** download rtw check report on success */
  const onDownloadPoliceCheckSuccess = ({ data }: any) => {
    downloadDocument(`${candidateName}_RightToWork_Check.pdf`, data)
  }

  /** download rtw check report on error */
  const onDownloadPoliceCheckError = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error Downloading Report.',

      status: 'Error',
    })
  }

  /** API Handling request for rtw  check report */
  const {
    refetch: downloadCheckReport,
    isLoading: downloadCheckReportIsLoading,
    isFetching: downloadCheckReportIsFetching,
  } = useDownloadCheckReport(
    {
      id: candidateId,
      type: 'RtwCheck',
    },
    onDownloadPoliceCheckSuccess,
    onDownloadPoliceCheckError,
  )

  const RightToWorkChecksCardClasses = CN(
    `eligibility-right-to-work border bg-white border-Gray-200 rounded-md pb-8 pt-4 px-5 mt-5 relative min-h-[200px] w-1/2`,
    className,
  )

  const isRtwQuotaEmptyOrPartial = !(
    RightToWorkCheck.purchasedQuota > 0 &&
    RightToWorkCheck.remainingQuota > 0 &&
    RightToWorkCheck.purchasedQuota === RightToWorkCheck.remainingQuota
  )

  return (
    <div className={RightToWorkChecksCardClasses} {...restProps}>
      {/* loader */}
      {(isLoading || addRightToWorkCheckIsLoading) && <PageLoader size='xxs' />}

      {/* top header */}
      <div className='flex justify-between'>
        {/* header */}
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Right To Work Check
        </span>

        {!rightToWorkData && (
          <SubscriptionUpgradeBadge
            totalQuota={RightToWorkCheck.purchasedQuota}
            remainingQuota={RightToWorkCheck.remainingQuota}
            isActionBtnRequired={isRtwQuotaEmptyOrPartial}
            onClickActionBtn={() =>
              dispatch(setPayAsYouGoCreditsModalActive(true))
            }
            label='Remaining Credits'
            btnTitle='Buy'
          />
        )}

        {rightToWorkData && (
          <Button
            size='sm'
            isLoading={
              downloadCheckReportIsFetching || downloadCheckReportIsLoading
            }
            isDisabled={rightToWorkData?.resultUrl === null ? true : false}
            onClick={() => {
              downloadCheckReport()
            }}
            textTheme='white'
            theme='cobalt'>
            Download Report
          </Button>
        )}
      </div>

      {rightToWorkData && (
        <table className='flex flex-col pt-5'>
          <tbody className='w-full'>
            {/* status section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Check Status
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {rightToWorkData?.checkStatus === 'PREP' ||
                rightToWorkData?.checkStatus === 'QUEUED'
                  ? 'Sent to Candidate'
                  : rightToWorkData?.checkStatus === 'SENT'
                  ? 'Processing'
                  : rightToWorkData?.checkStatus === 'RESULT'
                  ? 'Received'
                  : rightToWorkData?.checkStatus}
              </td>
            </tr>

            {/* request sent section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Request Sent
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                Sent on {timeToAESTWithSeconds(rightToWorkData?.createdOn)}
              </td>
            </tr>

            {/* outcome received section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Outcome Received
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {rightToWorkData?.resultReceivedDate === null
                  ? 'Processing'
                  : timeToAESTWithSeconds(rightToWorkData?.resultReceivedDate)}
              </td>
            </tr>

            {/* Managing Consultant section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Managing Consultant
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {rtwCheckConsultantName}
              </td>
            </tr>

            {/*  method section */}
            {/* <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Method
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'></td>
            </tr> */}

            {/*  result section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Result
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {!rightToWorkData?.result
                  ? 'Incomplete'
                  : rightToWorkData?.result === 'NONE'
                  ? 'NONE: Candidate Dose not have Australian working rights'
                  : rightToWorkData?.result}
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {/* empty state */}
      {!isLoading && !rightToWorkData && (
        <div className='flex flex-col items-center justify-center mt-10 mb-6'>
          <i className='ri-briefcase-fill text-[52px] text-Gray-500' />

          <span className='pt-2 text-heading-4 font-Medium text-Gray-800'>
            Conduct A Right To Work Check
          </span>

          <span className='pt-2 text-center text-small font-Regular text-Gray-800'>
            Looks like you haven't started the right to work check process for
            this candidate
          </span>

          <Button
            iconLeft={<i className='ri-add-line' />}
            onClick={() => {
              setIsActiveAddRtwCheckModal(true)
            }}
            isDisabled={
              isSettingDisabled || RightToWorkCheck.remainingQuota == 0
            }
            className='mt-6'
            textTheme='white'
            theme='cobalt'>
            Add Right To Work Check
          </Button>
        </div>
      )}

      {/* add police check modal */}
      <Modal
        isActive={isActiveAddRtwCheckModal}
        onClickPrimaryBtn={() => addRtwCheck()}
        className='!z-[150]'
        onClickSecondaryBtn={() => setIsActiveAddRtwCheckModal(false)}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        modalProps={{
          style: { width: '480px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Request Check'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-error-warning-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-center text-Gray-900 text-heading-5 font-Medium'>
            This will deduct one Right to Work Check Credit from your account
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            You currently have {RightToWorkCheck.remainingQuota} credits
            remaining.
          </span>
        </div>
      </Modal>
    </div>
  )
}

RightToWorkCheckCard.defaultProps = {
  isLoading: false,
}

export default RightToWorkCheckCard
