interface InnerListProps {
  id: number
  title: string
  link: string
  isActive: boolean
}

export type InnerListType = InnerListProps[]

interface SubMenuProps {
  id: number
  title: string
  link: string
  hasSubMenu?: boolean
  isActive: boolean
  subMenu?: InnerListType | []
}

export type SubMenuType = SubMenuProps[]

interface MainMenuProps {
  id: number
  menuPosition: string
  title: string
  icon: string
  link: string
  isActive: boolean
  hasSubMenu?: boolean
  subMenu?: SubMenuType | []
}

export type MainMenuTypes = MainMenuProps[]

export const settingsMenu = {
  id: 99,
  menuPosition: 'left',
  title: 'Settings',
  icon: 'ri-settings-5-line',
  link: '/settings',
  isActive: false,
}

export const menuItems: MainMenuTypes = [
  {
    id: 1,
    menuPosition: 'left',
    title: 'Home',
    icon: 'ri-dashboard-line',
    link: '/',
    isActive: false,
  },
  {
    id: 2,
    menuPosition: 'left',
    title: 'Candidates',
    icon: 'ri-team-line',
    link: '/candidate-list',
    isActive: false,
  },
  {
    id: 3,
    menuPosition: 'left',
    title: 'Questionnaires',
    icon: 'ri-star-smile-line',
    link: '/questionnaires',
    isActive: false,
  },
  {
    id: 4,
    menuPosition: 'left',
    title: 'Analytics',
    icon: 'ri-pie-chart-2-line',
    link: '/reporting',
    isActive: false,
  },
  {
    id: 5,
    menuPosition: 'left',
    title: 'Company Profile',
    icon: 'ri-profile-line',
    link: '/agency-profile',
    isActive: false,
  },
  {
    id: 6,
    menuPosition: 'left',
    title: 'Users',
    icon: 'ri-user-settings-line',
    link: '/user-groups',
    isActive: false,
  },
  {
    id: 7,
    menuPosition: 'left',
    title: 'Settings',
    icon: 'ri-settings-5-line',
    link: '/settings',
    isActive: false,
  },
  {
    id: 8,
    menuPosition: 'left',
    title: 'Super Admin Dashboard',
    icon: 'ri-admin-line',
    link: '/super-admin',
    isActive: false,
  },
]

export default menuItems
