import { Button } from '@labourhub/labour-hub-ds'

import { Modal, ModalFooter } from 'components/molecules'
import { timeToAESTWithSeconds } from 'utils'

type RequestRefereeModalProps = {
  isActive: boolean
  setIsActive: (value: boolean) => void
  onRequestRefereeClick: () => void
  [x: string]: any
}

export const RequestRefereeModal = ({
  isActive,
  setIsActive,
  onRequestRefereeClick,
  requestSentOn,
}: RequestRefereeModalProps) => {
  return (
    <Modal
      subHeaderTitle='Send form to candidate asking all the referee information'
      headerTitle='Request Referee'
      isFooterShow={true}
      size='md'
      isActive={isActive}
      onHeaderCloseButtonClick={() => {
        setIsActive(false)
      }}
      onOverlayClick={() => {
        setIsActive(false)
      }}>
      <div className='flex flex-col p-6 w-full'>
        <span className='text-small text-Gray-500 mb-5'>
          Request sent on:{' '}
          <span className='font-SemiBold'>
            {requestSentOn ? timeToAESTWithSeconds(requestSentOn) : 'Never'}
          </span>
        </span>
        <span className='text-small text-Gray-800 mb-5'>
          This will send an email to the candidate asking for referee details.
        </span>
        <span className='text-small text-Gray-800'>
          Candidate can fill out the form and send it back to us.
        </span>
      </div>
      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            onClick={() => {
              onRequestRefereeClick()
            }}
            className='mr-2'
            theme='cobalt'>
            Request Referee
          </Button>
          <Button
            onClick={() => {
              setIsActive(false)
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
