/* eslint-disable prefer-const */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getCandidateList = async ({ queryKey }: any) => {
  const {
    search,
    skip,
    take,
    managingConsultant,
    completionStatus,
    sortColumnName,
    sortDirection,
  } = queryKey[1]

  const res = await get(
    `/facade/Candidate/Listcard?skip=${skip}&take=${take}&search=${search}&ManagingConsultantId=${managingConsultant}&ByCompletionStatus=${completionStatus}&sortColumnName=${sortColumnName}&sortDirection=${sortDirection}`,
  )

  return res.data
}

export const useGetCandidateList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['Candidates', reqBody], getCandidateList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
