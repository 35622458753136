/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { UITable } from 'components/common/UITable'

import { PoliceCheckHistoryListColumn } from './PoliceCheckHistoryListColumn'

export type CandidateListProps = {
  [x: string]: any
}

export const PoliceCheckHistoryList = ({
  className,
  ...restProps
}: CandidateListProps) => {
  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({
    PoliceCheckHistory: [
      {
        candidateNo: 'C1663634034',
        history: 'lorem ipsum',
      },
      {
        candidateNo: 'C1874907869',
        history: 'lorem ipsum',
      },
    ],
  })
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
  })

  /** Table Filter states & Variables */
  const [pageNumber, setPageNumber] = useState(1)

  // const navigate = useNavigate()
  // const notify = (props: any) => Toast(props)

  /** candidate List on success */
  // const onSuccess = (data: any) => {
  //   setTableData(data || {})
  // }

  /** candidate List on error */
  // const onError = ({ response: { data: errData } }: any) => {
  //   notify({
  //     alertHeader: errData.messageTitle ? errData.messageTitle : '',
  //     alertBody: errData.message ? errData.message : 'Error!',
  //     status: 'Error',
  //   })
  // }

  /** API Handling request for Get All Police Check History List */
  // const {
  //   refetch: getTableData,
  //   isLoading: getPoliceCheckHistoryTableIsLoading,
  //   isFetching: getPoliceCheckHistoryTableIIsFetching,
  // } = useGetDashboardDetailsForTable(
  //   {
  //     take: paginationVariables.take,
  //     skip: paginationVariables.skip,
  //     sortColumnName: paginationVariables.sortColumnName,
  //     sortDirection: paginationVariables.sortDirection,
  //   },
  //   onSuccess,
  //   onError,
  // )

  // useEffect(() => {
  //   isActive && getTableData()
  // }, [paginationVariables, isActive])

  /** policeCheck Table Column Handling */
  const tableColumns = useMemo(() => {
    if (
      tableData?.PoliceCheckHistory &&
      tableData?.PoliceCheckHistory?.length > 0
    ) {
      const columns: any = PoliceCheckHistoryListColumn()
      return columns
    }
    return []
  }, [tableData])

  const CandidateListClasses = CN(
    `police-check-history-list w-full h-full`,
    className,
  )

  return (
    <div className={CandidateListClasses} {...restProps}>
      <div className='w-full h-[calc(60vh-80px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.PoliceCheckHistory || []}
            // isLoading={
            //   getPoliceCheckHistoryTableIsLoading ||
            //   getPoliceCheckHistoryTableIIsFetching
            // }
            className=''
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            isManualSortBy={true}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}
