import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { useGetDashboardData } from 'framework/api/methods'

import { PageLoader } from 'components/atoms'
import { DashboardDetailModal, DashboardReportCard } from 'components/molecules'

type ReportingProps = {
  [x: string]: any
}

type ReportingDetailModalType = {
  isActive: boolean
  status: any
  module: any
  title: any
  [x: string]: any
}

export const Reporting = ({ className, ...restProps }: ReportingProps) => {
  const DashboardClasses = CN('dashboard flex w-full h-4/5', className, {})
  const [dashboardDetailModal, setDashboardDetailModal] =
    useState<ReportingDetailModalType>({
      isActive: false,
      module: '',
      status: '',
      title: '',
    })

  const [candidateRefereeRequestsData, setCandidateRefereeRequestsData] =
    useState<any>()
  const [refereeData, setRefereeData] = useState<any>()
  const [fullProfileMetricsData, setFullProfileMetricsData] = useState<any>()

  const { dateRange } = useSelector((state: any) => state.dashboard)

  //get dashboard data
  const {
    data: dashboardData,
    refetch: getDashboardData,
    isLoading: getDashboardDataIsLoading,
  } = useGetDashboardData(dateRange[0], dateRange[1])

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      getDashboardData()
    }
  }, [dateRange])

  const {
    candidateCard,
    refereeCard,
    fullMetricsCard,
    fraudDetectedCount,
    avgTimeCompletedRefCheck,
  } = dashboardData?.dashboard || {}

  /** put this method inside get API on success */
  const updateChartsData = () => {
    setCandidateRefereeRequestsData([
      {
        title: 'Completed Referee Details Requests',
        value: candidateCard?.completed || 0,
        color: '#054A8B',
        tooltip: 'Completed Referee Details Requests',
        module: 'Candidate',
        status: 'Completed',
      },
      {
        title: 'Pending Referee Details Requests',
        value: candidateCard?.pending || 0,
        color: '#4191DC',
        tooltip: 'Pending Referee Details Requests',
        module: 'Candidate',
        status: 'Pending',
      },
      {
        title: 'Expired Referee Details Requests',
        value: candidateCard?.expired || 0,
        color: '#F28680',
        tooltip: 'Expired Referee Details Requests',
        module: 'Candidate',
        status: 'Expired',
      },
    ])

    setRefereeData([
      {
        title: 'Completed Reference Requests',
        value: refereeCard?.completed || 0,
        color: '#054A8B',
        tooltip: 'Completed Reference Requests',
        module: 'Referee',
        status: 'Completed',
      },
      {
        title: 'Pending Reference Requests',
        value: refereeCard?.pending || 0,
        color: '#F4AF5A',
        tooltip: 'Completed Reference Requests',
        module: 'Referee',
        status: 'Pending',
      },
      {
        title: 'Outstanding Phone Ref Requests',
        value: refereeCard?.phoneRef || 0,
        color: '#4191DC',
        tooltip: 'Outstanding Phone Ref Requests',
        module: 'Referee',
        status: 'Outstanding',
      },
      {
        title: 'Reference Requests Declined',
        value: refereeCard?.declined || 0,
        color: '#F28680',
        tooltip: 'Reference Requests Declined',
        module: 'Referee',
        status: 'Declined',
      },
    ])

    setFullProfileMetricsData([
      {
        title: 'Completed Profiles',
        value: fullMetricsCard?.completed || 0,
        color: '#054A8B',
        tooltip: 'Completed Profiles',
        module: 'Profile',
        status: 'Completed',
      },
      {
        title: 'Pending Profiles',
        value: fullMetricsCard?.pending || 0,
        color: '#4191DC',
        tooltip: 'Pending Profiles',
        module: 'Profile',
        status: 'Pending',
      },
      {
        title: 'Passed Profiles',
        value: fullMetricsCard?.pass || 0,
        color: '#F4AF5A',
        tooltip: 'Passed Profiles',
        module: 'Profile',
        status: 'Passed',
      },
      {
        title: 'Failed Profiles',
        value: fullMetricsCard?.fail || 0,
        color: '#F28680',
        tooltip: 'Failed Profiles',
        module: 'Profile',
        status: 'Failed',
      },
    ])
  }

  useEffect(() => {
    updateChartsData()
  }, [dashboardData])

  return (
    <div className={DashboardClasses} {...restProps}>
      {getDashboardDataIsLoading && <PageLoader size='xs' />}
      <div className='grid grid-cols-2 w-full gap-4 h-full'>
        <DashboardReportCard
          index={1}
          title='Candidate Referee Requests'
          toolTipDescription='Referee Requested date will be considered when filter by date'
          data={candidateRefereeRequestsData || []}
          totalCount={candidateCard?.candidateCount}
          dashboardDetailModal={dashboardDetailModal}
          setDashboardDetailModal={setDashboardDetailModal}
        />

        <DashboardReportCard
          index={2}
          title='Referees'
          toolTipDescription='Candidate creation date will be considered when filter by date'
          data={refereeData || []}
          totalCount={refereeCard?.refereeCount}
          dashboardDetailModal={dashboardDetailModal}
          setDashboardDetailModal={setDashboardDetailModal}
        />

        <DashboardReportCard
          index={3}
          title='Full Profile Metrics'
          toolTipDescription='Candidate creation date will be considered when filter by date'
          isFullCircle
          data={fullProfileMetricsData || []}
          dashboardDetailModal={dashboardDetailModal}
          setDashboardDetailModal={setDashboardDetailModal}
        />

        <div className='grid grid-row-2 w-full gap-y-4 h-auto'>
          <DashboardReportCard
            index={4}
            title='Fraud Detection'
            toolTipDescription='Ref Check sent date will be considered when filter by date'
            fraudDetection={fraudDetectedCount || 0}
            isDetailedCard={false}
            dashboardDetailModal={dashboardDetailModal}
            setDashboardDetailModal={setDashboardDetailModal}
          />

          <DashboardReportCard
            index={5}
            title='Average time to complete Ref check'
            toolTipDescription='Ref Check sent date will be considered when filter by date'
            AverageRefCheckTime={avgTimeCompletedRefCheck || 0}
            isDetailedCard={false}
            dashboardDetailModal={dashboardDetailModal}
            setDashboardDetailModal={setDashboardDetailModal}
          />
        </div>

        <DashboardDetailModal
          isActive={dashboardDetailModal?.isActive}
          dashboardDetailModal={dashboardDetailModal}
          setDashboardDetailModal={setDashboardDetailModal}
          fromDate={dateRange[0]}
          toDate={dateRange[1]}
        />
      </div>
    </div>
  )
}
