import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from '@labourhub/labour-hub-ds'
import { useGetActiveSubscriptions } from 'api//subscriptions'
import { setAgency } from 'features/agencies/store/actions'
import { CardBillingAddressDto, PaymentErrorType } from 'features/payments'
import { setSubscriptions } from 'features/subscriptions'
import { SubscriptionPlanPackageBadge } from 'features/subscriptions/components/SubscriptionPlanPackageBadge'
import { useGetAgencyDetails } from 'framework/api/methods'

import { dateToReadableString } from 'utils'

import { AmountSummary, InvoiceDto } from '../types'

import { FixedInvoiceNotificationBox } from './FixedInvoiceNotificationBox'
import { InvoiceTemplateModal } from './InvoiceTemplateModal'

type FixedSubscriptionPaymentInvoiceModalProps = {
  isActive: boolean
  setIsActive: any
  isSuccess?: boolean
  cardBillingAddress?: CardBillingAddressDto
  invoice: InvoiceDto | null
  paymentError?: PaymentErrorType
  onCloseAction?: () => void
}

export const FixedSubscriptionPaymentInvoiceModal = ({
  isActive,
  setIsActive,
  isSuccess = true,
  cardBillingAddress,
  invoice,
  paymentError,
  onCloseAction,
}: FixedSubscriptionPaymentInvoiceModalProps) => {
  const dispatch = useDispatch()

  const [invoiceTemplateModal, setInvoiceTemplateModal] = useState(false)

  // fetch active subscriptions
  const { refetch: refetchSubscriptions } = useGetActiveSubscriptions(
    (subscriptions) => {
      dispatch(setSubscriptions(subscriptions))
    },
  )

  useEffect(() => {
    const onboardingToken = sessionStorage.getItem('onboardingToken')
    if (onboardingToken) {
      refetchSubscriptions()
    }
  }, [isActive])

  const [agencyProfileDetails, setAgencyProfileDetails] = useState<any>({})

  const amountSummary: AmountSummary = {
    subTotal: invoice?.totalSubTotalAmount ?? 0,
    promotionAmount: invoice?.totalPromotionAmount ?? 0,
    tax: invoice?.totalTaxAmount ?? 0,
    totalAmount: invoice?.totalPaymentAmount ?? 0,
  }

  const getAgencyDetailsOnSuccess = ({ data: successData }: any) => {
    setAgencyProfileDetails(successData?.agencyProfile)
    dispatch(setAgency(successData?.agencyProfile))
  }

  /** Get agency profile details on error */
  const getAgencyDetailsOnError = () => {
    //
  }

  /** API call for getting agency profile details */
  const { refetch: getAgencyProfileDetailsRefetch } = useGetAgencyDetails(
    {},
    getAgencyDetailsOnSuccess,
    getAgencyDetailsOnError,
  )

  useEffect(() => {
    isActive && getAgencyProfileDetailsRefetch()
  }, [isActive])

  return (
    <>
      <Modal
        isActive={isActive}
        onClickPrimaryBtn={() => {
          isSuccess && setInvoiceTemplateModal(true)
        }}
        onHeaderCloseButtonClick={() => {
          setIsActive(false)
          onCloseAction && onCloseAction()
        }}
        onClickSecondaryBtn={() => {
          setIsActive(false)
          onCloseAction && onCloseAction()
        }}
        onOverlayClick={() => {
          setIsActive(false)
          onCloseAction && onCloseAction()
        }}
        isFooterShow={true}
        modalProps={{
          style: { width: '600px' },
        }}
        primaryButtonProps={{
          theme: 'cobalt',
          iconLeft: isSuccess && <i className='ri-download-line'></i>,
        }}
        secondaryButtonProps={{
          textTheme: 'black',
        }}
        footerButtonAlignment={isSuccess ? 'left' : 'right'}
        headerTitle={isSuccess ? 'Payment Successful' : 'Payment Failed '}
        primaryButtonTitle={isSuccess ? 'Download PDF' : ''}
        secondaryButtonTitle='Close'>
        <div className='flex w-full h-full flex-col justify-start items-center max-h-[80vh] min-h-[20vh] py-9 px-[80px]'>
          <FixedInvoiceNotificationBox
            isSuccess={isSuccess}
            message={
              isSuccess
                ? 'Thank you for your payment'
                : 'Unable to process your payment'
            }
            paymentError={paymentError}
          />

          {isSuccess && (
            <div className='flex-col flex-1 w-full mt-8 overflow-y-auto h-fit styled-scroll'>
              {invoice?.subscription?.packageName && (
                <div className='flex items-center justify-between w-full px-2 py-4 border-b border-b-Gray-200'>
                  <div className='text-base'>Purchased Pack</div>
                  <SubscriptionPlanPackageBadge
                    isFill={true}
                    packageName={invoice?.subscription?.packageName}
                  />
                </div>
              )}

              <div className='flex items-center justify-between w-full px-2 py-4 border-b border-b-Gray-200'>
                <div className='text-base'>Invoice ID</div>
                <div className='text-base font-Medium'>
                  #{invoice?.invoiceNumber}
                </div>
              </div>

              <div className='flex items-center justify-between w-full px-2 py-4 border-b border-b-Gray-200'>
                <div className='text-base'>Amount Paid</div>
                <div className='text-base font-Medium'>
                  ${invoice?.totalPaymentAmount.toFixed(2)}
                </div>
              </div>

              <div className='flex items-center justify-between w-full px-2 py-4 border-b border-b-Gray-200'>
                <div className='text-base'>Subscription</div>
                <div className='text-base font-Medium'>
                  {invoice?.subscription?.subscriptionType}
                </div>
              </div>

              <div className='flex items-center justify-between w-full px-2 py-4 border-b border-b-Gray-200'>
                <div className='text-base'>Payment Date</div>
                <div className='text-base font-Medium'>
                  {invoice?.createdOn
                    ? dateToReadableString(invoice?.createdOn)
                    : 'NA'}
                </div>
              </div>

              {invoice?.paymentType && (
                <div className='flex items-center justify-between w-full px-2 py-4 border-b border-b-Gray-200'>
                  <div className='text-base'>Payment Type</div>
                  <div className='text-base font-Medium'>
                    {invoice?.paymentType}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>

      <InvoiceTemplateModal
        isActive={invoiceTemplateModal}
        setClose={setInvoiceTemplateModal}
        invoice={invoice}
        cardBillingAddress={cardBillingAddress}
        amountSummary={amountSummary}
        agencyProfileDetails={agencyProfileDetails}
      />
    </>
  )
}
