import { useState } from 'react'
import {
  QuestionnaireCategoryType,
  QuestionnaireLibraryDto,
  useGetQuestionnaireLibraryList,
} from 'api/questionnaires'

import { BuildNewTemplateCard } from './BuildNewTemplateCard'
import { ChooseTemplateCard } from './ChooseTemplateCard'
import { CustomSurveyCardItem } from './CustomSurveyCardItem'

type CustomSurveysTabProps = {
  [x: string]: any
  candidateDetails: any
  getCandidateById: () => void
}
export const CustomSurveysTab = ({
  candidateId,
  candidateDetails,
  getCandidateById,
}: CustomSurveysTabProps) => {
  const [surveys, setSurveys] = useState<QuestionnaireLibraryDto[]>([])
  const { refetch } = useGetQuestionnaireLibraryList(
    {
      search: '',
      type: candidateDetails?.isEmployee
        ? QuestionnaireCategoryType.EmployeeSurvey
        : QuestionnaireCategoryType.CandidateSurvey,
      cId: candidateId,
    },
    (data) => setSurveys(data),
  )
  return (
    <div className='bg-white p-6'>
      <div className='grid grid-cols-3 gap-4'>
        <ChooseTemplateCard
          getCandidateById={getCandidateById}
          candidateDetails={candidateDetails}
        />

        <BuildNewTemplateCard
          getCandidateById={getCandidateById}
          candidateDetails={candidateDetails}
        />

        {surveys.map((survey, index) => (
          <CustomSurveyCardItem
            key={index}
            survey={survey}
            getCandidateById={getCandidateById}
            refetch={refetch}
          />
        ))}
      </div>
    </div>
  )
}
