import { useState } from 'react'
import { Radio, TextArea } from '@labourhub/labour-hub-ds'
import { CancelRequestDto } from 'api/subscriptions'
import cancelImage from 'assets/images/subscription/cancelImage.svg'

import { Column } from 'components/atoms/Column'
import { H5 } from 'components/atoms/Headings'
import { RowWithAlignment } from 'components/atoms/Row'

type ConsideringACancelingFormProps = {
  key: any
  state: CancelRequestDto
  setState: any
}

export const ConsideringACancelingForm = ({
  key,
  state,
  setState,
}: ConsideringACancelingFormProps) => {
  return (
    <RowWithAlignment
      justifyAlignment='center'
      className='flex flex-col p-5 px-7'>
      <img src={cancelImage} alt={'cancel'} className='object-fill h-[125px]' />

      <Column>
        <H5 title='We Value Your Feedback' />
        <span className='text-small'>
          We're sorry to see you've canceled your plan. To help us improve our
          services, we'd appreciate to know the reason behind your decision.
        </span>

        <Column className='mx-3 mt-6 space-y-3 text-base' key={key}>
          <Radio
            id='Switched to another reference checking software'
            labelText='Switched to another reference checking software'
            helperText=''
            name='reason'
            onChange={(e: any) => {
              setState({
                ...state,
                cancellationReason: e.target.id,
              })
            }}
          />

          <Radio
            id='Missing specific features I need'
            labelText='Missing specific features I need'
            helperText=''
            name='reason'
            onChange={(e: any) => {
              setState({
                ...state,
                cancellationReason: e.target.id,
              })
            }}
          />
          <Radio
            id='Budget constraints or financial reasons'
            helperText=''
            labelText='Budget constraints or financial reasons'
            name='reason'
            onChange={(e: any) => {
              setState({
                ...state,
                cancellationReason: e.target.id,
              })
            }}
          />
          <Radio
            id='Temporary pause in recruitment activities'
            labelText='Temporary pause in recruitment activities'
            helperText=''
            name='reason'
            onChange={(e: any) => {
              setState({
                ...state,
                cancellationReason: e.target.id,
              })
            }}
          />

          <Radio
            id='Other'
            labelText='Other'
            helperText=''
            name='reason'
            onChange={(e: any) => {
              setState({
                ...state,
                cancellationReason: e.target.id,
              })
            }}
          />

          <TextArea
            className='w-full'
            value={state.comment}
            onChange={(e: any) => {
              setState({
                ...state,
                comment: e.target.value,
              })
            }}
            label=''
            placeholder='Add a comment'
            rows={4}
          />
        </Column>
      </Column>
    </RowWithAlignment>
  )
}
