import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type GetCandidateDetailsByIdResponse = {
  basicCandidateProfile: CandidateProfileBasicDetailsType
  messageTitle: string | null
  message: string
}

export type CandidateProfileBasicDetailsType = {
  candidateId: string
  name: string
  candidateNo: string
  email: string
  phone: string
  jobTitle: string
  refereeCount: number | any
  remainingRefereeCount: number
  lastSendRequest: string
  requestSendDate: string
  avatarUrl: string
  passCount: number
  pendingCount: number
  failedCount: number
  percentage: string
  ipAddress: string
  consultantName: string
  isMarkAsPass?: boolean | null
  markedStatus?: string | null
  isEmployee: boolean
  responseStatsCount?: CandidateRefereesResponseStatsDto
  positiveSentiment: number
  negativeSentiment: number
  neutralSentiment: number
  surveyResponseStatsCount: CandidateSurveyResponseStatsDto
}

type CandidateRefereesResponseStatsDto = {
  receivedCount: number
  declinedCount: number
}

export type CandidateSurveyResponseStatsDto = {
  receivedCount: number
  awaitingCount: number
  declinedCount: number
  totalCount: number
}

export const useGetCandidateBasicDetailsById = (candidateId: string) => {
  return useQuery(
    ['GET_CANDIDATE_DETAILS_BY_CANDIDATE_ID', candidateId],
    async () => {
      if (candidateId !== '') {
        const data = await get(`facade/Candidate/${candidateId}/GetCard`)
        return (data?.data as GetCandidateDetailsByIdResponse) || []
      }
    },
    {
      onError: () => {
        Toast({
          alertHeader: 'Error fetching candidate profile card',
          status: 'Error',
        })
      },
      refetchOnWindowFocus: false,
    },
  )
}
