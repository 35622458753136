import { ReportSettingItemKey } from 'api/settings/report'

export const reportAccordionSettings = [
  {
    heading: 'Customize Report',
    description: 'Customize the report to match your style and brand.',
    checkboxes: [
      {
        id: ReportSettingItemKey.BackgroundImage,
        labelText: 'Background Image',
        style: 'flex items-center justify-between',
      },
      {
        id: ReportSettingItemKey.TextColour,
        labelText: 'Text Colour',
        style: 'flex items-center justify-between',
      },
      {
        id: ReportSettingItemKey.LineColour,
        labelText: 'Line Colour',
        style: 'flex items-center justify-between',
      },
    ],
  },
  {
    heading: 'Referee Privacy Settings',
    description:
      'Customize referee details included in your reports. Please note that it is your responsibility to obtain permission from the referee to share their information.',
    checkboxes: [
      {
        id: ReportSettingItemKey.PrivateReportsEmailPhone,
        labelText:
          'Private Reports - Do not include referee email and phone number in the reports',
      },
      {
        id: ReportSettingItemKey.PrivateReportsName,
        labelText:
          'Private Reports - Do not include referee name in the reports',
      },
    ],
  },
]
