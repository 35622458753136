import {
  setAIQuestionDataState,
  setFilterVariablesState,
  setIsRefreshListState,
  setSurveyTypeState,
  setTotalQuestionnairesState,
} from './reducer'

export const setTotalQuestionnaires =
  (value: number, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setTotalQuestionnairesState(value))
    cb()
  }

export const setFilterVariables =
  (filterVariables: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setFilterVariablesState(filterVariables))
    cb()
  }

export const setAIQuestionData =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAIQuestionDataState(data))
    cb()
  }

export const refreshQuestionnaireList =
  (cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsRefreshListState())
    cb()
  }
export const setSurveyType =
  (surveyType: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setSurveyTypeState(surveyType))
    cb()
  }
