import React, { useEffect, useRef, useState } from 'react'
import { Badge, Modal } from '@labourhub/labour-hub-ds'
import { SurveyResponseDto } from 'api/candidates'
import { QuestionnaireCategoryType } from 'api/questionnaires'
import { CandidateProfileBasicDetailsType } from 'framework/api/methods'
import { useDynamicFieldMapping } from 'hooks'
import { ModalProps } from 'types'

import { FormBuilder } from 'components/molecules'
import { capitalizeAndExtractFirstLetter } from 'utils'

import { CandidateReportDownloadModal } from './CandidateReportDownloadModal'

type SurveyResponseDetailsPreviewModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  selectedResponse: SurveyResponseDto
}

export const SurveyResponseDetailsPreviewModal = ({
  isModalActive,
  setIsModalActive,
  candidateDetails,
  selectedResponse,
}: SurveyResponseDetailsPreviewModalProps) => {
  const { changeJsonSchema } = useDynamicFieldMapping({
    candidateId: candidateDetails.candidateId,
  })

  const componentRef = useRef<HTMLDivElement | null>(null)

  const adjustTextFieldHeight = () => {
    const textInputs = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('input[type="text"]')

    if (textInputs) {
      textInputs.forEach((input) => {
        if (input instanceof HTMLInputElement) {
          // Check if a div with the same id already exists in the parent node
          const existingDiv = input?.parentNode?.querySelector(
            `#custom-div-${input.id}`,
          )

          if (!existingDiv) {
            // Create a new div element
            const newDiv = document.createElement('div')

            // Get the value of the input
            const inputValue = input.value

            // Set the div's content to the input value
            newDiv.textContent = inputValue
            newDiv.className = 'custom-div'

            // Set the div's id to match the input's id
            newDiv.id = `custom-div-${input.id}`

            // Prepend the div to the input's parent element
            input?.parentNode?.insertBefore(newDiv, input)

            input.style.display = 'none'
          }
        }
      })
    }
  }

  const adjustTextAreaHeight = (heightRatio = 1) => {
    const textareas = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('textarea')

    if (textareas) {
      textareas.forEach((textarea) => {
        if (textarea instanceof HTMLTextAreaElement) {
          textarea.style.height = 'auto'
          textarea.style.height = `${textarea.scrollHeight * heightRatio}px`
        }
      })
    }
  }

  useEffect(() => {
    if (isModalActive && componentRef.current) {
      adjustTextAreaHeight()
      adjustTextFieldHeight()
    }
  }, [isModalActive, selectedResponse, componentRef.current])

  const [
    isCandidateReportDownloadModalActive,
    setIsCandidateReportDownloadModalActive,
  ] = useState(false)

  const SurveyResponseReport = React.forwardRef((props, ref) => {
    return (
      <div className='flex flex-col justify-start w-full p-5 overflow-y-auto'>
        <div className='flex items-start p-4 border rounded-lg bg-Gray-50 border-Gray-200'>
          <div className='flex items-center mr-4 avatar-div'>
            <div
              className={
                'flex justify-center items-center rounded-full font-Regular pointer-events-none w-10 h-10 text-heading-5 bg-NavyBlue text-white'
              }>
              {capitalizeAndExtractFirstLetter(candidateDetails.name)}
            </div>
          </div>

          <div className='flex flex-col w-full'>
            <div className='divide-y divide-Gray-300'>
              <div className='flex flex-col items-start mb-3'>
                <div className='flex items-center mb-1'>
                  <span className='text-Gray-800 font-Medium text-[18px] mr-2'>
                    {candidateDetails?.name}
                  </span>
                  <div className='py-[2px] px-[10px]'>
                    {selectedResponse?.surveyTemplateType ===
                    QuestionnaireCategoryType.EmployeeSurvey ? (
                      <Badge
                        size='small'
                        theme='purple'
                        type='basic'
                        isIcon={true}
                        icon='ri-user-follow-line'>
                        Employee
                      </Badge>
                    ) : (
                      <Badge
                        size='small'
                        theme='primary'
                        type='basic'
                        isIcon={true}
                        icon='ri-user-line'>
                        Candidate
                      </Badge>
                    )}
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center mb-1 mr-5 min-w-fit'>
                    <i className='ri-phone-line text-Gray-500 mr-[10px] text-extra-small' />
                    <span className='text-Gray-600 font-Medium '>
                      {candidateDetails?.phone}
                    </span>
                  </div>
                  <div className='flex items-center mb-1'>
                    <i className='ri-mail-line text-Gray-500 mr-[10px] text-extra-small' />
                    <span className='text-Gray-600 font-Medium '>
                      {candidateDetails?.email}
                    </span>
                  </div>
                </div>

                <div className='flex items-center mb-1'>
                  <i className='ri-suitcase-line text-Gray-500 mr-[10px] text-extra-small' />
                  <span className='text-Gray-600 font-Medium '>
                    Managing consultant is {candidateDetails?.consultantName}
                  </span>
                </div>

                {selectedResponse?.surveyTemplateType ===
                QuestionnaireCategoryType.EmployeeSurvey ? null : (
                  <div className='flex items-center mb-1'>
                    <i className='ri-global-line text-Gray-500 mr-[10px] text-extra-small' />
                    <span className='text-Gray-600 font-Medium '>
                      Applying role of {candidateDetails?.jobTitle}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div ref={ref as React.RefObject<HTMLDivElement>} className='p-4 mt-2'>
          {selectedResponse?.jsonSchema &&
            selectedResponse?.jsonData &&
            selectedResponse?.jsonUiSchema && (
              <FormBuilder
                schema={changeJsonSchema(
                  JSON.parse(selectedResponse?.jsonSchema),
                )}
                formData={JSON.parse(selectedResponse?.jsonData)}
                uiSchema={JSON.parse(selectedResponse?.jsonUiSchema)}
                readonly={true}
              />
            )}
        </div>
      </div>
    )
  })

  return (
    <>
      <Modal
        isActive={isModalActive}
        headerTitle={`Detailed ${
          selectedResponse?.surveyTemplateType ===
          QuestionnaireCategoryType.EmployeeSurvey
            ? 'Employee'
            : 'Candidate'
        } Survey`}
        onHeaderCloseButtonClick={() => setIsModalActive(false)}
        onClickPrimaryBtn={() => setIsCandidateReportDownloadModalActive(true)}
        onClickSecondaryBtn={() => setIsModalActive(false)}
        onOverlayClick={() => setIsModalActive(false)}
        isFooterShow={true}
        footerProps={{
          style: {
            justifyContent: 'end',
          },
        }}
        modalProps={{
          style: { width: '1125px', maxHeight: '700px' },
        }}
        primaryButtonProps={{
          style: {
            marginLeft: '12px',
          },
          iconLeft: <i className='ri-download-line'></i>,
        }}
        secondaryButtonProps={{
          textTheme: 'black',
        }}
        primaryButtonTitle='Download Report'
        secondaryButtonTitle='Cancel'>
        <SurveyResponseReport ref={componentRef} />
      </Modal>

      <CandidateReportDownloadModal
        isModalActive={isCandidateReportDownloadModalActive}
        setIsModalActive={setIsCandidateReportDownloadModalActive}
        selectedResponse={selectedResponse}
        candidateDetails={candidateDetails}
      />
    </>
  )
}
