import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import {
  ReportSettingItem,
  ReportSettingItemKey,
  useGetReportSettings,
  useUpdateReportSettings,
} from 'api/settings/report'
import { setCurrentStep } from 'features/onboarding/store'

import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { MiniReferenceReportPreview } from 'components/ui/reports/reference-report'

import { CompanyDetailsCard } from './CompanyDetailsCard'

import './style.scss'

type ViewCompanyProfileProps = {
  setIsEditView: any
}
export const ViewCompanyProfile = ({
  setIsEditView,
}: ViewCompanyProfileProps) => {
  const dispatch = useDispatch()
  const notify = (props: any) => Toast(props)

  const [selectedTextColor, setSelectedTextColor] = useState('#1E75C6')
  const [selectedLineColor, setSelectedLineColor] = useState('#1E75C6')

  const [formBody, setFormBody] = useState<ReportSettingItem[]>([
    {
      id: ReportSettingItemKey.TextColour,
      isChecked: true,
      value: '#1E75C6',
    },
    {
      id: ReportSettingItemKey.LineColour,
      isChecked: true,
      value: '#1E75C6',
    },
  ])

  const reportAccordionSettings = [
    {
      heading: 'Customize Report',
      description: 'Customize the report to match your style and brand.',
      checkboxes: [
        {
          id: ReportSettingItemKey.TextColour,
          labelText: 'Text Colour',
          style: 'flex items-center justify-between',
        },
        {
          id: ReportSettingItemKey.LineColour,
          labelText: 'Line Colour',
          style: 'flex items-center justify-between',
        },
      ],
    },
  ]

  useGetReportSettings((data) => {
    data?.reportSettings?.forEach((setting) => {
      if (setting.id == ReportSettingItemKey.TextColour)
        setSelectedTextColor(setting.value || '#1E75C6')
      else if (setting.id == ReportSettingItemKey.LineColour)
        setSelectedLineColor(setting.value || '#1E75C6')
    })
  })

  const updateFormBody = (id: ReportSettingItemKey, value: any) => {
    setFormBody((prevFormBody) =>
      prevFormBody?.map((setting) =>
        setting.id === id ? { ...setting, value: value } : setting,
      ),
    )
  }

  const getIsChecked = (id: ReportSettingItemKey) => {
    const setting = formBody?.find((item) => item.id === id)
    return setting ? setting.isChecked : false
  }

  const getValue = (checkboxId: ReportSettingItemKey) => {
    const setting = formBody?.find((item) => item.id === checkboxId)
    return setting ? setting.value : null
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        selectedTextColor !== null &&
        getValue(ReportSettingItemKey.TextColour) != selectedTextColor
      )
        updateFormBody(
          ReportSettingItemKey.TextColour,
          getIsChecked(ReportSettingItemKey.TextColour)
            ? selectedTextColor
            : '#1E75C6',
        )
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [selectedTextColor, formBody])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        selectedLineColor !== null &&
        getValue(ReportSettingItemKey.LineColour) != selectedLineColor
      )
        updateFormBody(
          ReportSettingItemKey.LineColour,
          getIsChecked(ReportSettingItemKey.LineColour)
            ? selectedLineColor
            : '#1E75C6',
        )
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [selectedLineColor, formBody])

  const { mutate, isLoading: isSaving } = useUpdateReportSettings()

  const saveReportSettings = () => {
    mutate(
      { reportSettings: formBody },
      {
        onSuccess: () => {
          dispatch(setCurrentStep(3))
          notify({
            alertHeader: 'Report settings saved successfully!',
            status: 'Success',
          })
        },
        onError: () => {
          notify({
            alertHeader: 'Error while saving report settings',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className='flex flex-col w-full px-6'>
      {/* {(isFetching || isLoading || isLoadingReport || isFetchingReport) && (
        <PageLoader size='xxs' />
      )} */}

      <div className='mt-3 mb-4 text-small font-SemiBold text-Gray-800'>
        Company Profile
      </div>

      <CompanyDetailsCard setIsEditView={setIsEditView} />

      <div className='mt-5 text-small font-SemiBold text-Gray-800'>
        Customize Style
      </div>

      <div className='my-2 text-extra-small font-Regular text-Gray-500'>
        Customize the report to match your style and brand.
      </div>

      <div className='mt-1 mb-3 border border-Gray-200'></div>

      <RowWithAlignment className='mt-3'>
        <div className='w-[66%]'>
          {reportAccordionSettings.map((accordion, index) => (
            <Column key={index} className='w-full space-y-4'>
              {accordion.checkboxes.map((checkbox) => (
                <Column key={checkbox.id}>
                  <label
                    htmlFor={checkbox.labelText}
                    className='text-Gray-800 text-extra-small'>
                    {checkbox.labelText}
                  </label>

                  {checkbox.id == ReportSettingItemKey.TextColour && (
                    <input
                      type='color'
                      className='my-1 color-picker'
                      value={selectedTextColor}
                      onChange={(e) => setSelectedTextColor(e.target.value)}
                    />
                  )}

                  {checkbox.id == ReportSettingItemKey.LineColour && (
                    <input
                      type='color'
                      className='my-1 color-picker'
                      value={selectedLineColor}
                      onChange={(e) => setSelectedLineColor(e.target.value)}
                    />
                  )}
                </Column>
              ))}
            </Column>
          ))}
        </div>
        <div className='w-[40%]'>
          <MiniReferenceReportPreview
            textColour={selectedTextColor}
            lineColour={selectedLineColor}
          />
        </div>
      </RowWithAlignment>

      <div className='flex flex-col justify-center mt-6'>
        <Button
          onClick={saveReportSettings}
          isLoading={isSaving}
          className='cursor-pointer'>
          Confirm
        </Button>
      </div>
    </div>
  )
}
