import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, SimpleSelect } from '@labourhub/labour-hub-ds'
import { CardBillingAddressDto } from 'api/payments'
import {
  useCreateOrUpgradeFixedSubscription,
  useDowndgradeFixedSubscription,
} from 'api/subscriptions'
import { PaymentErrorSetterType } from 'features/payments/types'
import { SubscriptionMode } from 'features/subscriptions'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { Row } from 'components/atoms/Row'

import { paymentIcons } from '../../../data'
import {
  getPaymentStore,
  toggleIsRefetchBillingHistory,
  toggleIsRefetchPaymentCards,
} from '../../../store'

import { CardBillingAddress } from './CardBillingAddress'

type CardOption = {
  id: string
  cardType: string
  label: string
  billingAddress?: CardBillingAddressDto
}

const newCard: CardOption = {
  id: 'new-card',
  cardType: 'blank',
  label: 'Pay from a new card',
}

type ExisitngPaymentFormProps = {
  packageId: string
  packageAmount: number
  subscriptionMode: SubscriptionMode
  setIsGoToExistingCard: any
  setInvoice: any
  setIsInvoiceModalActive: any
  setFixedSubscriptionPaymentModalActive: any
  setCardBillingAddress: any
  setPaymentError: PaymentErrorSetterType
}

export const ExisitngPaymentForm = ({
  packageId,
  packageAmount,
  subscriptionMode,
  setIsGoToExistingCard,
  setInvoice,
  setIsInvoiceModalActive,
  setFixedSubscriptionPaymentModalActive,
  setCardBillingAddress,
  setPaymentError,
}: ExisitngPaymentFormProps) => {
  const dispatch = useDispatch()
  const { isHeaderBarDetailsUpdated } = useSelector((state: any) => state.user)

  const { cards } = useSelector(getPaymentStore)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedCard, setSelectedCard] = useState(newCard)
  const [cardAddress, setCardAddress] = useState<
    CardBillingAddressDto | undefined
  >(undefined)

  const [isValidBillingAddress, setIsValidBillingAddress] = useState(false)

  const defaultCard = cards.find((card) => card.isDefaultCard)

  useEffect(() => {
    if (selectedCard.billingAddress) {
      const { firstName, lastName, lineOne, state, postCode } =
        selectedCard.billingAddress

      const isValid =
        firstName && lastName && lineOne && state && postCode ? true : false
      setIsValidBillingAddress(isValid)
    }
  }, [selectedCard])

  useEffect(() => {
    if (defaultCard) {
      setSelectedCard({
        id: defaultCard.id,
        cardType: defaultCard.cardType,
        label: `**** **** **** ${defaultCard.last4Digits}`,
        billingAddress: defaultCard.billingAddress,
      })
    } else {
      setSelectedCard(
        cards.length > 0
          ? {
              id: cards[0].id,
              cardType: cards[0].cardType,
              label: `**** **** **** ${cards[0].last4Digits}`,
              billingAddress: cards[0].billingAddress,
            }
          : newCard,
      )
    }
  }, [cards, defaultCard])

  const { mutate } = useCreateOrUpgradeFixedSubscription()
  const { mutate: mutateDowngrade } = useDowndgradeFixedSubscription()

  const handlePayment = () => {
    setInvoice(null)
    setIsLoading(true)
    if (selectedCard.id == newCard.id) {
      setIsGoToExistingCard(false)
    } else {
      if (subscriptionMode == SubscriptionMode.Downgrade) {
        mutateDowngrade(
          {
            packageId,
            cardDetails: {
              paymentCardId: selectedCard.id,
              isSaveMyCardChecked: false,
              billingAddress: cardAddress,
            },
          },
          {
            onSuccess: ({ data }: any) => {
              setInvoice(data.invoice)
              setCardBillingAddress(data.billingAddress)
              dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
              dispatch(toggleIsRefetchBillingHistory())
              dispatch(toggleIsRefetchPaymentCards())
            },
            onError: ({ response: { data: errData } }: any) => {
              setPaymentError({
                errorCode: 'downgrade',
                message: errData?.message,
              })
              setInvoice(null)
            },
            onSettled: () => {
              setIsLoading(false)
              setIsInvoiceModalActive(true)
              setFixedSubscriptionPaymentModalActive(false)
            },
          },
        )
      } else {
        mutate(
          {
            packageId,
            subscriptionMode,
            cardDetails: {
              paymentCardId: selectedCard.id,
              isSaveMyCardChecked: false,
              billingAddress: cardAddress,
            },
          },
          {
            onSuccess: ({ data }: any) => {
              setInvoice(data.invoice)
              setCardBillingAddress(data.billingAddress)
              dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
              dispatch(toggleIsRefetchBillingHistory())
              dispatch(toggleIsRefetchPaymentCards())
            },
            onError: ({ response: { data: errData } }: any) => {
              setPaymentError({
                errorCode: 'upgrade',
                message: errData?.message,
              })
              setInvoice(null)
            },
            onSettled: () => {
              setIsLoading(false)
              setIsInvoiceModalActive(true)
              setFixedSubscriptionPaymentModalActive(false)
            },
          },
        )
      }
    }
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      'backgroundColor':
        state.data.id === selectedCard.id ? 'rgb(243 244 246)' : 'white',
      'color': 'black',
      '&:hover': {
        backgroundColor: 'rgb(243 244 246)',
        cursor: 'pointer',
      },
    }),
  }

  return (
    <>
      <div className='flex flex-col p-6 mt-6 bg-white border rounded-lg border-Gray-200'>
        <div className='text-[#414244] font-SemiBold'>Card Details</div>
        <Row className='p-1'>
          <div className='w-full'>
            <SimpleSelect
              label='Select payment method'
              className='mt-3 mb-1 w-full z-[100] '
              options={[
                newCard,
                ...cards.map((card) => ({
                  id: card.id,
                  cardType: card.cardType,
                  label: `**** **** **** ${card.last4Digits}`,
                  billingAddress: card.billingAddress,
                })),
              ]}
              styles={customStyles}
              components={{
                SingleValue: (props: any) => (
                  <div className='flex items-center justify-start space-x-2 -mt-7'>
                    <img src={paymentIcons[selectedCard.cardType]} alt='' />
                    <span className='text-Gray-500'> {props.children}</span>
                  </div>
                ),
              }}
              value={selectedCard}
              onChange={(card: any) => {
                if (card.id == newCard.id) {
                  setIsGoToExistingCard(false)
                }
                setSelectedCard(card)
              }}
            />
            <p className='text-Gray-500 text-extra-small'>
              By purchasing credits, you agree to our terms and conditions
            </p>
          </div>
        </Row>
      </div>

      <CardBillingAddress
        setIsValidBillingAddress={setIsValidBillingAddress}
        billingAddress={selectedCard.billingAddress}
        cardAddress={cardAddress}
        setCardAddress={setCardAddress}
      />

      <Button
        isLoading={isLoading}
        className='w-1/2 mt-6'
        onClick={handlePayment}
        textTheme='white'
        theme='cobalt'
        isDisabled={!isValidBillingAddress || packageAmount == 0}>
        Subscribe
      </Button>
    </>
  )
}
