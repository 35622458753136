import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type loginDto = {
  username: string
  password: string
  code?: string
}

export const useLogin = () => {
  const mutate = useMutation((dto: loginDto) => {
    return postAPI(`/identity/authenticate`, dto)
  })

  return mutate
}
