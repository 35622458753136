import { Rate } from 'components/atoms'
import { Column } from 'components/atoms/Column'

export const SampleQuestionnaireSummarySection = () => {
  return (
    <Column className='items-start justify-start w-full space-y-3'>
      <span className='mt-3 uppercase text-base text-[#085DA8] font-Medium'>
        REFEREE RESPONSES
      </span>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          1. How would you rate Brooklyn in Attendance?
        </p>
        <Rate rating={'5'} count={5} />
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          2. How would you rate Brooklyn in Reliability?
        </p>
        <Rate rating={'3'} count={5} />
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          3. How would you rate Brooklyn in Honesty?
        </p>
        <Rate rating={'4'} count={5} />
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          4. How would you rate Brooklyn in Initiative?
        </p>
        <Rate rating={'2'} count={5} />
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          5. How would you rate Brooklyn in Handling stress and conflicts?
        </p>
        <Rate rating={'4'} count={5} />
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          6. How would you rate Brooklyn in Overall Attitude?{' '}
        </p>
        <Rate rating={'5'} count={5} />
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          7. How would you rate Brooklyn in Fast paced work?{' '}
        </p>
        <Rate rating={'3'} count={5} />
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          8. What are his/her 3 best strengths?
        </p>
        <p className='ml-3 text-small'>
          <ul>
            <li>Confidence</li>
            <li>Neatness</li>
            <li>Driving</li>
          </ul>
        </p>
      </div>
      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          9. What are his/her weaknesses?
        </p>
        <p className='ml-3 text-small'>
          <ul>
            <li>Alcohol consumption</li>
            <li>Aggressive</li>
            <li>Sudden Sickness</li>
          </ul>
        </p>
      </div>

      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          10. What are his/her key accomplishments?
        </p>
        <p className='ml-3 text-small'>
          <ul>
            <li>Working in a factory environment</li>
            <li>Building the team work between members</li>
            <li>Salary increment</li>
            <li>Complement for the MD</li>
          </ul>
        </p>
      </div>

      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          11. What were the main duties and responsibilities carried out by
          (him/her) in their role
        </p>
        <p className='ml-3 text-small'>
          <ul>
            <li>Unloading and uploading Safe</li>
            <li>Arrangements of the stuff </li>
          </ul>
        </p>
      </div>

      <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          12. How would you describe (his/her) initiative on the job?
        </p>
        <p className='ml-3 text-small'>
          <ul>
            <li>Unloading and uploading Safe</li>
            <li>Arrangements of the stuff </li>
          </ul>
        </p>
      </div>

      {/* <div className='pb-4 space-y-2'>
        <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
          Signature
        </p>
        <div className='ml-3'>
          <img src={signatureIcon} alt='' className='h-[40px]' />
        </div>
      </div> */}
    </Column>
  )
}
