import { ReportCandidateSentimentDto } from 'api/executiveSummary'

import { Column, ColumnWithAlignment } from 'components/atoms/Column'
import { Row, RowWithAlignment } from 'components/atoms/Row'

type ReportSentimentStatsProps = {
  sentiment: ReportCandidateSentimentDto
}

export const ReportSentimentStats = ({
  sentiment: {
    positiveSentiment = 0,
    negativeSentiment = 0,
    neutralSentiment = 0,
  },
}: ReportSentimentStatsProps) => {
  return (
    <RowWithAlignment>
      <Column className='w-1/2'>
        <div className='w-[300px]'>
          <span className='pb-2 text-base font-Medium'>
            Reference Sentiment
          </span>
          <p className='text-Gray-500 text-extra-small'>
            This score is calculated automatically and represents feedback
            sentiment. This should only be considered as a guide.
          </p>
        </div>
      </Column>
      <ColumnWithAlignment
        className='w-1/2 ml-8 space-y-2'
        justifyAlignment='start'
        itemAlignment='start'>
        {positiveSentiment > 0 && (
          <Column>
            <span className='text-[9px] font-Regular text-[#979797]'>
              Positive
            </span>{' '}
            <Row className='items-center w-full -mt-1'>
              <span
                style={{ width: `${(positiveSentiment / 100) * 280}px` }}
                className={`h-[6px] bg-[#31BA55] rounded-[40px]`}
              />
              <span className='px-3 text-Gray-400 text-small'>
                {positiveSentiment.toFixed(0)}%
              </span>
            </Row>
          </Column>
        )}

        {negativeSentiment > 0 && (
          <Column>
            <span className='text-[9px] font-Regular text-[#979797]'>
              Negative
            </span>{' '}
            <Row className='items-center w-full -mt-1'>
              <span
                style={{ width: `${(negativeSentiment / 100) * 280}px` }}
                className={`h-[6px] bg-[#E57062] rounded-[40px]`}
              />
              <span className='px-3 text-Gray-400 text-small'>
                {negativeSentiment.toFixed(0)}%
              </span>
            </Row>
          </Column>
        )}

        {neutralSentiment > 0 && (
          <Column>
            <span className='text-[9px] font-Regular text-[#979797]'>
              Neutral
            </span>{' '}
            <Row className='items-center w-full -mt-1'>
              <span
                style={{ width: `${(neutralSentiment / 100) * 280}px` }}
                className={`h-[6px] bg-[#F9D452] rounded-[40px]`}
              />
              <span className='px-3 text-Gray-400 text-small'>
                {neutralSentiment.toFixed(0)}%
              </span>
            </Row>
          </Column>
        )}
      </ColumnWithAlignment>
    </RowWithAlignment>
  )
}
