import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge, BadgeTheme, Button } from '@labourhub/labour-hub-ds'
import { getAgency } from 'features/agencies/store/selectors'

import { Card } from 'components/atoms/Card'
import { capitalizeFirstLetter, dateToReadableString } from 'utils'

import { getSubscriptionStore } from '../store'
import {
  FixedSubscriptionPackageType,
  SubscriptionPlanType,
  SubscriptionType,
} from '../types'

import { CancelSubscriptionModal } from './CancelSubscriptionModal'
import { FixedSubscriptionPackagesModal } from './FixedSubscriptionPackagesModal'
import { SubscriptionPlanPackageBadge } from './SubscriptionPlanPackageBadge'

const badgeStyles: { theme: BadgeTheme; text: string }[] = [
  { theme: 'green', text: 'Active' },
  { theme: 'red', text: 'Inactive' },
]

export const ActiveSubscriptionDetailCard = () => {
  const [isFixedSubPackagesModalActive, setIsFixedSubPackagesModalActive] =
    useState(false)

  const [isCancelSubscriptionModalActive, setIsCancelSubscriptionModalActive] =
    useState(false)

  const { agency } = useSelector(getAgency)

  const { activeSubscriptions } = useSelector(getSubscriptionStore)
  // Check if 'fixedSubscription' exists and is not null
  const fixedSubscription = activeSubscriptions
    ? activeSubscriptions.fixedSubscription
    : null

  const styles =
    fixedSubscription && fixedSubscription.isActive
      ? badgeStyles[0]
      : badgeStyles[1]

  const packageName =
    fixedSubscription?.package.name ?? FixedSubscriptionPackageType.FifteenPack

  return (
    <>
      <Card>
        {fixedSubscription ? (
          <div className='mb-6 w-fit'>
            <SubscriptionPlanPackageBadge
              packageName={packageName}
              isFill={true}
              size='large'
            />
          </div>
        ) : (
          <div className='mb-6 w-fit'>
            <SubscriptionPlanPackageBadge
              packageName={SubscriptionPlanType.PAYG}
              isFill={true}
              size='large'
            />
          </div>
        )}

        <table className='w-full'>
          <tbody>
            <TableRow
              label='Sign Up Date'
              value={
                fixedSubscription?.startDate
                  ? dateToReadableString(fixedSubscription?.startDate)
                  : agency?.createdOn
                  ? dateToReadableString(agency?.createdOn)
                  : 'NA'
              }
            />
            <TableRow
              label={
                fixedSubscription?.package.subscriptionType ==
                SubscriptionType.Monthly
                  ? 'End Date'
                  : 'Plan Renews'
              }
              value={
                fixedSubscription?.endDate
                  ? dateToReadableString(fixedSubscription?.endDate)
                  : 'NA'
              }
            />
            <TableRow
              label='Subscription Type'
              value={
                !fixedSubscription
                  ? SubscriptionType.Unlimited
                  : fixedSubscription?.package.subscriptionType
                  ? capitalizeFirstLetter(
                      fixedSubscription.package.subscriptionType,
                    )
                  : 'NA'
              }
            />
            <TableRow label='Status'>
              {!fixedSubscription ? (
                <Badge size='small' theme='green'>
                  Active
                </Badge>
              ) : fixedSubscription?.isCanceled &&
                fixedSubscription.cancellationReason?.includes('Upgrade') ? (
                <Badge size='small' theme='yellow'>
                  Cancelled
                </Badge>
              ) : (
                <Badge size='small' theme={styles.theme}>
                  {styles.text}
                </Badge>
              )}
            </TableRow>
          </tbody>
        </table>

        <div className='flex flex-col w-full mt-5 space-y-3'>
          {fixedSubscription ? (
            <>
              <Button
                className='h-8'
                onClick={() => setIsFixedSubPackagesModalActive(true)}>
                Change Pack
              </Button>

              {!fixedSubscription.isCanceled && (
                <Button
                  theme='white'
                  textTheme='blue'
                  className='h-8'
                  onClick={() => setIsCancelSubscriptionModalActive(true)}>
                  Cancel Pack
                </Button>
              )}
            </>
          ) : (
            <Button
              className='h-8'
              onClick={() => setIsFixedSubPackagesModalActive(true)}>
              Buy Pack
            </Button>
          )}
        </div>
      </Card>

      <FixedSubscriptionPackagesModal
        isModalActive={isFixedSubPackagesModalActive}
        setIsModalActive={setIsFixedSubPackagesModalActive}
      />

      <CancelSubscriptionModal
        isModalActive={isCancelSubscriptionModalActive}
        setIsModalActive={setIsCancelSubscriptionModalActive}
      />
    </>
  )
}

type TabelRowProps = {
  label: string
  value?: string
  children?: React.ReactNode
}

const TableRow = ({ label, value, children }: TabelRowProps) => (
  <tr>
    <td className='text-Gray-600 font-Medium text-extra-small py-1.5'>
      {label}
    </td>
    {value && (
      <td className='text-Gray-800 font-SemiBold text-small'>{value}</td>
    )}
    {children && <td>{children}</td>}
  </tr>
)
