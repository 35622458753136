import { FC, useState } from 'react'
import CN from 'classnames'

import { PopOverMenu } from '../PopOverMenu'

export interface MoreMenuButtonProps {
  [x: string]: any
  actionButtons: Array<{
    iconClass?: string
    onClick: () => void
    title: string
    status: 'normal' | 'danger'
    disabled?: boolean
    isBottomSeparator?: boolean
  }>
  position?: any | undefined
  isOutlined?: boolean
  onClickMoreButton?: () => void | undefined
}

export const MoreMenuButton: FC<MoreMenuButtonProps> = ({
  className,
  actionButtons,
  isOutlined,
  position,
  onClickMoreButton,
  ...restProps
}: MoreMenuButtonProps) => {
  const MoreMenuButtonClasses = CN(
    'flex items-center justify-center box-border font-Medium p-[10px] rounded-md',
    className,
    { 'border border-Gray-300': isOutlined },
  )

  const [isPopOverOpen, setIsPopOverOpen] = useState(false)

  return (
    <PopOverMenu
      isOpen={isPopOverOpen}
      positions={[position]} // Stick in bottom position
      padding={10}
      onClickOutside={() => setIsPopOverOpen(false)} // Hide on outer click
      align={'end'} // Align at the end
      reposition={false} // Stop moving the popover
      content={() => (
        // Content of the popover menu
        <div className='flex flex-col rounded-[8px] z-20 bg-white shadow'>
          {/* Action buttons listed on popover menu */}
          {actionButtons.map(
            (
              {
                iconClass,
                onClick,
                status,
                title,
                disabled,
                isBottomSeparator,
              },
              idx,
            ) => (
              <button
                disabled={disabled}
                key={idx}
                className={CN(
                  'flex items-center ju py-2 px-4',
                  {
                    'text-Gray-500': disabled,
                  },
                  {
                    'text-Gray-500 hover:text-Gray-900':
                      status === 'normal' && !disabled,
                    'text-Red-500 hover:text-Red-900':
                      status === 'danger' && !disabled,
                    'cursor-not-allowed': disabled,
                  },
                  {
                    'border-b border-b-Gray-200': isBottomSeparator,
                  },
                )}
                onClick={() => {
                  setIsPopOverOpen(false)
                  onClick()
                }}>
                <i
                  className={CN(
                    iconClass,
                    'flex items-center text-heading-5 mr-2',
                  )}
                />
                {title}
              </button>
            ),
          )}
        </div>
      )}>
      {/* The component that popover need to be wrapped */}
      <button
        className={CN(MoreMenuButtonClasses, {
          'focus:border focus:border-Gray-300 hover:text-Gray-500 active:border active:border-Gray-300 active:bg-Gray-50 focus:bg-white focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-[#348AD9]':
            isPopOverOpen,
        })}
        onClick={() => {
          setIsPopOverOpen(!isPopOverOpen)
          onClickMoreButton && onClickMoreButton()
        }}
        {...restProps}>
        <div className='flex justify-center items-center h-[16.67px] w-[16.67px]'>
          <i className='ri-more-2-fill font-SemiBold text-Gray-700' />
        </div>
      </button>
    </PopOverMenu>
  )
}

MoreMenuButton.defaultProps = {
  onClickMoreButton: undefined,
  position: 'bottom',
}

export default MoreMenuButton
