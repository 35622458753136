import { RootState } from 'store/reducers'

import { SubscriptionPackagesListDto } from '../types'

import { SubscriptionStoreProps } from './reducer'

export const getSubscriptionStore = (
  state: RootState,
): SubscriptionStoreProps => state.subscription

export const getSubscriptionPackages = (
  state: RootState,
): SubscriptionPackagesListDto => state.subscription.packages

export const getFixedSubscriptionPackage = (state: RootState): any =>
  state?.subscription?.activeSubscriptions?.fixedSubscription?.package
