/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactNode } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { motion } from 'framer-motion'

import { Column } from 'components/atoms/Column'
import { cn } from 'utils'

type RoundedModalProps = {
  [x: string]: any
  children?: ReactNode | string | number | undefined
  closeButtonClassName?: string | undefined
  footerButtonAlignment?: 'left' | 'right' | 'center'
  headerTitle?: string
  headerSubtitle?: string
  isActive?: boolean | undefined
  isHeaderShow?: boolean
  isFooterShow?: boolean
  isSeparator?: boolean
  isPrimaryButtonDisable?: boolean
  primaryButtonTitle?: string
  primaryButtonTheme?: string
  secondaryButtonTitle?: string
  onClickPrimaryBtn?: () => void | undefined
  onClickSecondaryBtn?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
}

export const RoundedModal = ({
  children,
  closeButtonClassName,
  headerTitle,
  headerSubtitle,
  isActive,
  isHeaderShow,
  isFooterShow,
  isSeparator,
  isPrimaryButtonDisable,
  onClickPrimaryBtn,
  onClickSecondaryBtn,
  onHeaderCloseButtonClick,
  primaryButtonTitle,
  primaryButtonTheme,
  secondaryButtonTitle,
  ...restProps
}: RoundedModalProps) => {
  // const [key, setKey] = useState(Math.random())

  // useEffect(() => {
  //   setKey(Math.random())
  // }, [isActive])

  /** main modal header styles */
  const ModalHeaderClassName = CN(
    'modal-Header flex w-full justify-between px-6 py-[12px] items-center',
    {
      'border-b-[1px] border-[#E5E7EB]': isSeparator,
    },
  )

  /** main modal footer styles */
  const ModalFooterClassName = CN(
    'modal__footer flex w-full justify-between px-6 py-[12px] items-center',
    {
      'border-t-[1px] border-[#E5E7EB]': isSeparator,
    },
  )

  /** prevent modal hide when click inside modal */
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  return (
    <div
      className={cn(
        'modal-overlay fixed flex top-0 bottom-0 left-0 right-0 z-50 justify-center items-center h-full bg-black bg-opacity-40 rounded-xl',
        {
          hidden: !isActive,
        },
      )}>
      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, y: 0, transition: { duration: 0.1 } },
          closed: { opacity: 1, y: '-100%' },
        }}
        className='modal-container w-[580px] flex flex-col justify-between items-center bg-white shadow-lg rounded-xl'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <Column className='w-full'>
          {isHeaderShow && (
            <div className={ModalHeaderClassName} {...restProps.headerProps}>
              <div className='w-11/12'>
                <span className='flex items-center header-title text-heading-4 font-Medium text-Gray-700'>
                  {headerTitle}
                </span>
                <span className='flex items-center text-small text-Gray-600'>
                  {headerSubtitle}
                </span>
              </div>

              <Button
                className={CN(
                  'w-[36px] h-[36px] flex justify-center items-center',
                  closeButtonClassName,
                )}
                size='sm'
                isIconOnly
                textTheme='blue'
                theme='white'
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onHeaderCloseButtonClick && onHeaderCloseButtonClick()
                }}
                icon={
                  <i className='cursor-pointer ri-close-fill text-Cobalt-600 text-heading-4'></i>
                }
              />
            </div>
          )}

          {/* Modal body */}
          <div className='h-[75vh] overflow-y-auto styled-scroll mb-2'>
            {children}
          </div>
          {isFooterShow && (
            <div
              className={CN(
                'flex flex-col justify-center w-full max-h-[106px] px-5 space-y-3 pt-3 pb-5 rounded-b-xl',
                {
                  ModalFooterClassName,
                },
              )}
              {...restProps.footerProps}>
              {/*footer primary button*/}
              {primaryButtonTitle !== '' && (
                <Button
                  theme={primaryButtonTheme}
                  className=''
                  onClick={() => {
                    onClickPrimaryBtn && onClickPrimaryBtn()
                  }}
                  isDisabled={isPrimaryButtonDisable}
                  {...restProps.primaryButtonProps}>
                  {primaryButtonTitle}
                </Button>
              )}
              {/*footer secondary button*/}
              {secondaryButtonTitle !== '' && (
                <Button
                  textTheme='black'
                  theme='white'
                  onClick={() => {
                    onClickSecondaryBtn && onClickSecondaryBtn()
                  }}
                  {...restProps.secondaryButtonProps}>
                  {secondaryButtonTitle}
                </Button>
              )}
            </div>
          )}
        </Column>
      </motion.div>
    </div>
  )
}
