import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { getSubscriptionStore } from 'features/subscriptions'

import { Card } from 'components/atoms/Card'
import { H5 } from 'components/atoms/Headings'
import PoliceCheckLayout from 'components/layouts/PoliceCheckLayout/PoliceCheckLayout'

import { CreditListItem } from './CreditListItem'

export const QuotaCreditsCard = () => {
  const dispatch = useDispatch()

  const {
    activeSubscriptions: { fixedSubscription },
    fixedQuotas,
    payAsYouGoQuotas,
  } = useSelector(getSubscriptionStore)

  // Check if 'fixedSubscription' exists and is not null
  // const fixedSubscription = subscriptions
  //   ? subscriptions.fixedSubscription
  //   : null

  return (
    <Card>
      <div className='flex items-center justify-between'>
        <H5 title='Credits' />
        <Button
          theme='white'
          textTheme='blue'
          className='h-8'
          onClick={() => dispatch(setPayAsYouGoCreditsModalActive(true))}>
          Buy More
        </Button>
      </div>

      <div className='mt-3'>
        <PoliceCheckLayout>
          <CreditListItem
            quota={payAsYouGoQuotas.PoliceCheck.remainingQuota}
            icon='ri-police-car-line'
            title='Police Check Credits'
            exprireDate={fixedSubscription?.deactivatedDate}
          />
          <CreditListItem
            quota={payAsYouGoQuotas.RightToWorkCheck.remainingQuota}
            icon='ri-suitcase-line'
            title='Right To Work Credits'
            exprireDate={fixedSubscription?.deactivatedDate}
          />
          <CreditListItem
            quota={payAsYouGoQuotas.VolunteerCheck.remainingQuota}
            icon='ri-hand-heart-line'
            title='Volunteer Check Credits'
            exprireDate={fixedSubscription?.deactivatedDate}
          />
        </PoliceCheckLayout>

        <CreditListItem
          quota={fixedQuotas.referenceCheck.remainingQuota}
          icon='ri-ball-pen-line'
          title='Subscription Reference Check Credits'
          exprireDate={fixedSubscription?.endDate}
        />
        <CreditListItem
          quota={payAsYouGoQuotas.ReferenceCheck.remainingQuota}
          icon='ri-ball-pen-line'
          title='Single Purchase Reference Check Credits'
          exprireDate={fixedSubscription?.deactivatedDate}
        />
      </div>
    </Card>
  )
}
