import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toast, ToggleDetailSection } from '@labourhub/labour-hub-ds'
import { useEditAgencySettingsByTenantId } from 'api/settings/agencies/useEditAgencySettingsByTenantId'
import { useGetAgencySettingsByTenantId } from 'api/settings/agencies/useGetAgencySettingsByTenantId'
import { useBreadcrumbs } from 'hooks'
import { TenantIdProp } from 'types'

import { PageLoader } from 'components/atoms'

import { setAgencyAppSettings } from '../../store'
import { AppSettingKey, AppSettingProps } from '../../types'
import { isSettingEnabled, updateSettingByKey } from '../../utils'

// import { EditQuotaSettingsDrawer } from './EditQuotaSettingsDrawer'

type GlobalSettingsTabProps = TenantIdProp

export const GlobalSettingsTab = ({ tenantId }: GlobalSettingsTabProps) => {
  useBreadcrumbs()

  const notify = (props: any) => Toast(props)
  const dispatch = useDispatch()

  const { userDetails } = useSelector((state: any) => state.user)
  const [isModified, setIsModified] = useState(false)
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const [settings, setSettings] = useState<AppSettingProps[]>([])

  /** API call for getting agency profile settings */
  const { isLoading, refetch } = useGetAgencySettingsByTenantId(
    tenantId,
    (agencySettings) => {
      setSettings(agencySettings)
      if (userDetails.tenantId === tenantId) {
        dispatch(setAgencyAppSettings(agencySettings))
      }
    },
  )

  /** API call for the edit settings */
  const { mutate } = useEditAgencySettingsByTenantId()

  /** Process the edit existing settings */
  const editSettings = () => {
    mutate(
      {
        tenantId,
        settings,
      },
      {
        onSuccess: () => {
          if (userDetails.tenantId === tenantId) {
            dispatch(setAgencyAppSettings(settings))
          }
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: 'Unable to update global settings',
            status: 'Error',
          })
        },
      },
    )
  }

  const handleToggle = (
    key: AppSettingKey,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedSettings = updateSettingByKey(key, settings, {
      value: `${e.target.checked}`,
    })

    setSettings(updatedSettings)
    setIsModified(true)
  }

  // update setting when settings changes
  useEffect(() => {
    if (isModified) {
      editSettings()
      setIsModified(false)
    }
  }, [isModified])

  return (
    <div className='w-full p-5 bg-white border rounded-lg outline-none border-Gray-100'>
      {isLoading && <PageLoader size='xxs' className='bg-opacity-100' />}

      <div className='flex items-start justify-between'>
        <div className='space-t-3'>
          <span className='mt-8 text-md text-Gray-800 font-SemiBold'>
            Features
          </span>

          {/*   ----------- SMS Toggle --------------- */}
          <ToggleDetailSection
            label='Enable SMS'
            description='This will enable the sms feature for a company'
            value={isSettingEnabled(AppSettingKey.SmsFeature, settings)}
            onClick={(e: any) => handleToggle(AppSettingKey.SmsFeature, e)}
          />

          {/*   ----------- Police Check Toggle --------------- */}
          <ToggleDetailSection
            label='Enable Police Check'
            description='This will enable the police check feature for a company'
            value={isSettingEnabled(AppSettingKey.NccPoliceCheck, settings)}
            onClick={(e: any) => {
              const value: boolean = e.target.checked

              const updatedPoliceSettings = updateSettingByKey(
                AppSettingKey.NccPoliceCheck,
                settings,
                {
                  value: `${value}`,
                },
              )

              const updatedPoliceVolunteerSettings = updateSettingByKey(
                AppSettingKey.NccPoliceCheckVolunteer,
                updatedPoliceSettings,
                {
                  value: `${value}`,
                },
              )
              setSettings(updatedPoliceVolunteerSettings)

              setIsModified(true)
            }}
          />

          {/*   ----------- Right to Work Toggle --------------- */}
          <ToggleDetailSection
            label='Enable Right to Work Check'
            description='This will enable the right to work check feature for a company'
            value={isSettingEnabled(
              AppSettingKey.NccRightToWorkCheck,
              settings,
            )}
            onClick={(e: any) =>
              handleToggle(AppSettingKey.NccRightToWorkCheck, e)
            }
          />
        </div>

        {/* <Button theme='gradient' onClick={() => setIsDrawerOpen(true)}>
          Edit Quota Settings
        </Button>

        <EditQuotaSettingsDrawer
          tenantId={tenantId}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          agencyAppsettings={settings}
          refetchAppsettings={refetch}
        /> */}
      </div>
    </div>
  )
}
