import CN from 'classnames'
import moment from 'moment'

export enum RefCheckStateToTextEnum {
  RESPONSE_RECEVIED = 'Response Received',
  AWAITING_RESPONSE = 'Awaiting for Response',
  REQUEST_PHONE_REF = 'Requested Phone Ref Check',
  REQUEST_DECLINED = 'Request Declined',
  NOT_SENT = 'Not Sent',
}

interface CandidateRefereeStatusCardProps {
  onClickViewReport: () => void
  sentDate?: string | null
  updatedDate?: string | null
  status: 'Failed' | 'Incomplete' | 'Passed' | 'NotSent' | null
  state:
    | 'RESPONSE_RECEVIED'
    | 'AWAITING_RESPONSE'
    | 'REQUEST_PHONE_REF'
    | 'REQUEST_DECLINED'
    | 'NOT_SENT'
    | null
}

export const CandidateRefereeStatusCard = ({
  status,
  state,
  sentDate,
  updatedDate,
  onClickViewReport,
}: CandidateRefereeStatusCardProps) => {
  return (
    <button
      onClick={state === 'RESPONSE_RECEVIED' ? onClickViewReport : undefined}
      className={CN(
        'rounded-[8px] p-5 w-[246px] h-fit ml-auto border-[1px] focus:outline-none',
        {
          'bg-Green-50 border-Green-200 shadow-lg cursor-pointer focus:outline-1':
            state === 'RESPONSE_RECEVIED',
          'bg-Red-50 border-Red-200 cursor-default':
            state === 'REQUEST_DECLINED',
          'bg-Yellow-50 border-Yellow-200 cursor-default':
            state === 'AWAITING_RESPONSE',
          'bg-Blue-50 border-Blue-200 cursor-default':
            state === 'REQUEST_PHONE_REF',
          'bg-Gray-50 border-Gray-200 cursor-default':
            status === null || status == 'NotSent',
        },
      )}>
      <span
        className={CN(
          'flex w-max flex-row items-center border-[1px] rounded-[10px] px-2.5 py-.ml-2 mb-5',
          {
            'bg-Green-100 border-Green-500': state === 'RESPONSE_RECEVIED',
            'bg-Red-100 border-Red-500': state === 'REQUEST_DECLINED',
            'bg-Yellow-100 border-Yellow-500': state === 'AWAITING_RESPONSE',
            'bg-Blue-100 border-Blue-500': state === 'REQUEST_PHONE_REF',
            'bg-Gray-100 border-Gray-500':
              state === null || status == 'NotSent',
          },
        )}>
        <span
          className={CN('w-[8px] h-[8px] rounded-full mr-2', {
            'bg-Green-500': state === 'RESPONSE_RECEVIED',
            'bg-Red-500': state === 'REQUEST_DECLINED',
            'bg-Yellow-500': state === 'AWAITING_RESPONSE',
            'bg-Blue-500': state === 'REQUEST_PHONE_REF',
            'bg-Gray-500': state === null || state === 'NOT_SENT',
          })}
        />
        <span className='text-extra-small text-Gray-800 font-Medium'>
          {state === null || status == 'NotSent'
            ? 'Not Sent'
            : RefCheckStateToTextEnum[state]}
        </span>
      </span>
      <div className='flex mt-2.5 justify-between'>
        <div className='flex flex-col text-left min-w-max'>
          <span className='mb-2 leading-3 text-small text-Gray-500'>Sent:</span>
          <span className='text-left text-small font-SemiBold'>
            {sentDate ? moment(sentDate).format('DD/MM/YYYY') : 'Pending'}
          </span>
        </div>
        <div className='flex flex-col'>
          <span className='mb-2 leading-3 text-left text-small text-Gray-500'>
            {status === 'Failed' ? 'Declined:' : 'Responded:'}
          </span>
          <span className='text-left text-small font-SemiBold text-Gray-800'>
            {updatedDate ? moment(updatedDate).format('DD/MM/YYYY') : 'Pending'}
          </span>
        </div>
      </div>
    </button>
  )
}
