export const timeGap = (latest: any, old: any) => {
  const date1: any = new Date(latest)
  const date2: any = new Date(old)

  //new date instance
  const dt_date1 = new Date(date1)
  const dt_date2 = new Date(date2)

  //Get the Timestamp
  const date1_time_stamp = dt_date1.getTime()
  const date2_time_stamp = dt_date2.getTime()

  let calc

  //Check which timestamp is greater
  if (date1_time_stamp > date2_time_stamp) {
    calc = new Date(date1_time_stamp - date2_time_stamp)
  } else {
    calc = new Date(date2_time_stamp - date1_time_stamp)
  }
  //Retrieve the date, month and year
  const calcFormatTmp: any =
    calc.getDate() + '-' + (calc.getMonth() + 1) + '-' + calc.getFullYear()
  //Convert to an array and store
  const calcFormat = calcFormatTmp.split('-')
  //Subtract each member of our array from the default date
  const days_passed = Number(Math.abs(calcFormat[0]) - 1)
  const months_passed = Number(Math.abs(calcFormat[1]) - 1)
  const years_passed = Number(Math.abs(calcFormat[2]) - 1970)

  if (months_passed === 0 && years_passed === 0) {
    return `Less than a month.`
  } else if (days_passed === 0 && years_passed === 0) {
    return months_passed + ` ${months_passed > 1 ? `months` : `month`}`
  } else if (days_passed > 0 && months_passed > 0 && years_passed === 0) {
    return months_passed + ` ${months_passed > 1 ? `months` : `month`}`
  } else if (months_passed === 0 && years_passed > 0) {
    return years_passed + `  ${years_passed > 1 ? `years` : `year`}`
  } else if (months_passed > 0 && years_passed > 0) {
    return (
      years_passed +
      ` ${years_passed > 1 ? `years & ` : `year & `}` +
      months_passed +
      ` ${months_passed > 1 ? `months` : `month`}`
    )
  }
}
