/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Modal, Tabs, Toast } from '@labourhub/labour-hub-ds'
import { useGetAgencySettings, useGetCandidateSettings } from 'api'
import {
  useCreateCandidateAvatar,
  useDeleteCandidate,
  useDeleteCandidateAvatar,
} from 'api/candidates'
import CN from 'classnames'
import { EditCandidateModal } from 'features/candidates/components/AddEditCandidateModals/EditCandidateModal'
import { CustomSurveysTab } from 'features/candidates/components/CustomSurveysTab'
import { DownloadableReportsTable } from 'features/candidates/components/DownloadableReportsTab/DownloadableReportsTable'
import { HistoryTab } from 'features/candidates/components/HistoryTab'
import { RefereesTab } from 'features/candidates/components/RefereesTab'
import { CandidateReferenceReportDownloadModal } from 'features/candidates/components/ReferenceReportDownloadModal/CandidateReferenceReportDownloadModal'
import { SurveyResponsesTab } from 'features/candidates/components/SurveyResponsesTab'
import { CandidateMarkingStatus } from 'features/candidates/data'
import { EligibilityTab } from 'features/ncc'
import {
  AppSettingKey,
  getAgencyAppSettings,
  isSettingEnabled,
  setAgencyAppSettings,
  setCandidateAppSettings,
} from 'features/settings'
import {
  useGetCandidateBasicDetailsById,
  useRequestReferee,
  useRequestRefereeReminder,
  useUpdateCandidateMarkPassFail,
} from 'framework/api/methods'
import { useBreadcrumbs } from 'hooks'
import { setBackFunction } from 'store/reducers/layout/layoutSlice'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { PageLoader } from 'components/atoms'
import {
  CustomizableEditProfilePictureModal,
  RequestRefereeModal,
  RequestRefereeReminderModal,
} from 'components/molecules'

import { CandidateDetailsCard } from '../../components/CandidateDetailsCard'

import './Candidate.scss'

type CandidateProfilePageProps = {
  [x: string]: any
}

export const CandidateProfilePage = ({
  className,
  ...restProps
}: CandidateProfilePageProps) => {
  const CandidateProfileClasses = CN(`candidate-profile`, className)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userDetails, isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )

  const [refereeRequestModalIsActive, setRefereeRequestModalIsActive] =
    useState(false)

  const [hasValidDownloadableReferees, setHasValidDownloadableReferees] =
    useState(false)

  const [
    isDownlaodReferenceReportModalActive,
    setIsDownlaodReferenceReportModalActive,
  ] = useState(false)
  const [showEditProfilePictureModal, setShowEditProfilePictureModal] =
    useState(false)
  const [showDeleteProfilePictureModal, setShowDeleteProfilePictureModal] =
    useState(false)
  const [
    refereeRequestReminderModalIsActive,
    setRefereeRequestReminderModalIsActive,
  ] = useState(false)

  const [markAsPassFailModal, setMarkAsPassFailModal] = useState({
    type: '',
    isActive: false,
  })

  const [searchParams] = useSearchParams()
  const notify = (props: any) => Toast(props)

  const [files, setFiles] = useState<any>()

  const [imageUrl, setImageUrl] = useState<any>('')
  const [isOpenCandidateDelete, setIsOpenCandidateDelete] =
    useState<boolean>(false)

  /** showing  edit candidate drawer states */
  const [isCandidateEditModalShow, setIsCandidateEditModalShow] =
    useState(false)

  const candidateId: string = searchParams.get('candidate_id') || ''
  const notificationType: string = searchParams.get('notification_type') || ''
  const agencyAppSettings = useSelector(getAgencyAppSettings)

  const isSurveyEnabled = isSettingEnabled(
    AppSettingKey.IS_SURVEY_ENABLED,
    agencyAppSettings,
  )

  const policyCheckEnabled = isSettingEnabled(
    AppSettingKey.NccPoliceCheck,
    agencyAppSettings,
  )

  const isSuperAdmin = userDetails?.roleName === 'SuperAdministrator'

  // Add breadcrumbs
  useBreadcrumbs()

  useEffect(() => {
    dispatch(
      setBackFunction(() => {
        navigate('/candidate-list')
      }),
    )
  }, [])

  const {
    refetch: getCandidateById,
    data: candidateDetails,
    isLoading: loadingCandidateById,
  } = useGetCandidateBasicDetailsById(candidateId)

  /** API call for getting candidate settings */
  const { refetch: refetchCandidateSettings } = useGetCandidateSettings(
    candidateId,
    (candidateSettings) => {
      dispatch(setCandidateAppSettings(candidateSettings))
    },
  )

  const { refetch: refetchAgencySettings } = useGetAgencySettings(
    (appSettings) => {
      dispatch(setAgencyAppSettings(appSettings))
    },
  )

  useEffect(() => {
    refetchAgencySettings()
    refetchCandidateSettings()
  }, [isCandidateEditModalShow])

  useEffect(() => {
    refetchCandidateSettings()
  }, [candidateId])

  const { mutate: requestRefereesMutate, isLoading: requestRefereeIsLoading } =
    useRequestReferee()

  /** request referees */
  async function requestReferees() {
    requestRefereesMutate(candidateId, {
      onSuccess: () => {
        Toast({
          alertBody: 'Referee request successfully sent to candidate',
          status: 'Success',
        })
        setRefereeRequestModalIsActive(false)
        getCandidateById()
      },
      onError: ({ response: { data: errData } }: any) => {
        Toast({
          alertHeader: 'Error...!',
          alertBody: errData?.message
            ? errData?.message
            : 'Error requesting referees!',
          status: 'Error',
        })
      },
    })
  }

  const {
    mutate: requestRefereesReminderMutate,
    isLoading: requestRefereeReminderIsLoading,
  } = useRequestRefereeReminder()

  /** Request Referee Reminder */
  async function requestRefereeReminder() {
    requestRefereesReminderMutate(searchParams.get('candidate_id'), {
      onSuccess: () => {
        Toast({
          alertBody: 'Request referee reminder successfully sent to candidate',
          status: 'Success',
        })
        setRefereeRequestReminderModalIsActive(false)
        getCandidateById()
      },
      onError: ({ response: { data: errData } }: any) => {
        Toast({
          alertHeader: 'Error...!',
          alertBody: errData?.message
            ? errData?.message
            : 'Error requesting referee reminder!',
          status: 'Error',
        })
        setRefereeRequestReminderModalIsActive(false)
      },
    })
  }

  /** upload profile picture api */
  const {
    mutate: UploadProfilePictureMutate,
    isLoading: uploadProfilePictureIsLoading,
  } = useCreateCandidateAvatar()

  /** Process the upload profile picture */
  async function UploadProfilePicture(Id: any, avatarObject: any) {
    UploadProfilePictureMutate(
      {
        Id: candidateId,
        avatarObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Profile photo updated!',
            alertBody: successData?.message
              ? successData.message
              : `We've uploaded your profile photo. It may take a few minutes to display everywhere.`,
            status: 'Success',
          })
          setShowEditProfilePictureModal(false)

          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
          getCandidateById()
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowEditProfilePictureModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for upload profile picture using react-query */
  const handleUploadProfileAvatar = (files: any) => {
    UploadProfilePicture(userDetails.id, files)
  }

  /** API Call for delete candidate photo using react-query */
  const {
    mutate: deleteCandidateAvatarMutate,
    isLoading: deleteCandidateAvatarIsLoading,
  } = useDeleteCandidateAvatar()

  /** Process the Delete candidate photo */
  async function deleteCandidatePhoto() {
    deleteCandidateAvatarMutate(
      { Id: candidateId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Photo was removed!',
            alertBody: '',
            status: 'Success',
          })
          getCandidateById()
          setShowDeleteProfilePictureModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  const { mutateAsync: deleteCandidate, isLoading: isLoadingDeleteCandidate } =
    useDeleteCandidate(searchParams.get('candidate_id'), true)

  /** API Call for Update candidate mark as pass/fail */
  const {
    mutate: updateCandidateMarkPassFailMutate,
    isLoading: updateCandidateMarkPassFailIsLoading,
  } = useUpdateCandidateMarkPassFail()

  /** Process update candidate mark as pass/fail API call */
  async function updateCandidateMarkPassFail(markingStatus: string | null) {
    updateCandidateMarkPassFailMutate(
      {
        candidateId,
        markingStatus,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Success!',
            alertBody: successData?.message,
            status: 'Success',
          })

          getCandidateById()

          setMarkAsPassFailModal({
            ...markAsPassFailModal,
            type: '',
            isActive: false,
          })
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }
  /** candidate profile tab details */
  const tabDetails = [
    // {
    //   id: 1,
    //   tabButtonContent: 'Dashboard',
    //   tabContent: (
    //     <DashboardTabView candidateId={candidateId} notify={notify} />
    //   ),
    // },
    {
      id: 1,
      tabButtonContent: 'Referees',
      tabHeaderIconList: candidateDetails?.basicCandidateProfile
        ? [
            {
              content:
                candidateDetails?.basicCandidateProfile?.refereeCount -
                candidateDetails?.basicCandidateProfile?.remainingRefereeCount,
              color: 'bg-Cobalt-500',
            },
          ]
        : [],

      tabContent: (
        <RefereesTab
          candidateId={candidateId}
          candidateDetails={candidateDetails}
          getCandidateById={getCandidateById}
          setRefereeRequestModalIsActive={setRefereeRequestModalIsActive}
          setRefereeRequestReminderModalIsActive={
            setRefereeRequestReminderModalIsActive
          }
          notify={notify}
          requestReferees={requestReferees}
        />
      ),
    },

    {
      id: 3,
      tabButtonContent: 'History',
      tabContent: <HistoryTab candidateId={candidateId} />,
    },
    {
      id: 6,
      tabButtonContent: 'Downloadable Reports',
      tabContent: (
        <DownloadableReportsTable
          candidateId={candidateId}
          candidateDetails={candidateDetails?.basicCandidateProfile}
        />
      ),
    },
  ]
  if (policyCheckEnabled || isSuperAdmin) {
    tabDetails.splice(1, 0, {
      id: 2,
      tabButtonContent: 'Eligibility',
      tabContent: (
        <EligibilityTab
          candidateId={candidateId}
          candidateName={candidateDetails?.basicCandidateProfile.name}
        />
      ),
    })
  }

  // Find the index of the tab with ID 6
  const index = tabDetails.findIndex((tab) => tab.id === 6)

  // Insert the survey-related tabs at index + 1
  if (isSurveyEnabled && index !== -1) {
    tabDetails.splice(
      index,
      0,
      {
        id: 4,
        tabButtonContent: 'Custom Surveys',
        tabContent: (
          <CustomSurveysTab
            candidateId={candidateId}
            candidateDetails={candidateDetails?.basicCandidateProfile}
            getCandidateById={getCandidateById}
          />
        ),
      },
      {
        id: 5,
        tabButtonContent: 'Survey Responses',
        tabHeaderIconList: candidateDetails?.basicCandidateProfile
          ? [
              {
                content:
                  candidateDetails?.basicCandidateProfile
                    ?.surveyResponseStatsCount?.totalCount,
                color: 'bg-Cobalt-500',
              },
            ]
          : [],
        tabContent: (
          <SurveyResponsesTab
            candidateId={candidateId}
            candidateDetails={candidateDetails}
          />
        ),
      },
    )
  }

  return (
    <div className={CandidateProfileClasses} {...restProps}>
      {(requestRefereeIsLoading ||
        requestRefereeReminderIsLoading ||
        isLoadingDeleteCandidate) && (
        <PageLoader size='xxs' className='absolute' />
      )}

      {/* Delete Referee modal */}
      <Modal
        isActive={isOpenCandidateDelete}
        onClickPrimaryBtn={() => {
          deleteCandidate().then(() => {
            setIsOpenCandidateDelete(false)
            navigate('/candidate-list')
          })
        }}
        onClickSecondaryBtn={() => {
          setIsOpenCandidateDelete(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsOpenCandidateDelete(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Delete Candidate?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to delete candidate?
          </span>
        </div>
      </Modal>

      <CandidateDetailsCard
        candidateDetails={candidateDetails?.basicCandidateProfile}
        hasValidDownloadableReferees={hasValidDownloadableReferees}
        loadingCandidateById={loadingCandidateById}
        getCandidateById={getCandidateById}
        isSurveyEnabled={isSurveyEnabled}
        onCandidateEditClick={() => {
          setIsCandidateEditModalShow(true)
        }}
        onDownlaodReferenceReportClick={() => {
          setIsDownlaodReferenceReportModalActive(true)
        }}
        onCandidateDeleteClick={() => {
          setIsOpenCandidateDelete(true)
        }}
        onAddNewPhotoClick={() => {
          setShowEditProfilePictureModal(true)
        }}
        onEditPhotoClick={() => {
          setShowEditProfilePictureModal(true)
        }}
        onDeletePhotoClick={() => {
          setShowDeleteProfilePictureModal(true)
        }}
        contactButtonOnClick={() => {
          // console.log('Edit')
        }}
        onRequestRefereeClick={() => {
          setRefereeRequestModalIsActive(true)
        }}
        onRequestRefereeReminderClick={() => {
          setRefereeRequestReminderModalIsActive(true)
        }}
        setMarkAsPassFailModal={setMarkAsPassFailModal}
        markAsPassFailModal={markAsPassFailModal}
      />
      <CustomizableEditProfilePictureModal
        isActive={showEditProfilePictureModal}
        onHeaderCloseButtonClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowEditProfilePictureModal(false)
        }}
        onOverlayClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickPrimaryBtn={() => {
          handleUploadProfileAvatar(files)
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        headerTitle={
          candidateDetails?.basicCandidateProfile?.avatarUrl
            ? 'Change profile photo'
            : 'Add profile photo'
        }
        headerSubTitle='Update your profile photo here.'
        primaryButtonIsLoading={uploadProfilePictureIsLoading}
      />

      <Tabs
        className='!h-fit pt-5'
        tabHeaderButtonClassName='candidate-tab text-small py-3'
        contentClassName='h-fit pt-5 pb-10'
        defaultTabId={
          notificationType === 'Note'
            ? 3
            : notificationType === 'CandidateEligibility'
            ? 2
            : 1
        }
        tabDetails={tabDetails}
      />

      {/* mark as passed/failed confirmation pop up */}
      <Modal
        isActive={markAsPassFailModal?.isActive}
        onClickPrimaryBtn={() => {
          updateCandidateMarkPassFail(
            markAsPassFailModal?.type == 'Passed'
              ? CandidateMarkingStatus.Passed
              : CandidateMarkingStatus.Failed,
          )
        }}
        onClickSecondaryBtn={() => {
          setMarkAsPassFailModal({
            ...markAsPassFailModal,
            type: '',
            isActive: false,
          })
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: updateCandidateMarkPassFailIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, Continue'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Yellow-50'>
            <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            {markAsPassFailModal?.type == 'Passed'
              ? 'Mark as Passed & Completed?'
              : 'Mark as Failed & Completed?'}
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            {markAsPassFailModal?.type == 'Passed'
              ? 'Are you sure to make this one passed & completed?'
              : 'Are you sure to make this one failed & completed?'}
          </span>
        </div>
      </Modal>

      {/* delete user profile picture modal */}
      <Modal
        isActive={showDeleteProfilePictureModal}
        onClickPrimaryBtn={() => {
          deleteCandidatePhoto()
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteProfilePictureModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteCandidateAvatarIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteProfilePictureModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Delete Candidate Profile Picture?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>

      <RequestRefereeReminderModal
        requestSendDate={
          candidateDetails?.basicCandidateProfile?.requestSendDate
        }
        lastSendRequest={
          candidateDetails?.basicCandidateProfile?.lastSendRequest
        }
        isActive={refereeRequestReminderModalIsActive}
        setIsActive={setRefereeRequestReminderModalIsActive}
        onReminderClick={() => {
          requestRefereeReminder()
        }}
      />

      <RequestRefereeModal
        isActive={refereeRequestModalIsActive}
        setIsActive={setRefereeRequestModalIsActive}
        onRequestRefereeClick={() => {
          requestReferees()
        }}
        requestSentOn={candidateDetails?.basicCandidateProfile?.requestSendDate}
      />

      {candidateDetails && (
        <CandidateReferenceReportDownloadModal
          isModalActive={isDownlaodReferenceReportModalActive}
          setIsModalActive={setIsDownlaodReferenceReportModalActive}
          candidateId={candidateId}
          candidateDetails={candidateDetails?.basicCandidateProfile}
          setHasValidDownloadableReferees={setHasValidDownloadableReferees}
        />
      )}

      <EditCandidateModal
        isModalActive={isCandidateEditModalShow}
        candidateDetails={candidateDetails?.basicCandidateProfile}
        setIsModalActive={setIsCandidateEditModalShow}
        buttonTitle='Save Candidate'
        headerTitle='Edit candidate'
        headerSubtitle='Edit candidate details here'
        isButtonDisabled={false}
        notify={notify}
        getCandidateById={getCandidateById}
      />
    </div>
  )
}
