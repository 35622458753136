import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { CardBillingAddressDto } from 'api/payments'
import { InvoiceDto, PaymentInvoiceModal } from 'features/invoices'
import { FixedSubscriptionPaymentInvoiceModal } from 'features/invoices/components/FixedSubscriptionPaymentInvoiceModal'
import { PaymentErrorType } from 'features/payments/types'
import {
  FixedSubscriptionPackageDto,
  getSubscriptionStore,
  SubscriptionMode,
} from 'features/subscriptions'
import { ModalProps } from 'types'

import { Modal } from 'components/molecules'

import { PaymentCardElementForm } from './PaymentCardElementForm'
import { SubscriptionConfirmation } from './SubscriptionConfirmation'
import { SubscriptionInformation } from './SubscriptionInformation'
import { SubscriptionTypeRadio } from './SubscriptionTypeRadio'

import './SubscriptionPaymentModal.scss'

type FixedSubscriptionPaymentModalProps = ModalProps & {
  setIsFixedSubscriptionPackagesModalActive: any
  subscriptionPackage: FixedSubscriptionPackageDto
  subscriptionMode: SubscriptionMode
}

export const FixedSubscriptionPaymentModal = ({
  isModalActive,
  setIsModalActive,
  setIsFixedSubscriptionPackagesModalActive,
  subscriptionPackage,
  subscriptionMode,
}: FixedSubscriptionPaymentModalProps) => {
  const {
    packages: { fixedMonthlyPackages, fixedYearlyPackages },
  } = useSelector(getSubscriptionStore)

  const [selectedPackage, setSelectedPackage] =
    useState<FixedSubscriptionPackageDto>(subscriptionPackage)

  const [invoice, setInvoice] = useState<InvoiceDto | null>(null)
  const [cardBillingAddress, setCardBillingAddress] = useState<
    CardBillingAddressDto | undefined
  >(undefined)
  const [paymentError, setPaymentError] = useState<PaymentErrorType>(null)
  const [isInvoiceModalActive, setIsInvoiceModalActive] = useState(false)
  const [finalSubscriptionMode, setFinalSubscriptionMode] =
    useState(subscriptionMode)

  useEffect(() => {
    isModalActive && setSelectedPackage(subscriptionPackage)
  }, [isModalActive])

  useEffect(() => {
    setFinalSubscriptionMode(subscriptionMode)
  }, [subscriptionMode])

  return (
    <>
      <Modal
        customWidth='90vw'
        modalProps={{ style: { width: '80vw', height: '750px' } }}
        isActive={isModalActive}
        isFooterShow={false}
        isHeaderShow={false}
        onOverlayClick={() => {
          setIsModalActive(false)
          // setUseDifferentAddress(false)
        }}>
        <div className='flex flex-col items-start justify-start w-full h-full bg-Gray-50'>
          <div className='flex items-end justify-end w-full px-[50px] py-6'>
            <Button
              className='w-[20px] h-[20px] flex justify-center items-center'
              size='sm'
              isIconOnly
              textTheme='black'
              theme='link'
              onClick={() => {
                setIsModalActive(false)
                // setUseDifferentAddress(false)
              }}
              icon={
                <i className='cursor-pointer ri-close-fill text-Gray-800 text-[16px] leading-5'></i>
              }
            />
          </div>

          <div className='flex w-full px-[80px] justify-start items-start h-[680px]'>
            <div className='flex flex-col items-start justify-start w-3/5 h-full'>
              <div className='w-full h-full bg flex justify-start items-start flex-col px-[24px]'>
                <div className='flex items-center justify-between w-full'>
                  <Button
                    className='flex items-center justify-center font-SemiBold'
                    textTheme='black'
                    theme='link'
                    onClick={() => {
                      setIsModalActive(false)
                      setIsFixedSubscriptionPackagesModalActive(true)
                    }}
                    iconLeft={<i className='ri-arrow-left-line'></i>}>
                    Back to Subscription options
                  </Button>
                </div>

                <div className='w-full flex flex-col h-[calc(100%-96px)] overflow-y-auto styled-scroll pr-5 mt-4'>
                  <div className='flex flex-col p-6 bg-white border rounded-lg border-Gray-200'>
                    <div className='text-[#414244] font-SemiBold'>
                      Billing Period
                    </div>

                    <SubscriptionTypeRadio
                      currentSubscriptionPackage={subscriptionPackage}
                      sameplanPackages={[
                        ...fixedMonthlyPackages,
                        ...fixedYearlyPackages,
                      ].filter((p) => p.name === subscriptionPackage.name)}
                      setSelectedPackage={setSelectedPackage}
                      setFinalSubscriptionMode={setFinalSubscriptionMode}
                    />
                  </div>

                  <PaymentCardElementForm
                    isSubscriptionPaymentModalActive={isModalActive}
                    subscriptionPackage={selectedPackage}
                    subscriptionMode={finalSubscriptionMode}
                    setInvoice={setInvoice}
                    setCardBillingAddress={setCardBillingAddress}
                    setIsInvoiceModalActive={setIsInvoiceModalActive}
                    setFixedSubscriptionPaymentModalActive={setIsModalActive}
                    setPaymentError={setPaymentError}
                  />
                  <SubscriptionConfirmation
                    subscriptionPackage={selectedPackage}
                    subscriptionMode={finalSubscriptionMode}
                  />
                </div>
              </div>
            </div>

            <SubscriptionInformation
              subscriptionPackage={subscriptionPackage}
              selectedPackage={selectedPackage}
            />
          </div>
        </div>
      </Modal>

      <FixedSubscriptionPaymentInvoiceModal
        isActive={isInvoiceModalActive}
        setIsActive={setIsInvoiceModalActive}
        invoice={invoice}
        cardBillingAddress={cardBillingAddress}
        isSuccess={invoice != null}
        paymentError={paymentError}
      />
    </>
  )
}
