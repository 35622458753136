import { useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import buildTemplate from 'assets/images/buildTemplate.svg'
import { CandidateProfileBasicDetailsType } from 'framework/api/methods'

import { Card } from 'components/atoms/Card'
import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'

import { BuildNewTemplateModal } from './BuildNewTemplateModal'

type BuildNewTemplateCardProps = {
  getCandidateById: () => void
  candidateDetails: CandidateProfileBasicDetailsType
}

export const BuildNewTemplateCard = ({
  getCandidateById,
  candidateDetails,
}: BuildNewTemplateCardProps) => {
  const [isBuildTemplateModelOpen, setIsBuildTemplateModelOpen] =
    useState(false)

  return (
    <>
      <Card className='shadow-sm hover:shadow-xl rounded-xl relative cursor-pointer min-h-[245px]'>
        <Column className='items-center pt-3'>
          <RowWithAlignment justifyAlignment='center'>
            {/* Image */}
            <div className='flex w-[100px] justify-center items-center rounded-full bg-Cobalt-50 h-[100px]'>
              <img
                src={buildTemplate}
                alt=''
                className='flex justify-center items-center'
              />
            </div>
          </RowWithAlignment>
          {/* Subtitle*/}
          <p className='mt-4 mb-2 text-center text-small text-Gray-500'>
            Create your own AI tailored or custom templates
          </p>
          <Button
            className='w-[176px]'
            textTheme='white'
            onClick={() => {
              setIsBuildTemplateModelOpen(true)
            }}>
            Build New Template
          </Button>
        </Column>
      </Card>

      {/* Build New Template Modal */}
      <BuildNewTemplateModal
        isModalActive={isBuildTemplateModelOpen}
        setIsModalActive={setIsBuildTemplateModelOpen}
        getCandidateById={getCandidateById}
        candidateDetails={candidateDetails}
      />
    </>
  )
}
