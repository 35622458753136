import { DashboardPage, ReportingDashboard } from 'features/misc'
import { OnboardPage } from 'features/onboarding/pages/OnboardPage'
import {
  QuestionnaireBuilderPage,
  QuestionnaireTabsPage,
} from 'features/questionnaires'
import { SettingsPage } from 'features/settings'
import {
  AgencyConfigurationPage,
  SuperAdminDashboardPage,
} from 'features/super-admin'

import { AuthLayoutHalf } from 'components/layouts/AuthLayout'
import { MainLayout } from 'components/layouts/MainLayout'
import { VerifyLayout } from 'components/layouts/VerifyLayout'
import { ForgotPassword } from 'components/pages/auth/ForgotPassword'
import { LogInForm } from 'components/pages/auth/LogIn'
import { OnboardingUser } from 'components/pages/auth/OnboardingUser'
import { ResetPassword } from 'components/pages/auth/ResetPassword'
import { VerifyAccount } from 'components/pages/auth/VerifyAccount'
import { Error404 } from 'components/pages/Error404'
import { Reporting } from 'components/pages/Reporting'
import { UserList } from 'components/pages/UserList'
import { UserGroups } from 'components/pages/users/UserGroups'
import { UserProfile } from 'components/pages/users/UserProfile'
import { lazyImport } from 'utils/lazyImport'

const { AgencyProfilePage } = lazyImport(
  () => import('features/agencies'),
  'AgencyProfilePage',
)
const { CandidateListPage } = lazyImport(
  () => import('features/candidates'),
  'CandidateListPage',
)
const { CandidateProfilePage } = lazyImport(
  () => import('features/candidates'),
  'CandidateProfilePage',
)

interface Route {
  path: string
  component?: any
  heading?: string
  subHeading?: string
  layout?: any
  redirectTo?: string
  isSecured?: boolean
  isAuthScreen?: boolean
  isBackButton?: string
  isShowRangePicker?: boolean
}

export const routes: Route[] = [
  {
    path: '/auth',
    redirectTo: '/auth/login',
  },
  {
    path: '/auth/login',
    component: LogInForm,
    layout: AuthLayoutHalf,
    isAuthScreen: true,
  },
  {
    path: '/auth/sign-up',
    component: OnboardPage,
    isAuthScreen: true,
  },
  {
    path: '/auth/sign-up/free',
    component: OnboardPage,
    isAuthScreen: true,
  },
  {
    path: '/auth/sign-up/basic',
    component: OnboardPage,
    isAuthScreen: true,
  },
  {
    path: '/auth/sign-up/pro',
    component: OnboardPage,
    isAuthScreen: true,
  },
  {
    path: '/onboard-user',
    component: OnboardingUser,
    layout: AuthLayoutHalf,
    isAuthScreen: true,
  },
  {
    path: '/verify-account',
    component: VerifyAccount,
    layout: VerifyLayout,
    isAuthScreen: true,
  },
  {
    path: '/auth/forgot-password',
    component: ForgotPassword,
    layout: AuthLayoutHalf,
    isAuthScreen: true,
  },
  {
    path: '/auth/reset-password',
    component: ResetPassword,
    layout: AuthLayoutHalf,
    isAuthScreen: true,
  },
  {
    path: '/reporting',
    component: Reporting,
    layout: MainLayout,
    heading: 'Analytics',
    isSecured: true,
    isShowRangePicker: true,
  },
  {
    path: '/',
    component: DashboardPage,
    layout: MainLayout,
    heading: '',
    isSecured: true,
  },
  {
    path: '/reporting',
    component: ReportingDashboard,
    layout: MainLayout,
    heading: 'Analytics',
    isSecured: true,
    isShowRangePicker: true,
  },
  {
    path: '/agency-profile',
    component: AgencyProfilePage,
    layout: MainLayout,
    heading: 'Company Profile',
  },
  {
    path: '/user-groups',
    component: UserGroups,
    layout: MainLayout,
    heading: 'Users',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/candidate-list',
    component: CandidateListPage,
    layout: MainLayout,
    heading: 'Candidates',
    isSecured: true,
  },
  {
    path: '/candidate-list/candidate-profile',
    component: CandidateProfilePage,
    layout: MainLayout,
    heading: 'Candidate Profile',
    isBackButton: 'Back to Candidate List',
    isSecured: true,
  },
  {
    path: '/questionnaires',
    component: QuestionnaireTabsPage,
    layout: MainLayout,
    heading: 'Questionnaires',
    isSecured: true,
    subHeading: '',
  },
  {
    path: '/questionnaires/new',
    component: QuestionnaireBuilderPage,
    layout: MainLayout,
    heading: 'Build New Questionnaire',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/questionnaires/:questionnaireId/edit',
    component: QuestionnaireBuilderPage,
    layout: MainLayout,
    heading: 'Edit Questionnaire',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/user-profile',
    component: UserProfile,
    layout: MainLayout,
    heading: 'User profile',
    subHeading:
      'Personal info and options to manage it. You can also see a summary of your profiles.',
    isSecured: true,
  },
  {
    path: '*',
    component: Error404,
    isSecured: true,
  },
  {
    path: '/user-groups/user-list',
    component: UserList,
    layout: MainLayout,
    heading: 'Users',
    subHeading: 'Manage your team members and their account permissions here.',
    isSecured: true,
  },
  {
    path: '/settings',
    component: SettingsPage,
    layout: MainLayout,
    heading: 'Settings',
    subHeading:
      'Manage your team members and their account permissionscompany settings here.',
    isSecured: true,
  },
  {
    path: '/super-admin',
    component: SuperAdminDashboardPage,
    layout: MainLayout,
    heading: 'Super Admin Dashboard',
    subHeading: '',
    isSecured: true,
  },
  {
    path: '/super-admin/:tenantId',
    component: AgencyConfigurationPage,
    layout: MainLayout,
    heading: 'Company Configurations',
    subHeading: '',
    isSecured: true,
  },
]

export default routes
