import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getReportSettings } from 'features/settings/store'
import { ReferenceCheckType } from 'types'

import { ReferenceTypeLabel } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { cn, dateToReadableString, timeToAESTWithSeconds } from 'utils'

import { Details } from './ReportDetailColumns/DetailColumn'
import { ReportDetailColumns } from './ReportDetailColumns'

type ReportRefereeDetailsSectionProps = {
  refereeNumber?: number
  referee: any
  refereeCount: number
}

export const ReportRefereeDetailsSection = ({
  refereeNumber = 1,
  referee,
  refereeCount,
}: ReportRefereeDetailsSectionProps) => {
  const { privateReportsEmailPhone, privateReportsName } =
    useSelector(getReportSettings)

  const leftDetails = useMemo(() => {
    const details: Details[] = []

    if (!privateReportsName) {
      details.push({
        label: 'Referee Name',
        value: `${referee.firstName}  ${referee.lastName}`,
        type: 'text',
      })
    }

    if (!privateReportsEmailPhone && !privateReportsName) {
      details.push(
        {
          label: 'Email',
          value: referee.email || 'NA',
          type: 'text',
        },
        {
          label: 'Phone',
          value: referee.phone || 'NA',
          type: 'text',
        },
      )
    }

    if (referee?.referenceType?.type != ReferenceCheckType.FriendFamily)
      details.push(
        {
          label: 'Company',
          value: referee.company || 'NA',
          type: 'text',
        },
        {
          label: "Referee's Job Title",
          value: referee.jobTitle || 'NA',
          type: 'text',
        },
      )

    return details
  }, [privateReportsEmailPhone, privateReportsName, referee])

  const rightDetails: Details[] = useMemo(
    () => [
      {
        label: 'Response Date',
        value: referee?.refereeResponse?.updatedOn
          ? timeToAESTWithSeconds(referee?.refereeResponse?.updatedOn)
          : 'NA',
        type: 'text',
      },
      {
        label: 'Reference Type',
        component: (
          <p className='mt-1 text-base text-right text-Gray-600 font-Medium'>
            {referee?.referenceType?.type ? (
              <ReferenceTypeLabel type={referee?.referenceType?.type} />
            ) : (
              'NA'
            )}
          </p>
        ),
        type: 'component',
      },
      {
        label: 'Reference Check',
        component: (
          <p className='mt-1 text-base text-right text-Gray-600 font-Medium'>
            {referee.refereeResponse?.responseType === 'PHONE_CHECK' && (
              <Row className='bg-Cobalt-100 rounded-[10px] h-[20px] space-x-2 items-center w-[78px]'>
                <i className='pl-2 ri-phone-line text-extra-small text-Cobalt-800' />
                <span className='text-extra-small font-Medium text-Cobalt-800'>
                  Phone
                </span>
              </Row>
            )}
            {referee.refereeResponse?.responseType === 'WEB_CHECK' && (
              <Row className='bg-Cobalt-100 rounded-[10px] h-[20px] space-x-2 items-center w-[100px]'>
                <i className='pl-2 ri-global-line text-extra-small text-Cobalt-800' />
                <span className='text-extra-small font-Medium text-Cobalt-800'>
                  Web Form
                </span>
              </Row>
            )}
          </p>
        ),
        type: 'component',
      },
      {
        label: 'Status',
        component: (
          <p
            className={cn(
              'flex items-center px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px] mt-1',
              {
                'bg-Green-50 border-Green-500': referee.status === 'Passed',
                'bg-Red-50 border-Red-500': referee.status === 'Failed',
                'bg-Yellow-50 border-Yellow-500':
                  referee.status === 'Incomplete',
                'bg-Gray-50 border-Gray-500':
                  referee.status === null || referee.status == 'NotSent',
              },
            )}>
            <span
              className={cn('text-extra-small text-right font-Medium', {
                'text-Green-600': referee.status === 'Passed',
                'text-Red-600': referee.status === 'Failed',
                'text-Yellow-600': referee.status === 'Incomplete',
                'text-Gray-600':
                  referee.status === null || referee.status == 'NotSent',
              })}>
              {referee.status || 'NA'}
            </span>
          </p>
        ),
        type: 'component',
      },
    ],
    [referee],
  )

  const { finalLeftDetails, finalRightDetails } = useMemo(() => {
    let finalLeftDetails, finalRightDetails

    if (leftDetails.length > 0) {
      finalLeftDetails = leftDetails
      finalRightDetails = rightDetails
    } else {
      finalLeftDetails = rightDetails.slice(0, 2)
      finalRightDetails = rightDetails.slice(2, 4)
    }

    return { finalLeftDetails, finalRightDetails }
  }, [leftDetails, rightDetails])

  return (
    <div className='px-6'>
      <div className='pt-4 mt-5'>
        <ReportDetailColumns
          title={
            refereeCount > 1 ? `Referee No ${refereeNumber}` : 'Referee Details'
          }
          hasMiddleDivider={true}
          leftDetails={finalLeftDetails}
          rightDetails={finalRightDetails}
        />
      </div>
      <div className='w-full h-[28px]'>
        <span className='px-4 text-base leading-7 text-white font-Medium'>
          Referee No {refereeNumber}
        </span>
      </div>

      {referee?.referenceType?.type == ReferenceCheckType.Employment && (
        <Column className='mb-4'>
          <span className='pb-2 text-left text-black border-b text-heading-5 font-Medium border-Gray-200'>
            Employment coverage
          </span>
          <div className='mt-2'>
            <Row>
              <i className='mr-1 ri-checkbox-circle-fill text-[#137E30]' />
              <span className='text-Gray-400'>Confirmed Employment Dates</span>
            </Row>

            <span className='text-Gray-800 font-Medium text-small'>
              {dateToReadableString(referee?.startDate)} -{' '}
              {referee?.isCurrentlyWorking
                ? 'Currently working'
                : dateToReadableString(referee?.endDate)}
            </span>
          </div>
        </Column>
      )}

      {referee.refereeResponse?.fraudDetectedDate && (
        <Column className='mt-3 space-y-2.5 mb-4'>
          <div className='flex flex-row items-center w-full p-4 mt-5 border rounded bg-Red-50 border-Red-300'>
            <i className='ri-close-circle-fill text-Red-500 text-[16px] mr-[10px]' />
            <span className='text-base text-Red-800 font-Medium'>
              Fraud Detected. Candidate IP and referee IP are identical.
            </span>
          </div>
        </Column>
      )}
    </div>
  )
}
