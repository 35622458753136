import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Modal,
  SimpleSelect,
  TextArea,
  Toast,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { PoliceCheckType } from 'features/ncc'
import {
  AppSettingKey,
  getAgencyAppSettings,
  isSettingEnabled,
  isSettingsExist,
} from 'features/settings'
import { getSubscriptionStore } from 'features/subscriptions'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { PageLoader } from 'components/atoms'
import { timeToAESTWithSeconds } from 'utils'
import { downloadDocument } from 'utils/downloadDocument'

import { useAddPoliceCheck } from '../../../api/useAddPoliceCheck'
import { useDownloadCheckReport } from '../../../api/useDownloadCheckReport'
import {
  checkHealthcareListForHealth,
  checkHealthcareListForNonHealth,
  checkTypeList,
} from '../../../data'

import { PoliceCheckPlanBadge } from './PoliceCheckPlanBadge'

type PoliceCheckDetailsCardProps = {
  onEditClick?: () => void
  isLowCredit?: boolean
  [x: string]: any
}

export const PoliceCheckDetailsCard = ({
  className,
  candidateId,
  candidateName,
  isLoading,
  policeCheckDetailsData,
  getPoliceCheckDetails,
  policeCheckConsultantName,
  ...restProps
}: PoliceCheckDetailsCardProps) => {
  const dispatch = useDispatch()

  const [isActiveAddPoliceCheckModal, setIsActiveAddPoliceCheckModal] =
    useState(false)

  const {
    payAsYouGoQuotas: { PoliceCheck, VolunteerCheck },
  } = useSelector(getSubscriptionStore)

  const agencyAppSettings = useSelector(getAgencyAppSettings)
  const { isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )

  const [isSettingDisabled, setIsSettingDisabled] = useState(true)

  useEffect(() => {
    if (isSettingsExist(agencyAppSettings)) {
      setIsSettingDisabled(
        !isSettingEnabled(AppSettingKey.NccPoliceCheck, agencyAppSettings),
      )
    }
  }, [agencyAppSettings])

  const [policeCheckData, setPoliceCheckData] = useState({
    checkType: 'EMPLOYMENT',
    checkHealthcare: 'NONE',
    reason: '',
  })

  const [healthCareList, setHealthCareList] = useState<any>([])

  useEffect(() => {
    if (
      policeCheckData?.checkType === PoliceCheckType.EMPLOYMENT_HEALTH ||
      policeCheckData?.checkType === PoliceCheckType.VOLUNTEER_HEALTH ||
      policeCheckData?.checkType === PoliceCheckType.WORK_EXP_HEALTH
    ) {
      setHealthCareList(checkHealthcareListForHealth)
      setPoliceCheckData({
        checkType: policeCheckData?.checkType,
        checkHealthcare: checkHealthcareListForHealth[0].value,
        reason: policeCheckData?.reason,
      })
    } else {
      setHealthCareList(checkHealthcareListForNonHealth)
      setPoliceCheckData({
        checkType: policeCheckData?.checkType,
        checkHealthcare: checkHealthcareListForNonHealth[0].value,
        reason: policeCheckData?.reason,
      })
    }
  }, [policeCheckData.checkType])

  /** API call for the add police check */
  const { mutate: addPoliceCheckMutate, isLoading: addPoliceCheckIsLoading } =
    useAddPoliceCheck()

  /** Process the add police check */
  const addPoliceCheck = () => {
    if (getRemainingQuota() === 0) {
      Toast({
        alertHeader: 'Insufficient Credit',
        status: 'Warning',
      })

      return
    }
    addPoliceCheckMutate(
      {
        CandidateId: candidateId,
        CheckType: policeCheckData?.checkType,
        HealthcareType: policeCheckData?.checkHealthcare,
        Reason: policeCheckData.reason,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })
          setIsActiveAddPoliceCheckModal(false)
          getPoliceCheckDetails()
          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
          setIsActiveAddPoliceCheckModal(false)
        },
      },
    )
  }

  /** download police check report on success */
  const onDownloadPoliceCheckSuccess = ({ data }: any) => {
    downloadDocument(`${candidateName}_Police_Check.pdf`, data)
  }

  /** download police check report on error */
  const onDownloadPoliceCheckError = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: errData.messageTitle
        ? errData.messageTitle
        : 'Error Downloading Report.',

      status: 'Error',
    })
  }

  /** API Handling request for download police check report */
  const {
    refetch: downloadCheckReport,
    isLoading: downloadCheckReportIsLoading,
    isFetching: downloadCheckReportIsFetching,
  } = useDownloadCheckReport(
    {
      id: candidateId,
      type: 'PoliceCheck',
    },
    onDownloadPoliceCheckSuccess,
    onDownloadPoliceCheckError,
  )

  const PoliceCheckDetailsCardClasses = CN(
    `eligibility-police-check-details-card border bg-white border-Gray-200 rounded-md pb-8 pt-4 px-5 mt-5 relative min-h-[200px] w-1/2`,
    className,
  )

  const getRemainingQuota = (): number => {
    if (
      policeCheckData.checkType === PoliceCheckType.VOLUNTEER ||
      policeCheckData.checkType === PoliceCheckType.VOLUNTEER_HEALTH
    ) {
      return VolunteerCheck.remainingQuota
    }

    return PoliceCheck.remainingQuota
  }

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value

    if (newValue.length <= 100) {
      setPoliceCheckData({
        ...policeCheckData,
        reason: newValue,
      })
    } else {
      Toast({
        alertHeader: 'Character Limit Exceeded',
        status: 'Warning',
      })
    }
  }

  return (
    <div className={PoliceCheckDetailsCardClasses} {...restProps}>
      {/* loader */}
      {(isLoading || addPoliceCheckIsLoading) && <PageLoader size='xxs' />}

      {/* top header */}
      <div className='flex justify-between'>
        {/* header */}
        <span className='font-Bold text-heading-5 text-Gray-800'>
          Police Check
        </span>
        {!policeCheckDetailsData && (
          <PoliceCheckPlanBadge
            policeCheck={PoliceCheck}
            volunteerCheck={VolunteerCheck}
          />
        )}

        {policeCheckDetailsData && (
          <Button
            size='sm'
            isLoading={
              downloadCheckReportIsLoading || downloadCheckReportIsFetching
            }
            isDisabled={
              policeCheckDetailsData?.resultUrl === null ? true : false
            }
            onClick={() => downloadCheckReport()}
            textTheme='white'
            theme='cobalt'>
            Download Report
          </Button>
        )}
      </div>

      {policeCheckDetailsData && (
        <table className='flex flex-col pt-8'>
          <tbody className='w-full'>
            {/* status section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Check Status
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {policeCheckDetailsData?.checkStatus === 'PREP'
                  ? 'Sent to Candidate'
                  : policeCheckDetailsData?.checkStatus === 'SENT'
                  ? 'Processing'
                  : policeCheckDetailsData?.checkStatus === 'UTP'
                  ? 'Technical or legal, Pleases contact support'
                  : policeCheckDetailsData?.checkStatus === 'RECEIVED'
                  ? 'Received'
                  : policeCheckDetailsData?.checkStatus}
              </td>
            </tr>

            {/* request sent section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Request Sent
              </td>
              <td className='text-Gray-800 font-SemiBold text-small'>
                Sent on{' '}
                {timeToAESTWithSeconds(policeCheckDetailsData?.createdOn)}
              </td>
            </tr>

            {/* outcome received section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Outcome Received
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {policeCheckDetailsData?.resultReceivedDate === null
                  ? 'Processing'
                  : timeToAESTWithSeconds(
                      policeCheckDetailsData?.resultReceivedDate,
                    )}
              </td>
            </tr>

            {/* Managing Consultant section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Managing Consultant
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {policeCheckConsultantName}
              </td>
            </tr>

            {/*  result section */}
            <tr className='flex items-center w-full mt-2'>
              <td className='w-1/4 text-extra-small text-Gray-600 font-Medium'>
                Result
              </td>

              <td className='text-Gray-800 font-SemiBold text-small'>
                {policeCheckDetailsData?.result === 'UNKNOWN'
                  ? 'Incomplete'
                  : policeCheckDetailsData?.result === 'NDCO'
                  ? 'NDCO: Check DOES NOT have disclosable court outcomes. '
                  : policeCheckDetailsData?.result === 'DCO'
                  ? 'DCO : Check DOES have disclosable court outcomes'
                  : policeCheckDetailsData?.result === 'RECEIVED'
                  ? 'Completed'
                  : policeCheckDetailsData?.result}
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {/* empty state */}
      {!isLoading && !policeCheckDetailsData && (
        <div className='flex flex-col items-center justify-center mt-10 mb-6'>
          <i className='ri-police-car-line text-[52px] text-Gray-500' />

          <span className='pt-2 text-heading-4 font-Medium text-Gray-800'>
            Conduct a Police Check
          </span>

          <span className='pt-2 text-center text-small font-Regular text-Gray-800'>
            Looks like you haven't started the police check process for this
            candidate
          </span>
          <Button
            iconLeft={<i className='ri-add-line' />}
            onClick={() => {
              setIsActiveAddPoliceCheckModal(true)
            }}
            isDisabled={
              isSettingDisabled ||
              (PoliceCheck.remainingQuota === 0 &&
                VolunteerCheck.remainingQuota === 0)
            }
            className='mt-6'
            textTheme='white'
            theme='cobalt'>
            Add Police Check
          </Button>
        </div>
      )}

      {/* add police check modal */}
      <Modal
        isActive={isActiveAddPoliceCheckModal}
        onClickPrimaryBtn={() => {
          // Check if the reason is empty
          if (policeCheckData.reason.trim() == '') {
            Toast({
              alertHeader: 'Reason is required',
              status: 'Warning',
            })
          } else {
            addPoliceCheck() // Proceed with the police check request
          }
        }}
        className='!z-[150]'
        onClickSecondaryBtn={() => setIsActiveAddPoliceCheckModal(false)}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        modalProps={{
          style: { width: '480px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Request Check'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-error-warning-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-center text-Gray-900 text-heading-5 font-Medium'>
            This will deduct one Police Check Credit from your account
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            You currently have {getRemainingQuota()} credits remaining.
          </span>

          {/*  type section */}
          <SimpleSelect
            className='w-3/4 mt-5 type'
            isRequired
            value={{
              label: policeCheckData?.checkType,
              value: policeCheckData?.checkType,
            }}
            onChange={(selectedItem: any) => {
              setPoliceCheckData({
                ...policeCheckData,
                checkType: selectedItem.value,
                checkHealthcare: '',
              })
            }}
            label='Check Types'
            options={checkTypeList}
            placeholder='Select...'
          />

          {/*  healthcare section */}
          {healthCareList[0]?.value !== 'NONE' && (
            <SimpleSelect
              className='w-3/4 mt-5 type'
              isRequired
              value={{
                label: policeCheckData?.checkHealthcare,
                value: policeCheckData?.checkHealthcare,
              }}
              onChange={(selectedItem: any) => {
                setPoliceCheckData({
                  ...policeCheckData,
                  checkHealthcare: selectedItem.value,
                })
              }}
              label='Check Healthcare'
              options={healthCareList}
              placeholder='Select...'
            />
          )}
          <TextArea
            className='w-3/4 mt-5 type'
            isRequired
            value={policeCheckData.reason}
            onChange={handleReasonChange}
            label='Reason'
            placeholder='Enter reason'
            rows={4}
          />
        </div>
      </Modal>
    </div>
  )
}

PoliceCheckDetailsCard.defaultProps = {
  isLoading: false,
}

export default PoliceCheckDetailsCard
