/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { CandidateProfileBasicDetailsType } from 'framework/api/methods'

import { MoreMenuButton, PageLoader, ProfileAvatar } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { timeToAESTWithSeconds } from 'utils'

import { CandidateMarkingStatus } from '../data'

import { MarkCandidateSwitch } from './MarkCandidateSwitch'

import 'react-circular-progressbar/dist/styles.css'

type CandidateDetailsCardProps = {
  candidateDetails: CandidateProfileBasicDetailsType | undefined
  getCandidateById: () => void
  onCandidateEditClick: () => void
  onCandidateDeleteClick: () => void
  onAddNewPhotoClick: () => void
  onEditPhotoClick: () => void
  onDownlaodReferenceReportClick: () => void
  onDeletePhotoClick: () => void
  contactButtonOnClick: () => void
  onRequestRefereeClick: () => void
  onRequestRefereeReminderClick: () => void
  isSurveyEnabled?: boolean
  [x: string]: any
}

export const CandidateDetailsCard = ({
  className,
  onCandidateEditClick,
  onCandidateDeleteClick,
  onAddNewPhotoClick,
  onEditPhotoClick,
  onDeletePhotoClick,
  onRequestRefereeClick,
  onRequestRefereeReminderClick,
  getCandidateById,
  candidateDetails,
  setMarkAsPassFailModal,
  onDownlaodReferenceReportClick,
  markAsPassFailModal,
  loadingCandidateById,
  hasValidDownloadableReferees,
  isSurveyEnabled,
  ...restProps
}: CandidateDetailsCardProps) => {
  const CandidateDetailsCardClasses = CN(
    `candidate-details-card flex w-full bg-white border border-Gray-200 rounded-[8px] p-5 relative`,
    className,
  )

  const refereeRequestDate = timeToAESTWithSeconds(
    candidateDetails?.requestSendDate,
  )

  const refereeReminderDate = timeToAESTWithSeconds(
    candidateDetails?.lastSendRequest,
  )

  const refereeResponseValue = useMemo(() => {
    return candidateDetails?.percentage !== null &&
      candidateDetails?.percentage !== undefined
      ? parseInt(candidateDetails?.percentage)
      : -1
  }, [candidateDetails])

  return (
    <div className={CandidateDetailsCardClasses} {...restProps}>
      {loadingCandidateById && <PageLoader size='xxs' className='absolute' />}
      <div className='mr-12'>
        <ProfileAvatar
          isProfilePictureAvailable={candidateDetails?.avatarUrl ? true : false}
          imageSource={candidateDetails?.avatarUrl || undefined}
          imageContainerClassName='w-[132px] h-[132px]'
          onAddClick={() => {
            onAddNewPhotoClick()
          }}
          onDeleteClick={() => {
            onDeletePhotoClick()
          }}
          onEditClick={() => {
            onEditPhotoClick()
          }}
          isStatusDisable={false}
        />
      </div>
      <Column className='w-full'>
        <div className='flex justify-between w-full mb-3'>
          <span className='flex flex-col mb-1 items-start w-80'>
            <span className='text-[20px] mb-1 text-Gray-800 font-Bold'>
              {candidateDetails?.name}
            </span>
            {isSurveyEnabled && (
              <MarkCandidateSwitch
                candidateDetails={candidateDetails}
                getCandidateById={getCandidateById}
              />
            )}
          </span>

          <Row className='w-1/2 h-[38px] justify-end'>
            <Button
              size='sm'
              isDisabled={!hasValidDownloadableReferees}
              className='mx-2'
              iconLeft={
                <i className='flex items-center ri-download-line text-small' />
              }
              onClick={onDownlaodReferenceReportClick}>
              <span className='text-small font-Medium'> Download Report</span>
            </Button>

            <MoreMenuButton
              actionButtons={[
                {
                  onClick: () => {
                    onRequestRefereeClick()
                  },
                  title: 'Request Referee Details',
                  status: 'normal',
                  disabled:
                    candidateDetails?.remainingRefereeCount == 0 ||
                    (candidateDetails?.requestSendDate !== null &&
                      candidateDetails?.remainingRefereeCount == 0),
                },
                {
                  onClick: () => {
                    onRequestRefereeReminderClick()
                  },
                  title: 'Send Reminder (Request Referees)',
                  status: 'normal',
                  disabled:
                    candidateDetails?.remainingRefereeCount == 0 ||
                    (candidateDetails?.requestSendDate !== null &&
                      candidateDetails?.remainingRefereeCount == 0),
                },
                {
                  onClick: () => {
                    onCandidateEditClick && onCandidateEditClick()
                  },
                  title: 'Edit Candidate',
                  status: 'normal',
                },
                {
                  onClick: () => {
                    onCandidateDeleteClick()
                  },
                  title: 'Delete',
                  status: 'danger',
                  isBottomSeparator: true,
                },
                {
                  onClick: () => {
                    setMarkAsPassFailModal({
                      ...markAsPassFailModal,
                      type: 'Passed',
                      isActive: true,
                    })
                  },
                  title: 'Mark as Passed & Completed',
                  status: 'normal',
                  disabled:
                    (candidateDetails?.markedStatus !== null &&
                      candidateDetails?.markedStatus ===
                        CandidateMarkingStatus.Passed) ||
                    candidateDetails?.isEmployee === true
                      ? true
                      : false,
                },
                {
                  onClick: () => {
                    setMarkAsPassFailModal({
                      ...markAsPassFailModal,
                      type: 'Failed',
                      isActive: true,
                    })
                  },
                  title: 'Mark as Failed & Completed',
                  status: 'normal',
                  disabled:
                    (candidateDetails?.markedStatus !== null &&
                      candidateDetails?.markedStatus ===
                        CandidateMarkingStatus.Failed) ||
                    candidateDetails?.isEmployee === true
                      ? true
                      : false,
                },
              ]}
            />
          </Row>
        </div>
        <div className='flex w-full'>
          <table className='table-auto  w-[35%] mr-2'>
            <tbody className='w-full'>
              <tr className='flex items-start w-full mb-1'>
                <td className='text-extra-small text-Gray-600 mr-[10px] font-Medium w-1/5 pt-[2.5px]'>
                  ID No
                </td>
                <td className='w-4/5 text-small font-SemiBold text-Gray-800'>
                  {candidateDetails?.candidateNo}
                </td>
              </tr>
              <tr className='flex items-start w-full mb-1'>
                <td className='text-extra-small text-Gray-600 mr-[10px] font-Medium w-1/5 pt-[2.5px]'>
                  Email
                </td>
                <td className='w-4/5 break-all text-small font-SemiBold text-Gray-800'>
                  {!candidateDetails?.email.includes('@noreply.com') ? (
                    <a
                      href={`mailto:${candidateDetails?.email}`}
                      className='text-small text-Cobalt-500 font-SemiBold'>
                      {candidateDetails?.email}
                    </a>
                  ) : (
                    <span className='text-small font-SemiBold'>N/A</span>
                  )}
                </td>
              </tr>
              <tr className='flex items-start w-full mb-1'>
                <td className='text-extra-small text-Gray-600 mr-[10px] font-Medium w-1/5 pt-[2.5px]'>
                  Phone
                </td>
                <td className='w-4/5 text-small font-SemiBold text-Gray-800'>
                  <a
                    href={`tel:${candidateDetails?.phone}`}
                    className='text-small text-Cobalt-500 font-SemiBold'>
                    {candidateDetails?.phone}
                  </a>
                </td>
              </tr>
              <tr className='flex items-start w-full'>
                <td className='text-extra-small text-Gray-600 mr-[10px] font-Medium w-1/5 pt-[2.5px]'>
                  Job Title
                </td>
                <td className='w-4/5 break-all text-small font-SemiBold text-Gray-800'>
                  {candidateDetails?.jobTitle
                    ? candidateDetails?.jobTitle
                    : `-`}
                </td>
              </tr>
            </tbody>
          </table>

          <div className='flex flex-row justify-between w-[65%]'>
            <div className=''>
              <div className='flex items-center mb-2'>
                <span className='w-[136px] font-Medium text-extra-small text-Gray-600'>
                  Referees #
                </span>

                <span className='text-Gray-800 text-small font-SemiBold'>
                  {candidateDetails?.refereeCount}
                </span>
              </div>

              <div className='flex items-center mb-2'>
                <span className='w-[136px] font-Medium text-extra-small text-Gray-600'>
                  Referee Requests
                </span>

                <span className='text-Gray-800 text-small font-SemiBold line-clamp-1'>
                  {candidateDetails?.requestSendDate
                    ? `Sent on ${refereeRequestDate}`
                    : 'Never'}
                </span>
              </div>

              <div className='flex justify-between mb-2'>
                <div>
                  <div className='flex items-center'>
                    <span className='w-[136px] font-Medium text-extra-small text-Gray-600'>
                      Referee Reminder
                    </span>

                    <span className='text-Gray-800 text-small font-SemiBold'>
                      {candidateDetails?.lastSendRequest
                        ? `Last sent on ${refereeReminderDate}`
                        : 'Never'}
                    </span>
                  </div>
                </div>
              </div>

              <div className='flex items-start mb-2'>
                <div className='flex items-start'>
                  <span className='w-[136px] font-Medium text-extra-small text-Gray-600 pt-[2px]'>
                    Managing Consultant
                  </span>

                  <span className='text-Gray-800 text-small font-SemiBold break-all max-w-[350px]'>
                    {candidateDetails?.consultantName
                      ? candidateDetails?.consultantName
                      : `-`}
                  </span>
                </div>
              </div>
            </div>

            <div className='flex flex-row-reverse'>
              <div className='w-[88px] h-[88px]'>
                <CircularProgressbarWithChildren
                  value={refereeResponseValue}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                      // Path color
                      stroke:
                        refereeResponseValue < 40
                          ? '#FF4F3F'
                          : refereeResponseValue > 40 &&
                            refereeResponseValue <= 74
                          ? '#CA7308'
                          : '#31BA55',
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'round',
                      // Customize transition animation
                      transition: 'stroke-dashoffset 0.5s ease 0s',
                      // Rotate the path
                      transform: 'rotate(1turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                      // Trail color
                      stroke: '#F3F4F6',
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: 'round',
                      // Rotate the trail
                      transform: 'rotate(0.25turn)',
                      transformOrigin: 'center center',
                    },
                    // Customize the text
                    text: {
                      // Text color
                      fill: '#FF4F3F',
                      // Text size
                      fontSize: '28px',
                      lineHeight: '36px',
                      fontWeight: '500',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                      fill: '#3e98c7',
                    },
                  }}>
                  <div className='flex items-center'>
                    {refereeResponseValue !== -1 ? (
                      <>
                        <span
                          className={CN('text-[28px] font-Medium leading-9', {
                            'text-Red-500': refereeResponseValue <= 40,
                            'text-Yellow-500':
                              refereeResponseValue > 40 &&
                              refereeResponseValue <= 74,
                            'text-Green-500': refereeResponseValue > 74,
                          })}>
                          {refereeResponseValue}
                        </span>
                        <span
                          className={CN('text-[16px] font-SemiBold leading-4', {
                            'text-Red-500': refereeResponseValue <= 40,
                            'text-Yellow-500':
                              refereeResponseValue > 40 &&
                              refereeResponseValue <= 74,
                            'text-Green-500': refereeResponseValue > 74,
                          })}>
                          %
                        </span>
                      </>
                    ) : (
                      <span className='text-[14px] font-SemiBold leading-[14px] text-Gray-500'>
                        Pending
                      </span>
                    )}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
        </div>
      </Column>
    </div>
  )
}
