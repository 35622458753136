import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

export type SetupTwoFAResponse = {
  isTwoFAEnabled: boolean
  secretKey: string
  authenticatorUri: string
}

export const useSetupTwoFA = (
  email: string,
  onSuccess: (data: SetupTwoFAResponse) => void,
) => {
  return useQuery(
    ['identity_2fa', email],
    async () => {
      const res = await get(`/identity/2fa/setup?email=${email}`)
      return res.data
    },
    {
      enabled: email != '',
      refetchOnWindowFocus: false,
      onSuccess,
    },
  )
}
