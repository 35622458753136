import addIcon from 'assets/images/notes/add.svg'
import errorIcon from 'assets/images/notes/error.svg'
import infoIcon from 'assets/images/notes/info.svg'
import noteIcon from 'assets/images/notes/note.svg'
import sendIcon from 'assets/images/notes/send.svg'
import successIcon from 'assets/images/notes/success.svg'

import { ColorizeWordsInBrackets } from './ColorizeWordsInBrackets'

type HistoryTabProps = {
  icon?: 'Success' | 'Info' | 'Error' | 'Send' | 'Add' | 'Note'
  title: string
  date: string
  description?: string
}

const iconMappings = {
  Success: successIcon,
  Info: infoIcon,
  Error: errorIcon,
  Add: addIcon,
  Send: sendIcon,
  Note: noteIcon,
}

export const HistoryItemCard = ({
  date,
  description,
  icon = 'Note',
  title,
}: HistoryTabProps) => {
  return (
    <div className='flex flex-col w-full p-4 mb-3 bg-white border rounded-lg border-Gray-200'>
      <div className='flex items-start justify-start'>
        {/* <i
          className={CN('text-[18px] leading-[20px]', {
            'ri-checkbox-circle-line text-Green-500': type == 'Success',
            'ri-error-warning-line text-Gray-400': type == 'Info',
            'ri-error-warning-line text-Red-600': type == 'Error',
            'ri-send-plane-fill text-Gray-400': type == 'Send',
            'ri-user-add-line text-Gray-400': type == 'Add',
            'ri-sticky-note-line text-Gray-400': type == 'Note',
          })}></i> */}

        <img src={iconMappings[icon] || noteIcon} alt='' className='w-5 h-5' />

        {title && (
          <div className='flex pl-2 break-all'>
            <ColorizeWordsInBrackets text={title} />
          </div>
        )}
      </div>
      <div className='flex text-extra-small text-Gray-600 pl-[28px] mt-[2px]'>
        {description}
      </div>
      <div className='flex text-extra-small text-Gray-400 pl-[28px] mt-2'>
        {date || `12.11 PM,24 Aug 2023`}
      </div>
    </div>
  )
}
