import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

import { ReportSettingItem } from './getReportSettings'

export type UpdateReportSettingsRequest = {
  reportSettings: ReportSettingItem[]
}

export const useUpdateReportSettings = () => {
  const mutate = useMutation(
    ({ reportSettings }: UpdateReportSettingsRequest) => {
      return putAPI(`/reports`, { reportSettings })
    },
  )

  return mutate
}
