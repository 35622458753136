import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import refHubLogo from 'assets/images/refHubLogo.svg'
import CN from 'classnames'
import { useBrowserDetection } from 'hooks'
import { setIsFooterShown } from 'store/reducers/layout/layoutSlice'

import { BrowserBlockedPage } from 'components/common/BrowserBlockedPage'
import { Footer } from 'components/common/Footer'
import { MobileBlockedOverlay } from 'components/common/MobileBlockedOverlay'
import { useWidth } from 'utils'

export type AuthLayoutHalfProps = {
  className?: string | undefined
  isFooterShown: boolean | undefined
  [x: string]: any
}

export const AuthLayoutHalf: FC<AuthLayoutHalfProps> = ({
  children,
}: AuthLayoutHalfProps) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { isFooterShown } = useSelector((state: any) => state.layout)

  useEffect(() => {
    if (location.pathname === '/auth/sign-up') {
      dispatch(setIsFooterShown(false))
    } else {
      dispatch(setIsFooterShown(true))
    }
  }, [location])

  const ref = useRef(null)

  const [width] = useWidth(ref)

  const browserInfo = useBrowserDetection()

  const isBrowserNotAllowed = browserInfo.isFirefox

  const [isMobileUsing, setIsMobileUsing] = useState(false)

  useEffect(() => {
    if (width && width < 840) {
      setIsMobileUsing(true)
    }

    if (width && width > 840) {
      setIsMobileUsing(false)
    }
  }, [width])

  return (
    <div className='flex absolute h-full w-full' ref={ref}>
      {isMobileUsing && <MobileBlockedOverlay mailto='info@refhub.com.au' />}
      {isBrowserNotAllowed && (
        <BrowserBlockedPage mailto='info@refhub.com.au' />
      )}

      <div className='left-side w-2/5 h-full login-left-background-image'>
        <img src={refHubLogo} alt='' className='ml-[60px] mt-10 h-[30px]' />
      </div>

      <div
        className={CN(
          'right-side w-3/5 flex items-center justify-center relative',
          {
            'pb-[100px]': isFooterShown,
          },
        )}>
        {children}
        {isFooterShown && <Footer />}
      </div>
    </div>
  )
}

AuthLayoutHalf.defaultProps = {
  className: undefined,
  isFooterShown: true,
}

export default AuthLayoutHalf
