import CN from 'classnames'

import { dateToReadableString } from 'utils'

type CreditListItemProps = {
  icon: string
  title: string
  exprireDate?: string
  quota: number
}

export const CreditListItem = ({
  icon,
  title,
  exprireDate,
  quota = 0,
}: CreditListItemProps) => {
  return (
    <div className='flex items-center py-2'>
      <div className='flex items-center  justify-center h-[40px] w-[40px] rounded-full bg-Cobalt-50'>
        <i className={CN('text-Cobalt-200 text-heading-4', icon)} />
      </div>
      <div className='flex-grow mx-2'>
        <h3 className='text-Gray-800 font-SemiBold'>{title}</h3>
        {/* <p className='text-Gray-500'>
          Expires: {exprireDate ? dateToReadableString(exprireDate) : 'NA'}
        </p> */}
      </div>
      <div className='ml-auto text-heading-5 font-SemiBold'>{quota}</div>
    </div>
  )
}
