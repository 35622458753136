import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useVerifyAccount } from 'framework/api/methods'

import { FailIcon, PageLoader, SuccessIcon } from 'components/atoms'

type VerifyAccountProps = {
  [x: string]: any
}

export const VerifyAccount = ({
  className,
  ...restProps
}: VerifyAccountProps) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [routeData, setRouteData] = useState<any>({
    userName: null,
    emailToken: null,
  })

  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  /** get email token and user name from route params */
  useEffect(() => {
    const userName: any = searchParams.get('userName')
    const emailToken: any = searchParams.get('emailToken')
    if (
      userName == null ||
      userName == '' ||
      emailToken == null ||
      emailToken == ''
    ) {
      navigate('/')
    } else {
      setRouteData({
        ...routeData,
        userName: userName,
        emailToken: emailToken,
      })
    }
  }, [searchParams])

  /** verify account API call */
  const verifyAccountSuccess = () => {
    setIsSuccess(true)
  }
  const verifyAccountOnError = ({ response: { data: errData } }: any) => {
    setIsSuccess(false)

    setErrorMessage(errData?.message)
  }

  const { isFetching: verifyAccountIsFetching } = useVerifyAccount(
    {
      emailToken: routeData?.emailToken,
      userName: routeData?.userName,
    },
    verifyAccountSuccess,
    verifyAccountOnError,
  )

  const VerifyAccountClasses = CN(
    `verify-account flex flex-col justify-center items-center`,
    className,
  )

  return (
    <div className={VerifyAccountClasses} {...restProps}>
      {verifyAccountIsFetching && <PageLoader size='xxs' />}

      {!verifyAccountIsFetching ? (
        <div className='flex flex-col md:w-[350px] w-full justify-center items-center md:px-0 px-4'>
          {/* success/fail icon */}
          {isSuccess ? <SuccessIcon /> : <FailIcon />}

          {/* header */}
          <span className='text-center md:text-heading-2 text-heading-4 font-Medium text-Gray-800 pt-8'>
            {isSuccess ? `Account Verified!` : `Something Went Wrong!`}
          </span>

          {/* sub header */}
          {isSuccess ? (
            <span className='md:text-small text-[16px] font-Regular text-center text-Gray-700 pt-3'>
              Thank you for verifying your account. Please use below button to
              login to the system.
            </span>
          ) : (
            <span className='font-Medium text-center text-Gray-700 pt-3'>
              {errorMessage}
            </span>
          )}

          {/* login button */}
          <Button
            isBlock
            onClick={() => {
              navigate('/auth/login')
            }}
            className='goto-login mt-10'>
            Go to RefHub Login
          </Button>

          {!isSuccess && (
            <Button
              onClick={() => {
                window.open('https://www.refhub.com.au/contact-us', '_blank')
              }}
              isBlock
              className='contact-support mt-5'
              textTheme='blue'
              theme='white'>
              Contact Support
            </Button>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
