import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { addMonths, addYears } from 'date-fns'
import {
  FixedSubscriptionPackageDto,
  getSubscriptionStore,
  SubscriptionMode,
  SubscriptionType,
} from 'features/subscriptions'

import { Column } from 'components/atoms/Column'
import { dateToReadableString, formatCurrency } from 'utils'

type SubscriptionConfirmationProps = {
  subscriptionPackage: FixedSubscriptionPackageDto
  subscriptionMode: SubscriptionMode
}

export const SubscriptionConfirmation = ({
  subscriptionPackage,
  subscriptionMode,
}: SubscriptionConfirmationProps) => {
  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  const originalAmountWithTax = useMemo(() => {
    const amount = subscriptionPackage?.packageAmount * 1.1
    return amount.toFixed(2)
  }, [subscriptionPackage?.packageAmount])

  const discountedAmountWithTax = useMemo(() => {
    const amount =
      subscriptionPackage?.packageAmount *
      (1 - subscriptionPackage.promotionDiscountRate) *
      1.1
    return amount.toFixed(2)
  }, [subscriptionPackage?.packageAmount])

  const yearDateString = useMemo(() => {
    const renewalDate = fixedSubscription?.renewalDate
      ? new Date(fixedSubscription.renewalDate)
      : new Date()

    const newDateAfterAddingYear = addMonths(renewalDate, 12)
    return dateToReadableString(newDateAfterAddingYear)
  }, [fixedSubscription])

  const monthDateString = useMemo(() => {
    const renewalDate = fixedSubscription?.renewalDate
      ? new Date(fixedSubscription.renewalDate)
      : new Date()

    const newDateAfterAddingMonth = addMonths(renewalDate, 1)
    return dateToReadableString(newDateAfterAddingMonth)
  }, [fixedSubscription])

  return (
    <Column>
      <div className='flex flex-col text-base leading-[20px] font-Medium text-[#414244] mt-6'>
        <div>
          <span className='mr-1'>You will be charged</span>
          {subscriptionPackage.promotionDiscountRate > 0 ? (
            <span>
              <del className='text-Red-600'> ${originalAmountWithTax} </del> $
              {discountedAmountWithTax}{' '}
            </span>
          ) : (
            <span>${originalAmountWithTax} </span>
          )}{' '}
          <span>
            AUD Per
            {subscriptionPackage?.subscriptionType === SubscriptionType.Yearly
              ? ' Year '
              : ' Month '}
          </span>
          {(!fixedSubscription?.isCanceled ||
            (subscriptionMode == SubscriptionMode.Downgrade &&
              fixedSubscription?.renewalDate)) && (
            <span>
              {' '}
              starting from (
              {subscriptionMode == SubscriptionMode.Downgrade
                ? dateToReadableString(fixedSubscription?.renewalDate)
                : dateToReadableString(new Date())}
              -{' '}
              {subscriptionPackage?.subscriptionType === SubscriptionType.Yearly
                ? fixedSubscription &&
                  subscriptionMode == SubscriptionMode.Downgrade
                  ? yearDateString
                  : dateToReadableString(addYears(new Date(), 1))
                : fixedSubscription &&
                  subscriptionMode == SubscriptionMode.Downgrade
                ? monthDateString
                : dateToReadableString(addMonths(new Date(), 1))}
              )
            </span>
          )}
        </div>
        <span>You can cancel anytime</span>
      </div>

      <span className='text-[#64676C] text-extra-small font-Regular tracking-[0.25px] mt-1'>
        By clicking Subscribe, you agree to our
        <a
          href='https://www.refhub.com.au/privacy-policy'
          target='_blank'
          className='mx-1 text-Cobalt-600 font-Medium'>
          terms & conditions
        </a>
        and
        <a
          href='https://www.refhub.com.au/privacy-policy'
          target='_blank'
          className='mx-1 text-Cobalt-600 font-Medium'>
          terms of service
        </a>
      </span>
    </Column>
  )
}
