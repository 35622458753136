export enum PayAsYouGoSubscriptionPackageType {
  PoliceCheck = 'PoliceCheck',
  VolunteerCheck = 'VolunteerCheck',
  RightToWorkCheck = 'RightToWorkCheck',
  ReferenceCheck = 'ReferenceCheck',
}

export enum NormalizedPayAsYouGoSubscriptionPackageType {
  PoliceCheck = 'Police Check',
  VolunteerCheck = 'Volunteer Check',
  RightToWorkCheck = 'Right To Work Check',
  ReferenceCheck = 'Reference Check',
}
