/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import { useGetAgencySettings } from 'api'
import CN from 'classnames'
import { getCandidateStore } from 'features/candidates/store'
import { getOnboardingStore } from 'features/onboarding'
import {
  AppSettingKey,
  getAgencyAppSettings,
  isSettingEnabled,
  setAgencyAppSettings,
} from 'features/settings'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useGetCandidateList,
  useGetCSVList,
  useGetManagingConsultantList,
} from 'framework/api/methods'
import { completionList } from 'static-data/candidates/completionStatus'

import { MoreMenuButton } from 'components/atoms'
import { Card } from 'components/atoms/Card'
import { H4 } from 'components/atoms/Headings'
import { EmptyTableType, TableHeader, UITable } from 'components/common/UITable'
import { CandidateImportWizard } from 'components/molecules'

import { AddNewCandidateModal } from '../AddEditCandidateModals'

import CandidatesTableColumn from './CandidatesTableColumn'

type CandidateListProps = {
  [x: string]: any
  showFilter?: boolean
  showAddButton?: boolean
  isAddCandidateDrawerOpen?: boolean
  setIsAddCandidateDrawerOpen?: any
  pageSize?: number
  showEmptyAddCard?: boolean
  drawerTitle?: string
  drawerSubTitle?: string
}

export const CandidatesTable = ({
  drawerTitle,
  drawerSubTitle,
  className,
  showFilter = true,
  showAddButton = true,
  isAddCandidateDrawerOpen = false,
  setIsAddCandidateDrawerOpen,
  pageSize = 20,
  showEmptyAddCard = false,
  ...restProps
}: CandidateListProps) => {
  const agencyAppSettings = useSelector(getAgencyAppSettings)
  const { isRefreshCandidateTable } = useSelector(getCandidateStore)
  const { guideStepperCandidate } = useSelector(getOnboardingStore)

  const isEmailRequired = isSettingEnabled(
    AppSettingKey.IS_CANDIDATE_EMAIL_REQUIRED,
    agencyAppSettings,
  )

  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || pageSize,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(true)
  const [filterActive, setFilterActive] = useState(true)
  const [pageNumber, setPageNumber] = useState(1)

  const [selectedCompletionStatus, setSelectedCompletionStatus] = useState<any>(
    {
      label: '',
      value: '',
    },
  )

  const [selectedManagingConsultant, setSelectedManagingConsultant] =
    useState<any>({
      id: '',
      name: '',
    })
  const [filterVariables, setFilterVariables] = useState<any>({
    completionStatus: '',
    managingConsultant: '',
  })

  /** CSV Export  states & Variables */
  const [csvCandidateList, setCsvCandidateList] = useState([])

  const [isOpenExportWizard, setIsOpenExportWizard] = useState(false)
  const [readyToDownload, setReadyToDownload] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const notify = (props: any) => Toast(props)

  const csvLinkElement = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  /** candidate List on success */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  /** API Handling request for managing consultant List*/
  const { data: managingConsultantList } = useGetManagingConsultantList()

  /** candidate List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All candidates List */
  const {
    refetch: refreshCandidateList,
    isLoading: getCandidateListIsLoading,
    isFetching: getCandidateListIsFetching,
  } = useGetCandidateList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search,
      managingConsultant: filterVariables?.managingConsultant || '',
      completionStatus: filterVariables?.completionStatus || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  useEffect(() => {
    refreshCandidateList()
  }, [paginationVariables, isRefreshCandidateTable])

  /** candidate list table column handling */
  const tableColumns = useMemo(() => {
    if (tableData?.candidate && tableData?.candidate?.length > 0) {
      const columns: any = CandidatesTableColumn(
        isEmailRequired,
        navigate,
        guideStepperCandidate?.id,
      )
      return columns
    }
    return []
  }, [tableData])

  /** API Call for getting candidates export as CSV using react-query */
  const { mutate: getCSVListMutate } = useGetCSVList()

  useEffect(() => {
    if (
      readyToDownload &&
      csvCandidateList.length > 0 &&
      csvLinkElement.current
    ) {
      csvLinkElement.current.link.click()
      setReadyToDownload(false)
    }
  }, [readyToDownload, csvCandidateList])

  /** Process the candidate list export as CSV */
  async function getCSVList() {
    getCSVListMutate(searchKeyWord, {
      onSuccess: async ({ data: successData }: any) => {
        /** set data to csv document */
        setCsvCandidateList(successData)
        /** trigger csv element */
        setReadyToDownload(true)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify({
          alertHeader: errData.messageTitle ? errData.messageTitle : '',
          alertBody: errData.message ? errData.message : 'Error!',
          status: 'Error',
        })
      },
    })
  }

  const applyFilter = () => {
    setFilterActive(true)
    refreshCandidateList()
    setIsFilterMenuOpen(false)
    setFilterVariables({
      managingConsultant: selectedManagingConsultant?.id || '',
      completionStatus:
        selectedCompletionStatus?.value === 'Pending'
          ? 'Pending'
          : selectedCompletionStatus?.value === 'PassedCompleted'
          ? 'PassedCompleted'
          : selectedCompletionStatus?.value === 'FailedCompleted'
          ? 'FailedCompleted'
          : '',
    })
  }

  const resetFilter = () => {
    setSelectedManagingConsultant('')
    setSelectedCompletionStatus('')
    setFilterActive(false)
    setIsFilterMenuOpen(false)

    setFilterVariables({
      completionStatus: '',
      managingConsultant: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  useEffect(() => {
    setFilterVariables({
      completionStatus: 'Pending',
      managingConsultant: '',
    })
  }, [])

  useEffect(() => {
    completionList?.find((item) => {
      if (item?.label === 'Pending') {
        setSelectedCompletionStatus({
          ...selectedCompletionStatus,
          label: item?.label,
          value: item?.value,
        })
      }
    })
  }, [completionList])

  const { refetch: refetchSettings } = useGetAgencySettings((appSettings) => {
    dispatch(setAgencyAppSettings(appSettings))
  })

  useEffect(() => {
    refetchSettings()
  }, [isAddCandidateDrawerOpen])

  const CandidateListClasses = CN(`candidate-list w-full h-full`, className)

  const isShowEmptyAddCardActive = useMemo(
    () =>
      showEmptyAddCard &&
      tableData?.candidate?.length === 0 &&
      tableData?.totalCount === 0,
    [showEmptyAddCard, tableData, searchKeyWord],
  )

  return (
    <>
      {isShowEmptyAddCardActive ? (
        <Card className='min-h-[400px] flex flex-col items-center justify-center'>
          <i className='ri-team-line text-Gray-500 text-[52px]' />
          <H4
            title='You have no candidates yet!'
            className='pt-0 font-Medium'
          />
          <p className='text-Gray-600'>
            Looks like you have no candidates. You can add one by clicking below
            button
          </p>
          <Button
            className='mt-5 ml-2'
            onClick={() => {
              setIsAddCandidateDrawerOpen(true)
            }}
            iconLeft={<i className='ri-add-line' />}>
            Add Candidate
          </Button>
        </Card>
      ) : (
        <div className={CandidateListClasses} {...restProps}>
          <div className='flex flex-col w-full px-4 bg-white border rounded-t-lg outline-none border-Gray-100'>
            <CandidateImportWizard
              isOpenImportWizard={isOpenExportWizard}
              setIsOpenImportWizard={setIsOpenExportWizard}
              refetchList={refreshCandidateList}
            />
            <TableHeader
              isFilterEnable={showFilter}
              filterActive={filterActive}
              searchKeyWord={searchKeyWord}
              setSearchKeyWord={setSearchKeyWord}
              searchFieldPlaceholder={
                'Search by name, email and managing consultant'
              }
              setIsFilterMenuOpen={setIsFilterMenuOpen}
              isFilterMenuOpen={showFilter && isFilterMenuOpen}
              paginationVariables={paginationVariables}
              setPaginationVariables={setPaginationVariables}>
              {/** Adding candidate button */}
              {showAddButton && (
                <Button
                  className='ml-2'
                  onClick={() => {
                    setIsAddCandidateDrawerOpen(true)
                  }}
                  iconLeft={<i className='ri-user-add-line' />}>
                  Add Candidate
                </Button>
              )}

              {/** CSV  Export button*/}
              <div className='relative'>
                <MoreMenuButton
                  isOutlined
                  className='ml-2'
                  actionButtons={[
                    {
                      iconClass: 'ri-upload-2-line',
                      onClick: () => {
                        setIsOpenExportWizard(true)
                      },
                      title: 'CSV Import',
                      status: 'normal',
                    },
                    {
                      iconClass: 'ri-download-line',
                      onClick: () => {
                        getCSVList()
                      },
                      title: 'CSV Export',
                      status: 'normal',
                    },
                  ]}
                />
              </div>

              {/* for csv export */}
              <CSVLink
                filename='candidate-list.csv'
                data={csvCandidateList}
                ref={csvLinkElement}
              />
            </TableHeader>

            {/* expanding filter panel with open close animation */}
            {showFilter && (
              <AnimatePresence initial={false}>
                {isFilterMenuOpen && (
                  <motion.div
                    className='flex flex-col w-full bg-white'
                    initial='collapsed'
                    animate='open'
                    exit='collapsed'
                    transition={{ type: 'tween', duration: 0.3 }}
                    variants={{
                      open: { opacity: 1, y: 0, height: 'auto' },
                      collapsed: { opacity: -1, y: -35, height: 0 },
                    }}>
                    {/** Input field of filtering section */}

                    <div className='flex items-center px-4 w-full pt-3 pb-4 z-[10]'>
                      <SimpleSelect
                        label='By Completion Status'
                        placeholder='All'
                        value={completionList?.filter((item: any) => {
                          return item?.value === selectedCompletionStatus?.value
                        })}
                        className='w-1/4 mr-5'
                        onChange={(selectedItem: any) => {
                          setSelectedCompletionStatus({
                            ...selectedCompletionStatus,
                            label: selectedItem.label,
                            value: selectedItem.value,
                          })
                        }}
                        options={completionList}
                      />

                      <SimpleSelect
                        label='By Managing Consultant'
                        placeholder='All'
                        value={
                          selectedManagingConsultant?.name
                            ? {
                                label: selectedManagingConsultant?.name,
                                value: selectedManagingConsultant?.id,
                              }
                            : null
                        }
                        className='w-1/4 mr-5'
                        onChange={(selectedItem: any) => {
                          setSelectedManagingConsultant({
                            ...selectedManagingConsultant,
                            id: selectedItem?.value,
                            name: selectedItem.label,
                          })
                        }}
                        options={managingConsultantList}
                      />
                      <Button
                        className='mr-4 h-[38px] w-[139px] mt-5'
                        isDisabled={
                          !selectedManagingConsultant?.name &&
                          !selectedCompletionStatus?.value
                        }
                        onClick={() => {
                          setPageNumber(1)
                          applyFilter()
                        }}>
                        Apply Filter
                      </Button>

                      <Button
                        className='ml-2 h-[38px] w-[100px] mt-5'
                        theme='white'
                        textTheme='blue'
                        onClick={() => {
                          resetFilter()
                        }}>
                        Reset
                      </Button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </div>

          <div className='w-full h-[calc(90vh-80px)] relative'>
            <div
              className={CN(
                'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
              )}>
              <UITable
                data={tableData?.candidate || []}
                isLoading={
                  getCandidateListIsLoading || getCandidateListIsFetching
                }
                className=''
                emptyTableType={EmptyTableType.Candidate}
                emptyBtnOnClick={() => setIsAddCandidateDrawerOpen(true)}
                columns={tableColumns}
                allowRowSelection={false}
                isSorted={true}
                hasFooter={true}
                hasCheckBox={true}
                isHeader={true}
                isFilterEnabled={false}
                paginationVariables={paginationVariables}
                setPaginationVariables={setPaginationVariables}
                totalRowCount={tableData?.totalCount || 0}
                isManualSortBy={true}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </div>
          </div>
        </div>
      )}

      <AddNewCandidateModal
        modalTitle={drawerTitle}
        modalSubTitle={drawerSubTitle}
        refreshCandidateList={refreshCandidateList}
        isModalActive={isAddCandidateDrawerOpen}
        setIsModalActive={setIsAddCandidateDrawerOpen}
      />
    </>
  )
}
