/* eslint-disable @typescript-eslint/no-unused-vars */

import { PromotionDto } from '../types'

import { setPromotionMessageState, setPromotionState } from './reducer'

export const setPromotion =
  (value: PromotionDto | null, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setPromotionState(value))
  }

export const setPromotionMessage =
  (value: string, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setPromotionMessageState(value))
  }
