import { useSelector } from 'react-redux'
import reportBgPlaceholder from 'assets/images/report-settings-bg.svg'
import { getReportSettings } from 'features/settings'

import { Column } from 'components/atoms/Column'

import { ReportDividerBar } from './ReportDividerBar'

export const ReportBanner = () => {
  const { backgroundImage, lineColour } = useSelector(getReportSettings)

  return (
    <Column>
      {backgroundImage.isChecked && (
        <div className='flex items-center justify-center w-full h-[225px]'>
          <img
            src={backgroundImage?.value || reportBgPlaceholder}
            alt=''
            className='object-cover w-full h-full'
          />
        </div>
      )}

      <ReportDividerBar size='sm' color={lineColour} />
    </Column>
  )
}
