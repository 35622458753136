import React, { Suspense, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager, { TagManagerArgs } from 'react-gtm-module'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useVerifyMaintenanceState } from 'api/featureFlags/verifyMaintenanceState'
import { UnderMaintenancePage } from 'features/misc/pages/UnderMaintenance'

import { PageLoader } from 'components/atoms'

import { AuthRequired } from './components/auth/AuthRequired'
import { routes } from './routes/routes'

const App: React.FC = () => {
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER || ''
  const tagManagerArgs: TagManagerArgs = {
    gtmId,
  }
  TagManager.initialize(tagManagerArgs)
  const [isMaintenance, setIsMaintenance] = useState(false)

  useVerifyMaintenanceState((data) => setIsMaintenance(data.isFeatureEnabled))

  /* Render component with or without Layout */
  const renderWithLayout = ({ Component, Layout, ...restProps }: any) => {
    if (Layout) {
      return (
        <Layout {...restProps}>
          <Suspense
            fallback={<PageLoader size='xs' className='bg-opacity-100' />}>
            <Component />
          </Suspense>
        </Layout>
      )
    }
    return (
      <Suspense fallback={<PageLoader size='xs' className='bg-opacity-100 ' />}>
        <Component {...restProps} />
      </Suspense>
    )
  }

  /* Rendering routes */
  const renderComponent = ({ isSecured, ...restProps }: any) => {
    if (isSecured) {
      return <AuthRequired>{renderWithLayout({ ...restProps })}</AuthRequired>
    } else {
      return renderWithLayout({ ...restProps })
    }
  }

  /* Iterate through Routes */
  const routeRender = (
    <Routes>
      {isMaintenance ? (
        <>
          <Route path='/maintenance' element={<UnderMaintenancePage />} />
          <Route path='/*' element={<Navigate to='/maintenance' />} />
        </>
      ) : (
        routes.map(
          (
            {
              redirectTo,
              path,
              layout: Layout,
              component: Component,
              isSecured,
              hasSidebar = true,
              heading,
              isBackButton,
              subHeading,
              hasSubHeading = true,
              hasSubFooter = false,
              hasPermissions = false,
              isShowRangePicker,
            }: any,
            key,
          ) => {
            /* If redirectTo is defined, render a Redirect component */
            if (redirectTo) {
              return (
                <Route
                  key={key}
                  path={path}
                  element={<Navigate to={redirectTo} />}
                />
              )
            }

            /* Render Route component */
            return (
              <Route
                path={path}
                key={key}
                element={renderComponent({
                  Component,
                  Layout,
                  isSecured,
                  hasSidebar,
                  heading,
                  isBackButton,
                  subHeading,
                  hasSubHeading,
                  hasSubFooter,
                  hasPermissions,
                  isShowRangePicker,
                })}
              />
            )
          },
        )
      )}
    </Routes>
  )

  return <Router>{routeRender}</Router>
}

export default App
