import { useEffect, useState } from 'react'
import { TabTileCard } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { useGetPoliceCheckDetails } from '../../api/useGetPoliceCheckDetails'
import { useGetRightToWorkDetails } from '../../api/useGetRightToWorkDetails'

import { PoliceCheckDetailsCard } from './PoliceCheckDetailsCard'
import { RightToWorkCheckCard } from './RightToWorkCheckCard'

type EligibilityTabProps = {
  [x: string]: any
}

export const EligibilityTab = ({
  className,
  candidateId,
  candidateName,
  ...restProps
}: EligibilityTabProps) => {
  const [policeCheckDetailsData, setPoliceCheckDetailsData] = useState<any>()
  const [rtwCheckDetailsData, setRtwCheckDetailsData] = useState<any>()
  const [policeCheckConsultantName, setPoliceCheckConsultantName] = useState()
  const [rtwCheckConsultantName, setRtwCheckConsultantName] = useState()

  useEffect(() => {
    getPoliceCheckDetails()
    getRightToWorkCheckDetails()
  }, [])

  /** get police check details on success */
  const onGetPoliceCheckDetailsSuccess = ({ data }: any) => {
    setPoliceCheckDetailsData(data?.policeCheck)
    setPoliceCheckConsultantName(data?.consultantName)
  }

  /** API Handling request for police check details */
  const {
    refetch: getPoliceCheckDetails,
    isLoading: getPoliceCheckDetailsIsLoading,
    isFetching: getPoliceCheckDetailsIsFetching,
  } = useGetPoliceCheckDetails(
    {
      Id: candidateId,
    },
    onGetPoliceCheckDetailsSuccess,
  )

  /** get rtw check details on success */
  const onGetRightToWorkCheckDetailsSuccess = ({ data }: any) => {
    setRtwCheckDetailsData(data?.rtwCheck)
    setRtwCheckConsultantName(data?.consultantName)
  }

  /** API Handling request for right to work check details */
  const {
    refetch: getRightToWorkCheckDetails,
    isLoading: getRightToWorkCheckDetailsIsLoading,
    isFetching: getRightToWorkCheckDetailsIsFetching,
  } = useGetRightToWorkDetails(
    {
      Id: candidateId,
    },
    onGetRightToWorkCheckDetailsSuccess,
  )

  /** eligibility tab view style class */
  const EligibilityTabViewClasses = CN(
    `eligibility-tab-view flex flex-col w-full`,
    className,
  )

  return (
    <div className={EligibilityTabViewClasses} {...restProps}>
      {/* top tile cards */}
      <div className='flex gap-x-5'>
        {/* has a police check card */}
        <TabTileCard
          className='w-1/4'
          icon={
            policeCheckDetailsData?.checkStatus === 'RECEIVED' &&
            policeCheckDetailsData?.result === 'NDCO'
              ? 'ri-checkbox-circle-line'
              : 'ri-police-car-line !pr-2'
          }
          variant={
            !policeCheckDetailsData
              ? 'blue'
              : policeCheckDetailsData?.checkStatus === 'SENT' ||
                policeCheckDetailsData?.checkStatus === 'PREP'
              ? 'orange'
              : policeCheckDetailsData?.checkStatus === 'RECEIVED' &&
                policeCheckDetailsData?.result === 'NDCO'
              ? 'green'
              : policeCheckDetailsData?.checkStatus === 'RECEIVED' &&
                policeCheckDetailsData?.result === 'DCO'
              ? 'red'
              : 'orange'
          }
          leftElement={
            <span className='text-white font-Bold'>Police Check</span>
          }
          rightElement={
            <span className='text-heading-3 text-white font-Bold z-[1]'>
              {!policeCheckDetailsData
                ? 'Not Sent'
                : policeCheckDetailsData?.checkStatus === 'SENT' ||
                  policeCheckDetailsData?.checkStatus === 'PREP'
                ? 'Sent'
                : policeCheckDetailsData?.checkStatus === 'RECEIVED' &&
                  policeCheckDetailsData?.result === 'NDCO'
                ? 'NDCO'
                : policeCheckDetailsData?.checkStatus === 'RECEIVED' &&
                  policeCheckDetailsData?.result === 'DCO'
                ? 'DCO'
                : policeCheckDetailsData?.checkStatus}
            </span>
          }
        />

        {/* police check details card */}
        <TabTileCard
          className='w-1/4'
          icon={'ri-article-line'}
          leftElement={
            <span className='text-white font-Bold w-[102px]'>
              Right To Work
            </span>
          }
          variant={
            !rtwCheckDetailsData
              ? 'black'
              : rtwCheckDetailsData?.checkStatus === 'PREP' ||
                rtwCheckDetailsData?.checkStatus === 'QUEUED' ||
                rtwCheckDetailsData?.checkStatus === 'SENT'
              ? 'orange'
              : rtwCheckDetailsData?.checkStatus === 'RESULT' &&
                rtwCheckDetailsData?.result === 'UNLIMITED'
              ? 'green'
              : rtwCheckDetailsData?.checkStatus === 'RESULT' &&
                rtwCheckDetailsData?.result === 'NONE'
              ? 'red'
              : rtwCheckDetailsData?.checkStatus === 'RESULT' &&
                rtwCheckDetailsData?.result === 'LIMITED'
              ? 'orange'
              : 'black'
          }
          rightElement={
            <span
              className={CN('text-heading-3  font-Bold z-[1]', {
                'text-Green-500': policeCheckDetailsData?.status,
                'text-white': !policeCheckDetailsData?.status,
              })}>
              {!rtwCheckDetailsData
                ? 'Not Sent'
                : rtwCheckDetailsData?.checkStatus === 'PREP' ||
                  rtwCheckDetailsData?.checkStatus === 'QUEUED' ||
                  rtwCheckDetailsData?.checkStatus === 'SENT'
                ? 'Sent'
                : rtwCheckDetailsData?.checkStatus === 'RESULT' &&
                  rtwCheckDetailsData?.result === 'UNLIMITED'
                ? 'UNLIMITED'
                : rtwCheckDetailsData?.checkStatus === 'RESULT' &&
                  rtwCheckDetailsData?.result === 'NONE'
                ? 'NONE'
                : rtwCheckDetailsData?.checkStatus === 'RESULT' &&
                  rtwCheckDetailsData?.result === 'LIMITED'
                ? 'LIMITED'
                : rtwCheckDetailsData?.result}
            </span>
          }
        />
      </div>

      <div className='flex w-full gap-x-4'>
        {/* police check  details section */}
        <PoliceCheckDetailsCard
          policeCheckDetailsData={policeCheckDetailsData}
          isLoading={
            getPoliceCheckDetailsIsLoading || getPoliceCheckDetailsIsFetching
          }
          candidateId={candidateId}
          candidateName={candidateName}
          getPoliceCheckDetails={getPoliceCheckDetails}
          policeCheckConsultantName={policeCheckConsultantName}
        />

        {/* right to work section */}
        <RightToWorkCheckCard
          rightToWorkData={rtwCheckDetailsData}
          isLoading={
            getRightToWorkCheckDetailsIsFetching ||
            getRightToWorkCheckDetailsIsLoading
          }
          candidateId={candidateId}
          candidateName={candidateName}
          getRightToWorkCheckDetails={getRightToWorkCheckDetails}
          rtwCheckConsultantName={rtwCheckConsultantName}
        />
      </div>
    </div>
  )
}
