/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { useOutsideClickIdentify } from 'utils'

type UserDropDownProps = {
  className?: string | undefined
  isCustomStyles?: boolean
  setShowUserDropDown: (arg0: boolean) => void
  profileData: {
    userRole: string
    userName: string
    profileAvatar: string | undefined
  }
  [x: string]: any
}

export const UserDropDown: FC<UserDropDownProps> = ({
  className,
  isCustomStyles,
  setShowUserDropDown,
  profileData,
  ...restProps
}: UserDropDownProps) => {
  const navigate = useNavigate()

  const focusedDivRef = useRef(null)
  useOutsideClickIdentify('user-action-button', focusedDivRef, () => {
    setShowUserDropDown(false)
  })

  /** user drop down main class name */
  const textFieldMainClassName = !isCustomStyles
    ? CN(
        'absolute top-2 right-[32px] flex flex-col w-[195px] rounded-[8px] z-20 bg-white custom-shadow',
        className,
      )
    : className

  return (
    <div className={textFieldMainClassName} {...restProps} ref={focusedDivRef}>
      <div className='flex pl-3 py-[11px] border-b border-Gray-200'>
        {/** Profile picture */}
        <Avatar
          titleClassName='flex max-w-[160px] line-clamp-1'
          title={profileData?.userName}
          tag={profileData?.userRole}
          imageURL={
            profileData?.profileAvatar ? profileData?.profileAvatar : undefined
          }
          size='sm'
        />
      </div>

      {/** Drop Down Body */}
      <div className='flex flex-col cursor-pointer pb-[13px]'>
        <div className='my-account flex text-Gray-500 pt-3 hover:text-Gray-900'>
          <i className='ri-user-3-line pl-[15px]'></i>
          <span
            className='font-Medium text-Gray-500 pl-[10px] hover:text-Gray-900'
            onClick={() => {
              navigate('/user-profile')
              setShowUserDropDown(false)
            }}>
            My Account
          </span>
        </div>

        <div className='logout flex text-Cobalt-600 pt-[14px] hover:text-Cobalt-400'>
          <i className='ri-logout-box-r-line pl-[15px]'></i>
          <span
            className='font-Medium text-Cobalt-600 pl-[10px] hover:text-Cobalt-400'
            onClick={() => {
              localStorage.removeItem('token')
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('rememberMe')
              sessionStorage.removeItem('token')
              sessionStorage.removeItem('refreshToken')
              navigate('/auth/login')
              setShowUserDropDown(false)
            }}>
            Logout
          </span>
        </div>
      </div>
    </div>
  )
}

export default UserDropDown

UserDropDown.defaultProps = {
  className: undefined,
  isCustomStyles: false,
  profileData: {
    userRole: '',
    userName: '',
    profileAvatar: undefined,
  },
}
