import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useGetSubscriptionPackagesList } from 'api/subscriptions'
import bfBanner from 'assets/images/blackfriday/package-banner.svg'
import { resetPayAsYouGoCredits } from 'features/payments'
import { setPromotion } from 'features/promotions'
import {
  setSubscriptionPackages,
  SubscriptionPlanType,
  SubscriptionType,
} from 'features/subscriptions'

import { isBlackFriday } from 'utils/offers'

import { OnboardingFooter } from '../../OnboardingFooter'

import { PackageCard } from './PackageCard'
import { SelectPackageRightContent } from './SelectPackageRightContent'

export const SelectPackage = () => {
  const dispatch = useDispatch()
  const [packagePeriod, setPackagePeriod] = useState<SubscriptionType>(
    SubscriptionType.Monthly,
  )

  const [selectedPackageType, setSelectedPackageType] =
    useState<SubscriptionPlanType>(SubscriptionPlanType.PAYG)

  useGetSubscriptionPackagesList((packages) => {
    dispatch(setSubscriptionPackages(packages))
  })

  const packageDetails: any = [
    {
      id: SubscriptionPlanType.PAYG,
    },
    {
      id: SubscriptionPlanType.FifteenPack,
    },
    {
      id: SubscriptionPlanType.TwentyfivePack,
    },
    {
      id: SubscriptionPlanType.FiftyPack,
    },
  ]

  useEffect(() => {
    dispatch(setPromotion(null))
    dispatch(resetPayAsYouGoCredits())
  }, [selectedPackageType])

  return (
    <div className='flex items-center justify-center w-full h-full'>
      <div className='flex flex-col w-2/5 h-full bg-[#F4F8FB] px-[42px] pt-[10px] overflow-y-auto'>
        <div className='text-[28px] leading-normal font-SemiBold text-black'>
          Select package
        </div>

        <div className='text-[#757575] mt-2'>
          You can upgrade or downgrade anytime.
          <br />{' '}
          {isBlackFriday() && (
            <span>
              Special Black Friday deal only available for 24 to 27th November
            </span>
          )}
        </div>

        {isBlackFriday() && <img src={bfBanner} alt='' className='h-[70px]' />}

        <div className='mb-5'>
          <div
            className='flex flex-col justify-start items-start w-full h-full gap-y-4 mt-[14px]'
            key={selectedPackageType}>
            {packageDetails.map((pkg: any) => (
              <PackageCard
                id={pkg.id}
                isSelected={selectedPackageType === pkg.id ? true : false}
                packageName={pkg.id}
                onChange={() => setSelectedPackageType(pkg.id)}
                onRadioClick={() => setSelectedPackageType(pkg.id)}
                defaultChecked={selectedPackageType === pkg.id ? true : false}
                packagePeriod={packagePeriod}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='relative flex flex-1 w-full h-full bg-white'>
        <SelectPackageRightContent
          selectedPackageType={selectedPackageType}
          setPackagePeriod={setPackagePeriod}
          packagePeriod={packagePeriod}
        />

        <OnboardingFooter hasMaxPadding={true} />
      </div>
    </div>
  )
}
