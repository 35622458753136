import { useEffect, useMemo, useState } from 'react'
import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createParagraphNode,
  $getRoot,
  $insertNodes,
  LexicalNode,
} from 'lexical'
import {
  $convertToMentionNodes,
  $createBeautifulMentionNode,
} from 'lexical-beautiful-mentions'

import { isValidJSON } from 'utils'

type InitialStatePluginProps = {
  initialState?: string
  isHtml?: boolean
  mentions: string[]
}

export const InitialStatePlugin = ({
  initialState,
  isHtml,
  mentions = [],
}: InitialStatePluginProps) => {
  const [editor] = useLexicalComposerContext()
  const [stateHasBeenApplied, setStateHasBeenApplied] = useState(false)

  const mentionsMapping: Record<string, string> = useMemo(() => {
    const mapping: Record<string, string> = {}

    mentions.forEach((mention) => {
      const formattedMention = `@${mention
        .replace(/\s/g, 'SPACE')
        .replace(/'/g, 'APOS')}`
      mapping[formattedMention] = mention
    })

    return mapping
  }, [mentions])

  useEffect(() => {
    if (!initialState || stateHasBeenApplied) return

    const current = editor.getEditorState().toJSON()
    if (JSON.stringify(current) === initialState) return

    const applyPriorState = () => {
      try {
        const state = editor.parseEditorState(initialState)
        editor.setEditorState(state)
      } catch {
        console.log('some error message')
      }
    }

    const createTextNodeFromString = () => {
      editor.update(() => {
        const root = $getRoot()
        root.clear()
        const paragraphNode = $createParagraphNode()

        const modifiedText =
          mentions.length > 0
            ? initialState.replace(
                new RegExp(`@(${mentions.join('|')})`, 'g'),
                (match) =>
                  match
                    .replace(/\s/g, 'SPACE')
                    .replace(/'/g, 'APOS')
                    .replace('SPACE@', ' @'),
              )
            : initialState

        const mentionNodes = $convertToMentionNodes(modifiedText, ['@'])
        mentionNodes.forEach((mentionNode: LexicalNode) => {
          if (mentionNode.__type == 'beautifulMention') {
            const mentionValue = mentionsMapping[mentionNode.getTextContent()]

            if (mentionValue !== undefined) {
              const customNode = $createBeautifulMentionNode('@', mentionValue)
              paragraphNode.append(customNode)
            } else {
              paragraphNode.append(mentionNode)
            }
          } else {
            paragraphNode.append(mentionNode)
          }

          return paragraphNode
        })

        root.append(paragraphNode)
      })
    }

    const replaceTags = (node: Node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement
        if (element.tagName === 'EM' || element.tagName === 'STRONG') {
          const span = document.createElement('span')
          span.innerHTML = element.innerHTML

          // Copy over styles and classes
          span.className = element.className
          span.style.cssText = element.style.cssText

          element.replaceWith(span)
        }
      }
      node.childNodes.forEach(replaceTags)
    }

    const createNodeFromHTML = () => {
      editor?.update(() => {
        if (!initialState) return
        console.log(initialState)

        const parser = new DOMParser()
        const dom = parser.parseFromString(initialState, 'text/html')

        // Replace <em> and <strong> tags with <span> tags
        replaceTags(dom.body)

        const nodes = $generateNodesFromDOM(editor, dom)
        $getRoot().clear()
        $getRoot().select()
        $insertNodes(nodes)
      })
    }

    if (isValidJSON(initialState)) {
      applyPriorState()
    } else if (isHtml) {
      createNodeFromHTML()
    } else {
      createTextNodeFromString()
    }

    setStateHasBeenApplied(true)
  }, [initialState, editor, stateHasBeenApplied])

  return null
}
