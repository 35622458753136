import { useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { useListCandidateNotes } from 'api/candidates'
import CN from 'classnames'

import { timeToAESTWithFormat } from 'utils'

import { AddHistoryRecordModal } from './AddHistoryRecordModal'
import { HistoryItemCard } from './HistoryItemCard'

export type HistoryTabProps = {
  candidateId: string
  className?: string
}

export const HistoryTab = ({ candidateId, className }: HistoryTabProps) => {
  const DashboardTabViewClasses = CN(`history-tab-view w-full`, className)
  const [isAddHistoryModalOpen, setIsAddHistoryModalOpen] = useState(false)

  const [historyList, setHistoryList] = useState([])

  /** get subscriptions List on success */
  const onSuccess = (data: any) => {
    setHistoryList(data?.reverse())
  }

  /** API Handling request for Get All Uniform List */
  const { refetch: refetchListCandidateHistory } = useListCandidateNotes(
    {
      candidateId,
    },
    onSuccess,
  )

  return (
    <div className={DashboardTabViewClasses}>
      <div className='flex items-start justify-end w-full mb-4'>
        <Button
          size='sm'
          onClick={() => {
            setIsAddHistoryModalOpen(true)
          }}>
          Add Note
        </Button>
      </div>

      {historyList &&
        historyList?.map?.((item: any, idx: any) => (
          <div
            key={idx}
            className='relative flex w-full h-full history-detail-card'>
            {/* detail card */}
            <div className='absolute flex flex-col items-center h-full'>
              <div className='bg-Cobalt-600 w-[10px] h-[10px] rounded-full'></div>
              <div
                className={CN('bg-Cobalt-600 w-[2px] h-full', {
                  '!h-[calc(100%-25px)]': idx + 1 === historyList?.length,
                  'h-full': idx + 1 !== historyList?.length,
                })}></div>
            </div>
            <div className='w-full ml-5 '>
              <HistoryItemCard
                icon={item?.icon}
                description={item?.description}
                date={
                  item?.updatedOn
                    ? timeToAESTWithFormat(
                        item?.updatedOn,
                        'h:mm A, DD MMM YYYY',
                      )
                    : ''
                }
                title={item?.title}
              />
            </div>
          </div>
        ))}

      <AddHistoryRecordModal
        isModalActive={isAddHistoryModalOpen}
        setIsModalActive={setIsAddHistoryModalOpen}
        candidateId={candidateId}
        getListData={refetchListCandidateHistory}
      />
    </div>
  )
}
