import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getQuestionnairesList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  let URI = `Questionnaire?Take=${reqBody.take}&Skip=${reqBody.skip}&Category=${reqBody.category}&sortDirection=${reqBody.sortDirection}&SurveyType=${reqBody.surveyType}`
  if (reqBody.search) {
    URI += `&Search=${reqBody.search}`
  }
  if (reqBody.status) {
    URI += `&IsActive=${reqBody.status}`
  }
  if (reqBody.sortColumnName) {
    URI += `&sortColumnName=${reqBody.sortColumnName}`
  }
  if (reqBody.filteredSurveyType) {
    URI += `&FilteredSurveyType=${reqBody.filteredSurveyType}`
  }
  const res = await get(URI)
  return res.data
}

export const useGetQuestionnairesList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['Questionnaires', reqBody], getQuestionnairesList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
