/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useSelector } from 'react-redux'
import ImageUploader from 'assets/images/ImageUploader.png'
import CN from 'classnames'

import { CustomizableEditProfilePictureModal } from 'components/molecules'

import { getOnboardingStore } from '../../../../store/selectors'

type LogoUploadProps = {
  [x: string]: any
}

export const LogoUpload = ({
  isRectangle,
  subText,
  title,
  imageUrl,
  setImageUrl,
  showAgencyProfileModal,
  setShowAgencyProfileModal,
  uploadAgencyProfileLogo,
  createAgencySecondaryLogo,
  uploadBtnIsLoading,
  uploadedPrimaryImageUrl = null,
  uploadedSecondaryImageUrl = null,
}: LogoUploadProps) => {
  const [zoom, setZoom] = useState(1)
  const [warningMessage, setWarningMessage] = useState('')
  const { tenantId, brandingLogos: brandingLogosObj } =
    useSelector(getOnboardingStore)

  const [droppedFile, setDroppedFile] = useState<any>()
  const [files, setFiles] = useState<any>()

  const addFile = (file: any) => {
    file.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setFiles(file)
      setImageUrl(file.preview)
      setShowAgencyProfileModal(true)
    })
  }

  /** handle image upload file on drop */
  const onDrop = (accepted) => {
    if (
      accepted[0].type === 'image/jpeg' ||
      accepted[0].type === 'image/png' ||
      accepted[0].type === 'image/jpg'
    ) {
      setDroppedFile(accepted)
      addFile(accepted)
      setWarningMessage('')
    } else {
      const message = 'Please submit valid file type (jpg, png, jpeg)'
      setWarningMessage(message)
    }
  }

  useEffect(() => {
    if (zoom === 1.01) {
      addFile(droppedFile)
    }
  }, [zoom])

  return (
    <div className='flex flex-col w-full'>
      <div className='pb-4 text-small font-Bold'>{title}</div>
      {!isRectangle && uploadedPrimaryImageUrl && (
        <div className='relative flex items-start justify-start'>
          <img
            src={uploadedPrimaryImageUrl || brandingLogosObj?.primaryLogoUrl}
            alt='drag-and-drop-area'
            className='rounded'
          />

          <div className='absolute bottom-1 right-1'>
            <div className='overflow-hidden bg-white rounded shadow-md'>
              <i
                className='ri-pencil-line flex justify-center items-center text-[18px] h-[18px] w-[18px] p-[20px] text-Cobalt-600 hover:bg-Gray-50 hover:text-Cobalt-700 cursor-pointer'
                onClick={() => {
                  setShowAgencyProfileModal(true)
                }}
              />
            </div>
          </div>
        </div>
      )}

      {isRectangle && uploadedSecondaryImageUrl && (
        <div className='relative flex items-start justify-start'>
          <img
            src={
              uploadedSecondaryImageUrl || brandingLogosObj?.secondaryLogoUrl
            }
            alt='drag-and-drop-area'
            className='rounded'
          />

          <div className='absolute bottom-1 right-1'>
            <div className='overflow-hidden bg-white rounded shadow-md'>
              <i
                className='ri-pencil-line flex justify-center items-center text-[18px] h-[18px] w-[18px] p-[20px] text-Cobalt-600 hover:bg-Gray-50 hover:text-Cobalt-700 cursor-pointer'
                onClick={() => {
                  setShowAgencyProfileModal(true)
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!isRectangle && !uploadedPrimaryImageUrl && (
        <>
          <div className='border-[2px] border-Gray-300 rounded-md border-dashed cursor-pointer'>
            <Dropzone
              key={Math.random()}
              multiple={false}
              onDrop={(accepted) => {
                onDrop(accepted)
              }}>
              {({ getRootProps, getInputProps }) => (
                <div id={Math.random().toString()}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      className={CN(
                        'flex flex-col justify-center items-center',
                      )}>
                      <img
                        src={ImageUploader}
                        className='px-[26px] pt-[22px] '
                        alt='drag-and-drop-area'
                      />

                      <div className='flex flex-col text-center text-small font-Medium pb-[26px] mt-1'>
                        <div>
                          <span className='text-Cobalt-600'>Upload a file</span>
                          <span className='ml-1 text-Gray-600'>
                            or drag and drop
                          </span>
                        </div>
                        <div className='font-Regular text-Gray-500'>
                          PNG, JPG, GIF up to 10MB
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>

          <div className='mt-4 text-Gray-800 text-[10px] leading-[15px] font-Medium'>
            {subText}
          </div>
        </>
      )}

      {isRectangle && !uploadedSecondaryImageUrl && (
        <>
          <div className='border-[2px] border-Gray-300 rounded-md border-dashed cursor-pointer'>
            <Dropzone
              key={Math.random()}
              multiple={false}
              onDrop={(accepted) => {
                onDrop(accepted)
              }}>
              {({ getRootProps, getInputProps }) => (
                <div id={Math.random().toString()}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      className={CN(
                        'flex flex-col justify-center items-center',
                      )}>
                      <img
                        src={ImageUploader}
                        className='px-[26px] pt-[22px] '
                        alt='drag-and-drop-area'
                      />

                      <div className='flex flex-col text-center text-small font-Medium pb-[26px] mt-1'>
                        <div>
                          <span className='text-Cobalt-600'>Upload a file</span>
                          <span className='ml-1 text-Gray-600'>
                            or drag and drop
                          </span>
                        </div>
                        <div className='font-Regular text-Gray-500'>
                          PNG, JPG, GIF up to 10MB
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>

          <div className='mt-4 text-Gray-800 text-[10px] leading-[15px] font-Medium'>
            {subText}
          </div>
        </>
      )}

      <CustomizableEditProfilePictureModal
        isRectangleLogo={isRectangle}
        isActive={showAgencyProfileModal}
        onHeaderCloseButtonClick={() => {
          setImageUrl('')
          setShowAgencyProfileModal(false)
        }}
        onClickSecondaryBtn={() => {
          setImageUrl('')
          setShowAgencyProfileModal(false)
        }}
        onOverlayClick={() => {
          setImageUrl('')
          setShowAgencyProfileModal(false)
        }}
        onClickPrimaryBtn={() => {
          if (!isRectangle) {
            /** for primary logo */
            uploadAgencyProfileLogo(tenantId, files)
          } else {
            /** for secondary logo */
            createAgencySecondaryLogo(tenantId, files)
          }
        }}
        setImageUrl={setImageUrl}
        imageUrl={imageUrl}
        setFiles={setFiles}
        files={files}
        headerTitle={
          isRectangle ? 'Change secondary company logo' : 'Change profile photo'
        }
        headerSubTitle={
          isRectangle
            ? 'This is the company logo displayed in the header bar'
            : 'Update your profile photo here.'
        }
        primaryButtonIsLoading={uploadBtnIsLoading}
        isOnboardingLogoUpload={true}
      />
    </div>
  )
}
