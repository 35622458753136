import { useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { SubscriptionUpgradeBadge } from '@labourhub/labour-hub-ds'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'

import { QuotaItem } from '../types'

type PlanCheckBadgeProps = {
  id: string
  icon?: string
  quotaItem: QuotaItem
  tooltip?: string
}

export const PlanCheckBadge = ({
  id,
  icon,
  quotaItem,
  tooltip,
}: PlanCheckBadgeProps) => {
  const dispatch = useDispatch()

  return (
    <button
      className='focus:outline-none'
      onClick={() => {
        dispatch(setPayAsYouGoCreditsModalActive(true))
      }}
      data-tip={`tooltip-${id}`}
      data-for={`tooltip-${id}`}>
      <SubscriptionUpgradeBadge
        icon={icon}
        totalQuota={quotaItem.purchasedQuota}
        remainingQuota={quotaItem.remainingQuota}
        label=''
      />
      <ReactTooltip
        id={`tooltip-${id}`}
        place='bottom'
        effect='solid'
        textColor='#FFFFFF'
        backgroundColor='#4B5563'
        className='rounded-full text-extra-small'>
        <p>{tooltip}</p>
      </ReactTooltip>
    </button>
  )
}
