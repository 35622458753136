import { Navigate } from 'react-router-dom'

type AuthRequiredProps = {
  [x: string]: any
}

export const AuthRequired = ({ children }: AuthRequiredProps) => {
  const token = localStorage.getItem('token')
  return token ? children : <Navigate to='/auth/login' />
}
