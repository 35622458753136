export const formatCurrency = (amount: number, removeZero = true) => {
  const ceiledAmount = Math.ceil(amount)

  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(ceiledAmount)

  let withoutTrailingZeros = formattedAmount
  if (removeZero) {
    withoutTrailingZeros = formattedAmount.replace(/\.?0+$/, '')
  }

  return withoutTrailingZeros
}
