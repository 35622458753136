import { createSlice } from '@reduxjs/toolkit'

export type CandidateStoreProps = {
  candidates: any
  isRefreshCandidateTable: boolean
}

const initialState: CandidateStoreProps = {
  candidates: null,
  isRefreshCandidateTable: false,
}

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setCandidatesState(state, { payload }) {
      state.candidates = payload
    },
    setIsRefreshCandidateTableState(state) {
      state.isRefreshCandidateTable = !state.isRefreshCandidateTable
    },
  },
})

export const { setCandidatesState, setIsRefreshCandidateTableState } =
  candidateSlice.actions

export default candidateSlice.reducer
