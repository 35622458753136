import refHubLogo from 'assets/images/refHubLogoText.svg'
import { PaymentErrorType } from 'features/payments'
import { getPaymentErrorMessage } from 'features/payments/utils'

import { Row, RowWithAlignment } from 'components/atoms/Row'

export type PayAsYouGoInvoiceNotificationBoxProps = {
  isSuccess: boolean
  message: string
  dateString: string
  timeString: string
  paymentError?: PaymentErrorType
}
export const PayAsYouGoInvoiceNotificationBox = ({
  isSuccess,
  message,
  dateString,
  timeString,
  paymentError,
}: PayAsYouGoInvoiceNotificationBoxProps) => {
  return (
    <div className='w-[95%]'>
      {isSuccess ? (
        <RowWithAlignment className='py-3 px-4 mb-1 bg-Green-50 border border-Green-300'>
          <Row>
            <i className='text-heading-1 ri-checkbox-circle-fill text-[#2AB346]'></i>

            <div className='ml-[10px]'>
              <p className='text-heading-5 text-[#333333] font-Medium'>
                {message}
              </p>

              <p className='text-extra-small text-[#757575]'>
                {timeString}, {dateString}
              </p>
            </div>
          </Row>
          <img src={refHubLogo} alt='refHubLogo' className='h-[25px] mb-1' />
        </RowWithAlignment>
      ) : (
        <RowWithAlignment className='px-4 h-fit py-3 bg-Red-50 border border-Red-300'>
          <div className='flex flex-col items-center justify-center w-full h-fit'>
            <i className='text-[58px] h-[58px] flex justify-center items-center ri-close-circle-fill text-[#F44336]'></i>

            <div className='w-full text-center'>
              <p className='text-heading-4 text-[#000] font-Medium'>
                {message}
              </p>
            </div>

            <span className='text-center text-Gray-600'>
              {getPaymentErrorMessage(paymentError)}
            </span>
          </div>
        </RowWithAlignment>
      )}
    </div>
  )
}
